/*-------- 13. Video style -------------*/

.video-area{
    &.video-area-ptb {
        padding: 240px 0 205px;
        @media #{$xl-layout} {
            padding: 140px 0 105px;
        }
        @media #{$lg-layout} {
            padding: 140px 0 105px;
        }
        @media #{$md-layout} {
            padding: 100px 0 105px;
        }
        @media #{$xs-layout} {
            padding: 50px 0 50px;
        }
    }
    &.video-area-ptb-2 {
        padding: 300px 0 300px;
        @media #{$xl-layout} {
            padding: 140px 0 105px;
        }
        @media #{$lg-layout} {
            padding: 140px 0 105px;
        }
        @media #{$md-layout} {
            padding: 100px 0 105px;
        }
        @media #{$xs-layout} {
            padding: 50px 0 50px;
        }
        @media #{$sm-layout} {
            padding: 80px 0 80px;
        }
    }
    &.video-area-ptb-3 {
        padding: 218px 0 218px;
        @media #{$xl-layout} {
            padding: 140px 0 105px;
        }
        @media #{$lg-layout} {
            padding: 140px 0 105px;
        }
        @media #{$md-layout} {
            padding: 100px 0 105px;
        }
        @media #{$xs-layout} {
            padding: 50px 0 50px;
        }
        @media #{$sm-layout} {
            padding: 80px 0 80px;
        }
    }
    .video-content {
        h2 {
            font-size: 72px;
            font-weight: 700;
            font-family: $playfairdisplay;
            margin: 0 0 49px;
            color: #fff;
            line-height: 64px;
            @media #{$lg-layout} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 50px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 0 0 25px;
                line-height: 40px;
            }
        }
    }
}

.video-btn {
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: all .5s;
    &::before {
        content: '';
        position: absolute;
        left: -5px;
        right: -5px;
        top: -5px;
        bottom: -5px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        opacity: 0;
        transition: all .5s;
        z-index: 1;
    }
    a {
        display: block;
        z-index: 99;
        position: relative;
        img {
            width: auto;
            transform: scale(1);
            transition: all .5s;
        }
    }
    &:hover::before {
        opacity: 1;
        left: -15px;
        right: -15px;
        top: -15px;
        bottom: -15px;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
        padding: 10px;
        margin: -10px;
        border-radius: 50%;
    }
    &:hover a img {
        transform: scale(.9);
    }
}



