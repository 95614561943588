/*-------- 8. Footer style ---------*/

.footer-about {
    ul {
        li {
            font-size: 12px;
            color: #ddd;
            line-height: 1;
            margin: 0 0 9px;
            &:last-child {
                margin: 0 0 0px;
            }
            @media #{$xs-layout} {
                line-height: 20px;
            }
            a {
                color: #ddd;
                font-size: 12px;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    @media #{$xs-layout} {
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
}

@media #{$xs-layout} {
    .footer-logo {
        margin: 30px 0;
    }
    .footer-bottom {
        &.pt-25 {
            padding-top: 0;
        }
    }
}
@media #{$sm-layout} {
    .footer-logo {
        a {
            img {
                width: 100%;
            }
        }
    }
}

.footer-social {
    float: right;
    a {
        color: #ddd;
        font-size: 16px;
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            color: #fff;
        }
        @media #{$sm-layout} {
            font-size: 14px;
            margin-left: 9px;
        }
    }
    @media #{$xs-layout} {
        float: inherit;
        text-align: center;
    }
    @media #{$sm-layout} {
        float: right;
        text-align: inherit;
    }
}

.footer-menu {
    ul {
        li {
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 12px;
                color: #ddd;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    @media #{$xs-layout} {
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: inherit;
    }
}

.copyright {
    float: right;
    p {
        margin: 0;
        font-size: 12px;
        color: #ddd;
    }
    @media #{$xs-layout} {
        float: inherit;
        text-align: center;
    }
    @media #{$sm-layout} {
        float: inherit;
        text-align: center;
    }
}

.footer-mrg {
    padding: 70px 0;
    @media #{$xs-layout} {
        padding: 50px 0;
    }
}

.footer-column {
    padding-left: 15px;
    padding-right: 15px;
}
.footer-top .footer-column-1, .footer-top .footer-column-6 {
	width: calc((100% - (180px * 4)) / 2);
    @media #{$lg-layout} {
        width: 33.333%;
    }
}
.footer-top .footer-column-2,
.footer-top .footer-column-3,
.footer-top .footer-column-4,
.footer-top .footer-column-5 {
	width: 180px;
    @media #{$lg-layout} {
        width: 33.333%;
    }
}

@media #{$md-layout} {
    .footer-top .footer-column-1 {
        width: 40%;
    }
    .footer-top .footer-column-2,
    .footer-top .footer-column-3 ,
    .footer-top .footer-column-4 {
        width: 20%;
    }
    .footer-top .footer-column-5 {
        width: 40%;
    }
    .footer-top .footer-column-6 {
        width: 60%;
    }
}
@media #{$xs-layout} {
    .footer-top .footer-column-1 {
        width: 100%;
    }
    .footer-top .footer-column-2,
    .footer-top .footer-column-3 ,
    .footer-top .footer-column-4 ,
    .footer-top .footer-column-5 {
        width: 50%;
    }
    .footer-top .footer-column-6 {
        width: 100%;
    }
}
@media #{$sm-layout} {
    .footer-top .footer-column-1 {
        width: 50%;
    }
    .footer-top .footer-column-2,
    .footer-top .footer-column-3 ,
    .footer-top .footer-column-4 ,
    .footer-top .footer-column-5 {
        width: 50%;
    }
    .footer-top .footer-column-6 {
        width: 50%;
    }
}

.footer-widget {
    .footer-info {
        margin: 25px 0 26px;
        ul {
            li {
                font-size: 12px;
                color: #333;
                line-height: 1;
                margin: 0 0 9px;
                &:last-child {
                    margin: 0 0 0px;
                }
                a {
                    color: #333;
                    font-size: 12px;
                    &:hover {
                        color: #989898;
                    }
                }
            }
        }
    }
    .footer-map {
        a {
            font-size: 12px;
            color: #333;
            text-decoration: underline;
        }
    }
    .widget-title {
        margin: 0 0 30px;
        @media #{$xs-layout} {
            margin: 0 0 15px;
        }
        h3 {
            font-size: 12px;
            font-weight: 700;
            margin: 0;
        }
    }
    .footer-list {
        ul {
            li {
                margin: 0 0 17px;
                &:last-child {
                    margin: 0 0 0;
                }
                a {
                    color: #333;
                    font-size: 12px;
                    display: block;
                    line-height: 1;
                    &:hover {
                        color: #989898;
                    }
                }
            }
        }
    }
    &.subscribe-right {
        width: 400px;
        max-width: 100%;
        margin-left: auto;
    }
    &.footer-social-2 {
        a {
            color: #616161;
            font-size: 12px;
            margin-right: 18px;
            &:last-child {
                margin-right: 0px;
            }
        }
        @media #{$xs-layout} {
            text-align: center;
        }
        @media #{$sm-layout} {
            text-align: left;
        }
    }
    &.copyright-2 {
        p {
            color: #333;
            font-size: 12px;
            margin: 0;
        }
        @media #{$xs-layout} {
            margin: 10px 0 10px;
        }
        @media #{$sm-layout} {
            margin: 0px 0 0px;
        }
    }
    &.payments-img {
        text-align: right;
        p {
            color: #616161;
            font-size: 12px;
            margin: 0;
        }
        @media #{$xs-layout} {
            text-align: center;
        }
        @media #{$sm-layout} {
            margin: 10px 0 0px;
        }
    }
}

.footer-hm13 {
	display: block;
	position: absolute;
	left: 0;
	bottom: 40px;
    right: 0;
    padding: 0 80px;
    z-index: 99;
    @media #{$xx-layout} {
        padding: 0 65px;
    }
    @media #{$xl-layout} {
        padding: 0 15px;
    }
    @media #{$lg-layout} {
        padding: 0 30px;
    }
    @media #{$md-layout} {
        padding: 0 40px;
    }
    @media #{$xs-layout} {
        padding: 0 15px;
    }
    @media #{$sm-layout} {
        padding: 0 30px;
    }
    .footer-hm13-wrap {
        display: flex;
        justify-content: space-between;
        .footer-social-hm13 {
            a {
                color: #ccc;
                margin-right: 18px;
                font-size: 12px;
                &:last-child {
                    margin-right: 0px;
                }
                &:hover {
                    color: $theme-color;
                }
                @media #{$xs-layout} {
                    margin-right: 4px;
                }
                @media #{$sm-layout} {
                    margin-right: 8px;
                }
            }
        }
        .footer-copyright-hm13 {
            p {
                color: #ccc;
                font-size: 12px;
                margin: 0;
            }
        }
    }
}





