/*-------- 19. Cart style ---------*/

.cart-main-area {
    @media #{$xs-layout}{
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}
h3.cart-page-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 15px;
}

.cart-table-content {
    table {
        width: 100%;
        thead > tr {
            th {
                border-top: medium none;
                font-size: 11px;
                text-transform: capitalize;
                vertical-align: middle; 
                white-space: nowrap;
                font-weight: 400;
                text-transform: uppercase;
                padding: 0 0 10px 0;
                @media #{$xs-layout}{
                    padding: 0 10px 0px 20px;
                }
            }
        }
        tbody > tr {
            border-bottom: 1px solid #E7E7E7;
            td {
                font-size: 14px;
                padding: 30px 0px 30px;
                @media #{$xs-layout}{
                    padding: 30px 12px 30px 12px;
                }
            }
            td.product-remove {
                width: 30px;
                a {
                    color: #989898;
                    font-size: 16px;
                }
            }
            td.product-img {
                width: 90px;
            }
            td.product-name {
                width: 340px;
                @media #{$md-layout}{
                    width: 250px;
                }
                @media #{$xs-layout}{
                    width: 200px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 0;
                }
                a {
                    font-size: 14px;
                    display: block;
                    margin: 0 0 5px;
                    &:hover {
                        color: $theme-color;
                    }
                }
                span {
                    color: #989898;
                    font-size: 12px;
                    display: block;
                    line-height: 1;
                }
            }
            td.product-price {
                width: 90px;
                span {
                    color: #262626;
                    font-size: 16px;
                }
            }
            td.cart-quality {
                width: 110px;
                
            }
            td.product-total {
                width: 70px;
                span {
                    color: #262626;
                    font-size: 16px;
                }
            }
            td.product-wishlist-cart {
                > a {
                    background-color: #232323;
                    color: #fff;
                    font-size: 12px;
                    line-height: 1;
                    padding: 7px 8px;
                    text-transform: capitalize;
                    &:hover {
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
}
.cart-shiping-update-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 23px 0 0;
    @media #{$xs-layout}{
        display: block;
    }
    .discount-code {
        position: relative;
        flex: 0 0 40%;
        input {
            padding: 0 115px 0 0;
            border: none;
            height: 30px;
            font-size: 14px;
            color: #989898;
            background-color: transparent;
            border-bottom: 1px solid #D8D8D8;
        }
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            padding: 0 0 3px;
            background-color: transparent;
            font-weight: 600;
            font-size: 14px;
            color: #989898;
            &:hover {
                color: #262626;
            }
            
        }
    }
    .cart-clear {
        a {
            font-weight: 600;
            display: inline-block;
            margin: 3px 0 0 7px;
            color: #989898;
            &:hover {
                color: #262626;
            }
        }
        @media #{$xs-layout}{
            margin-top: 20px;
        }
    }
}

.grand-total-wrap {
    background: #F9F9F9;
    padding: 30px 30px 0;
    @media #{$md-layout}{
        margin-top: 40px;
    }
    @media #{$xs-layout}{
        margin-top: 40px;
    }
    > h4 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
    }
    .grand-total-content {
        margin: 24px 0 6px;
        .single-grand-total {
            display: flex;
            margin: 0 0 20px;
            &:last-child {
                margin: 0 0 0;
            }
            .single-grand-total-left {
                margin-right: 34px;
            }
            .single-grand-total-right {
                > span {
                    color: #262626;
                }
                > ul {
                    li {
                        display: flex;
                        font-size: 12px;
                        color: #262626;
                        margin: 0 0 5px;
                        &:last-child {
                            margin: 0 0 0;
                        }
                        input {
                            width: auto;
                            height: auto;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
    > a {
        color: #262626;
    }
    .cart-total-wrap {
        display: flex;
        border-top: 1px solid #DBDBDB;
        padding: 20px 0 0 0;
        margin: 20px 0 37px 0;
        .single-cart-total-left {
            margin-right: 34px;
        }
        .single-cart-total-right {
            span {
                color: #262626;
            }
        }
    }
    .grand-btn {
        margin-left: -30px;
        margin-right: -30px;
        a {
            background-color: #262626;
            color: #fff;
            text-align: center;
            font-size: 14px;
            display: block;
            padding: 23px 10px 20px;
            font-weight: 600;
            text-transform: uppercase;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
}


