/*-------- 17. Product details style ---------*/

.product-big-img-style {
    .easyzoom-style {
        position: relative;
        .easyzoom {
            a {
                img {
                    width: 100%;
                }
            }
        }
        .img-gallery {
            img {
                width: 100%;
            }
        }
        > span {
            display: inline-block;
            color: #fff;
            top: 20px;
            width: 90px;
            height: 31px;
            line-height: 31px;
            border-radius: 50px;
            text-align: center;
            position: absolute;
            font-size: 12px;
            z-index: 999;
            &.badge-right {
                right: 20px;
            }
            &.badge-black {
                background-color: #1c1c1c;
            }
        }
        > .pro-dec-video {
            position: absolute;
            right: 25px;
            bottom: 27px;
            z-index: 999;
            a {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                font-size: 16px;
                background-color: #fff;
                text-align: center;
                display: inline-block;
                i {
                    line-height: 50px;
                }
            }
        }
        > .pro-dec-zoom-img {
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 999;
            a {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                font-size: 14px;
                background-color: $theme-color;
                color: #fff;
                text-align: center;
                display: inline-block;
                border-radius: 5px;
                i {
                    line-height: 30px;
                }
            }
        }
    }
}

.product-small-img-style {
    .slick-list {
        width: 450px;
        margin: 0 auto;
        @media #{$xs-layout} {
            width: auto;
        }
        .product-dec-small {
            border: 1px solid transparent;
            margin: 0 3px;
            position: relative;
            &.slick-current {
                border: 1px solid #262626;
                img {
                    opacity: 1;
                }
            }
            img {
                width: 100%;
                opacity: 0.4;
            }
        }
    }
}

span {
    &.pro-dec-icon {
        color: #262626;
        font-size: 25px;
        position: absolute;
        line-height: 1; 
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 9;
        &:hover {
            color: $theme-color;
        }
        i {
            line-height: 1;
            display: inline-block;
        }
    }
    &.pro-dec-prev {
        left: 22%;
        @media #{$xl-layout} {
            left: 7%;
        }
        @media #{$lg-layout} {
            left: -2%;
        }
        @media #{$md-layout} {
            left: 14%;
        }
        @media #{$xs-layout} {
            left: -8px;
        }
    }
    &.pro-dec-next {
        left: auto;
        right: 22%;
        @media #{$xl-layout} {
            right: 7%;
        }
        @media #{$lg-layout} {
            right: -2%;
        }
        @media #{$md-layout} {
            right: 14%;
        }
        @media #{$xs-layout} {
            right: -8px;
        }
    }
}

.pro-dec-icon2 {
    span {
        &.pro-dec-prev {
            left: 16%;
            @media #{$xx-layout} {
                left: 2%;
            }
            @media #{$xl-layout} {
                left: -1%;
            }
            @media #{$lg-layout} {
                left: -2%;
            }
            @media #{$md-layout} {
                left: 14%;
            }
            @media #{$xs-layout} {
                left: -8px;
            }
        }
        &.pro-dec-next {
            left: auto;
            right: 16%;
            @media #{$xx-layout} {
                right: 2%;
            }
            @media #{$xl-layout} {
                right: -1%;
            }
            @media #{$lg-layout} {
                right: -2%;
            }
            @media #{$md-layout} {
                right: 14%;
            }
            @media #{$xs-layout} {
                right: -8px;
            }
        }
    }
}

.description-review-wrapper {
    margin: 42px 0 29px;
    .pro-dec-accordion {
        .panel-heading {
            h4 {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                a {
                    display: block;
                    position: relative;
                    text-transform: capitalize;
                    border-bottom: 1px solid #D8D8D8;
                    padding: 16px 0 17px;
                    color: $theme-color;
                    &::after {
                        content: "\f068";
                        color: #262626;
                        font-family: FontAwesome;
                        position: absolute;
                        right: 0;
                        top: 19px;
                        font-size: 11px;
                        font-weight: normal;
                    }
                    &.collapsed {
                        color: #262626;
                        &:hover {
                            color: $theme-color;
                        }
                        &::after {
                            content: "\f067";
                        }
                    }
                }
            }
        }
        .product-description-wrapper {
            .panel-body {
                padding: 23px 0 16px;
                p {
                    color: #989898;
                    margin: 0;
                }
            }
        }
        .pro-additional-info {
            .panel-body {
                padding: 23px 0 16px;
                ul {
                    li {
                        color: #989898;
                        span {
                            
                        }
                        a {
                            color: #989898;
                        }
                        i {
                            color: #f5b223;
                            margin: 0 5px 0 0;
                        }
                    }
                }
            }
        }
        .description-review {
            .panel-body {
                padding: 23px 0 16px;
                .ratting-form-wrapper {
                    > h6 {
                        font-weight: 600;
                        margin: 0 0 29px;
                        color: #262626;
                        font-size: 14px;
                    }
                    .review-comments {
                        p {
                            color: #989898;
                            margin: 0;
                        }
                        h6 {
                            color: #262626;
                            font-weight: 600;
                            font-size: 14px;
                            margin: 5px 0 18px;
                        }
                    }
                    > span {
                        font-size: 12px;
                        color: #989898;
                        display: block;
                        line-height: 1;
                        margin: 0 0 9px;
                    }
                    > p{
                        color: #989898;
                        font-size: 12px;
                        margin: 0;
                        font-family: $montserrat;
                        font-style: italic;
                        span {
                            color: red;
                        }
                    }
                    .star-box-wrap {
                        display: flex;
                        margin: 27px 0 18px;
                        @media #{$xs-layout} {
                            display: block;
                        }
                        .single-ratting-star {
                            margin-right: 20px;
                            i {
                                color: #989898;
                                font-size: 14px;
                                transition: all .3s ease 0s;
                                cursor: pointer;
                            }
                            &:hover i {
                                color: #f5b223;
                            }
                        }
                    }
                    .ratting-form {
                        @media #{$md-layout} {
                            margin-right: 0px;
                        }
                        @media #{$xs-layout} {
                            margin-right: 0px;
                        }
                        form {
                           .rating-form-style {
                                label {
                                    color: #989898;
                                    text-transform: capitalize;
                                    margin: 0 0 8px;
                                    span {
                                        color: red;
                                    }
                                }
                                input {
                                    border: 1px solid #e8e8e8;
                                    background: transparent;
                                    height: 45px;
                                    &:focus {
                                        border: 1px solid #343538;
                                    }
                                }
                                textarea {
                                    border: 1px solid #e7e7e7;
                                    background: transparent;
                                    height: 200px;
                                    &:focus {
                                        border: 1px solid #343538;
                                    }
                                }
                            }
                            .form-submit {
                                input {
                                    padding: 10px 40px;
                                    text-transform: uppercase;
                                    width: auto;
                                    color: #fff;
                                    background-color: #dcb86c;
                                    border: none;
                                    &:hover {
                                        background-color: #343538;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pro-details-group {
    margin: 40px 0 0;
    @media #{$xs-layout} {
        margin: 15px 0 0;
    }
    .single-group {
        display: flex;
        margin-bottom: 20px;
        .pro-dec-title-stock {
            margin: 5px 0 0 25px;
            .pro-dec-title {
                h5 {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 0 0 5px;
                    color: #262626;
                    a {
                        color: #262626;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
            .pro-dec-price-stock {
                span {
                    color: #989898;
                    font-size: 14px;
                }
            }
        }
    }
}
.cart-plus-minus {
    display: inline-block;
    height: 60px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 80px;
    input.cart-plus-minus-box {
        background: transparent none repeat scroll 0 0;
        border: medium none;
        color: #343538;
        float: left;
        font-size: 14px;
        height: 60px;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 80px;
        border: 1px solid #e8e8e8;
        &:focus {
            border: 1px solid #343538;
        }
    }
    .qtybutton {
        color: #343538;
        cursor: pointer;
        float: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        position: absolute;
        text-align: center;
        transition: all 0.3s ease 0s;
        width: 24px;
        &.dec {
            height: 60px;
            left: 0;
            padding-top: 20px;
            top: 0;
        }
        &.inc {
            height: 60px;
            padding-top: 20px;
            right: 0;
            top: 0;
        }
    }
}
.pro-details-size {
    margin-top: 17px;
    span {
        color: #262626;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0 0 8px;
        display: block;
    }
    ul {
        display: flex;
        li {
            margin-right: 7px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 14px;
                color: #262626;
                display: inline-block;
                border: 1px solid #e8e8e8;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                &:hover {
                    border: 1px solid $theme-color;
                    color: $theme-color;
                }
            }
        }
    }
}
.pro-details-color {
    margin: 35px 0 0px;
    span {
        color: #262626;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0 0 4px;
        display: block;
    }
    ul {
        display: flex;
        li {
            margin-right: 10px;
            position: relative;
            &:before {
                position: absolute;
                left: -3px;
                top: -3px;
                border: 1px solid #e8e8e8;
                width: 46px;
                height: 46px;
                content: "";
                transition: all 0.3s ease 0s;
                pointer-events: none;
            }
            a {
                display: block;
            }
            &:hover:before {
                border: 1px solid #dcb86c;
            }
        }
    }
}

.product-dec-left {
	width: 160px;
	float: left;
	display: block;
	margin-top: 20px;
    @media #{$xx-layout}{
        width: 130px;
    }
    @media #{$xl-layout}{
        width: 130px;
    }
    @media #{$lg-layout}{
        width: 100px;
    }
    @media #{$xs-layout}{
        width: 60px;
    }
    @media #{$sm-layout}{
        width: 100px;
    }
    &.product-small-img-style {
        .slick-list {
            width: auto;
            .product-dec-small {
                margin-bottom: 8px;
            }
        }
    }
}
.product-dec-right {
	width: calc(100% - 160px);
	padding-left: 8px;
	float: left;
    @media #{$xx-layout}{
        width: calc(100% - 130px);
    }
    @media #{$xl-layout}{
        width: calc(100% - 130px);
    }
    @media #{$lg-layout}{
        width: calc(100% - 100px);
    }
    @media #{$xs-layout}{
        width: calc(100% - 60px);
    }
    @media #{$sm-layout}{
        width: calc(100% - 100px);
    }
}
.pro-dec-icon-2 {
	color: #262626;
	cursor: pointer;
	display: inline-block;
	font-size: 25px;
	left: 50%;
	line-height: 1;
	padding: 0 10px;
	position: absolute;
	transform: translateX(-50%);
	z-index: 99;
    i {
        line-height: 1;
    }
    &.pro-dec-prev-2 {
        top: -31px;
        line-height: 1;
    }
    &.pro-dec-next-2 {
        bottom: -31px;
        line-height: 1;
        @media #{$md-layout}{
            bottom: -22px;
        }
        @media #{$xs-layout}{
            bottom: -22px;
        }
        @media #{$sm-layout}{
            bottom: -22px;
        }
    }
}

@media #{$md-layout} {
    .product-details-area {
        &.mt-175 {
            margin-top: 0;
        }
    }
}

@media #{$xs-layout} {
    .product-details-area {
        &.mt-175 {
            margin-top: 0;
        }
    }
    .related-product-area {
        &.pb-45 {
            padding-bottom: 15px;
        }
    }
}





