/*-------- 21. Order tracking style ---------*/

.order-tracking-content {
    p {
        line-height: 1.714286;
        margin: 0;
        text-align: center;
        width: 98%;
    }
    .order-tracking-form {
        margin: 30px 0 0;
        .sin-order-tracking {
            margin: 0 0 30px;
            label {
                color: #262626;
                margin: 0 0 10px;
            }
            input {
                background: transparent;
                border: 1px solid #e7e7e7;
                height: 70px;
                padding: 10px 20px;
                color: #989898;
                font-size: 14px;
                &:focus {
                    border: 1px solid #262626;
                }
            }
        }
        .order-track-btn {
            text-align: center;
            a {
                display: inline-block;
                font-weight: 400;
                color: #fff;
                background-color: #262626;
                line-height: 1;
                padding: 18px 50px;
                text-transform: uppercase;
                &:hover {
                    background-color: $theme-color;
                }
            }
        }
    }
}



