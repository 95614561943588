/*-----------------------------------------------------------------------------------
    
    Template Name: Mantis - Minimal eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Section title style
    6. Subscribe style
    7. Instagram style
    8. Footer style
    9. Others style
    10. Service style
    11. Animation style
    12. Testimonial style
    13. Video style
    14. Blog style
    15. Breadcrumb style
    16. Shop style
    17. Product details style
    18. Compare style
    19. Cart style
    20. Wishlist style
    21. Order tracking style
    22. My account style
    23. Login register style
    24. Checkout style
    25. About us style
    26. Team style
    27. Contact us style
    28. Elements style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Playfair+Display:400,700,900&display=swap|Noto+Serif:400,700&display=swapVidaloka&display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #4A4141;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4A4141;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #4A4141;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #4A4141;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.border-top-1 {
  border-top: 1px solid #363636;
}

.border-top-2 {
  border-top: 1px solid #e7e7e7;
}

.border-bottom-1 {
  border-bottom: 1px solid #e7e7e7;
}

.border-bottom-2 {
  border-bottom: 1px solid #ebebeb;
}

a:hover {
  color: #a38b5e;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .mt-100 {
    margin-top: 50px;
  }
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

@media only screen and (max-width: 767px) {
  .pt-85 {
    padding-top: 45px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 45px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 40px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .pb-50 {
    padding-bottom: 20px;
  }
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 20px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 40px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 45px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 86px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

button, input, optgroup, select, textarea {
  font-family: "Montserrat", sans-serif;
}

.custom-container {
  max-width: 1774px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container {
    max-width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.custom-container-2 {
  max-width: 1694px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container-2 {
    max-width: 1266px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container-2 {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container-2 {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container-2 {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container-2 {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container-2 {
    max-width: 540px;
  }
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-width-23 {
  flex: 0 0 23%;
  max-width: 23%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-23 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-23 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-width-23 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-width-15 {
  flex: 0 0 15%;
  max-width: 15%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-15 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-15 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-width-15 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-width-18 {
  flex: 0 0 18.61%;
  max-width: 18.61%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-18 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-18 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-col-width-81 {
  flex: 0 0 81.39%;
  max-width: 81.39%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-width-81 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-width-81 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #a38b5e;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.transparent-bar-2 {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .transparent-bar-2 {
    position: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .transparent-bar-2 {
    position: inherit;
  }
}

/*--
    - Background Color
------------------------------------------*/
.bg-black {
  background-color: #1C1C1C;
}

.bg-black-2 {
  background-color: #1F1F1F;
}

.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #F9F9F9;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: '';
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: all 0.5s ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.padding-20-row-col .container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-10-row-col .container-fluid {
  padding-right: 5px;
  padding-left: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.custom-column {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.custom-column.column-width-61 {
  flex: 0 0 61%;
}

@media only screen and (max-width: 767px) {
  .custom-column.column-width-61 {
    flex: 0 0 100%;
  }
}

.custom-column.column-width-39 {
  flex: 0 0 39%;
}

@media only screen and (max-width: 767px) {
  .custom-column.column-width-39 {
    flex: 0 0 100%;
  }
}

.custom-padding-1 {
  padding-left: 0;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .custom-padding-1 {
    padding-right: 0px;
  }
}

.custom-padding-2 {
  padding-left: 5px;
  padding-right: 0px;
}

@media only screen and (max-width: 767px) {
  .custom-padding-2 {
    padding-left: 0px;
  }
}

.custom-d-flex {
  display: flex;
}

.height-100-percent {
  height: 100%;
}

/*-------- 2. Header style ---------*/
.main-header-wrap {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-header-wrap {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-header-wrap {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    padding: 30px 0;
  }
  .header-area .header-res-none {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-area {
    padding: 25px 0;
  }
  .header-area .header-res-none {
    display: none;
  }
}

.logo a.logo-none {
  display: block;
}

.logo a.logo-visible {
  display: none;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .logo a img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .logo a img {
    width: 120px;
  }
}

.headroom--not-top .logo a.logo-none {
  display: none;
}

.headroom--not-top .logo a.logo-visible {
  display: block;
}

.header-info a {
  color: #989898;
  font-size: .857em;
  margin-right: 18px;
}

.header-info a:last-child {
  margin-right: 0;
}

.header-info a:hover {
  color: #a38b5e;
}

.header-info.header-info-inc a {
  font-size: 14px;
}

.curr-lang-wrap > ul > li {
  display: inline-block;
  position: relative;
  margin-right: 33px;
  line-height: 37px;
  transition: all .3s ease 0s;
}

.curr-lang-wrap > ul > li:last-child {
  margin-right: 0px;
}

.curr-lang-wrap > ul > li::before {
  color: #989898;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 1em;
  position: absolute;
  right: -15px;
  top: 2px;
}

.curr-lang-wrap > ul > li.curr-lang-hover::before {
  color: #a38b5e;
  content: "\f106";
  font-family: FontAwesome;
  font-size: 1em;
  position: absolute;
  right: -15px;
  top: 2px;
}

.curr-lang-wrap > ul > li > a {
  color: #989898;
  font-size: .857em;
  position: relative;
}

.curr-lang-wrap > ul > li:hover > a {
  color: #a38b5e;
}

.curr-lang-wrap > ul > li > ul {
  position: absolute;
  z-index: -11;
  top: 100%;
  left: 0;
  width: 69px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .curr-lang-wrap > ul > li > ul {
    left: -8px;
  }
}

.curr-lang-wrap > ul > li > ul li {
  display: block;
}

.curr-lang-wrap > ul > li > ul li a {
  display: block;
  color: #989898;
  font-size: .857em;
  padding: 13px 20px;
  line-height: 1;
}

.curr-lang-wrap > ul > li > ul li a:hover {
  color: #a38b5e;
}

.curr-lang-wrap > ul > li:hover ul {
  z-index: 99999;
  opacity: 1;
  visibility: visible;
}

.curr-lang-wrap.curr-lang-inc > ul > li > a {
  font-size: 14px;
}

.header-middle-mrg {
  padding: 32px 0 33px;
}

.header-ptb-hm11 {
  padding: 55px 0;
}

.header-search.header-search-mrg {
  margin: 0 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-search.header-search-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search.header-search-mrg {
    margin: 0 0px;
  }
}

.header-search.header-search-mrg2 {
  margin: 0 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-search.header-search-mrg2 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search.header-search-mrg2 {
    margin: 0 0px;
  }
}

.header-search .search-form {
  position: relative;
}

.header-search .search-form input {
  background-color: transparent;
  border: none;
  color: #989898;
  border-bottom: 1px solid #989898;
  height: 40px;
  padding: 2px 50px 2px 0;
}

.header-search .search-form input:focus {
  color: #262626;
}

.header-search .search-form button {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  color: #000;
  transform: translateY(-50%);
  transition: all .3s ease 0s;
}

.header-search .search-form button:hover {
  color: #a38b5e;
}

.header-component-wrap {
  display: flex;
  justify-content: flex-end;
}

.header-login a {
  font-size: 16px;
}

.header-login a > span {
  margin-left: 5px;
  font-size: 14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-login.ml-30 {
    margin-left: 15px;
  }
}

.quick-info a {
  font-size: 16px;
}

.quick-info.quick-info-white a {
  color: #fff;
}

.cart-wrap a {
  position: relative;
  font-size: 16px;
}

.cart-wrap a span.count-style {
  position: absolute;
  font-size: 10px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  display: block;
  top: -20px;
  right: -1px;
  width: 18px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background-color: #4A4141;
}

.cart-wrap.cart-white a {
  color: #fff;
}

.main-wrapper .body-overlay, .main-wrapper .body-overlay-2, .main-wrapper .body-overlay-3, .main-wrapper .body-overlay-4, .main-wrapper-2 .body-overlay, .main-wrapper-2 .body-overlay-2, .main-wrapper-2 .body-overlay-3, .main-wrapper-2 .body-overlay-4, .main-wrapper-3 .body-overlay, .main-wrapper-3 .body-overlay-2, .main-wrapper-3 .body-overlay-3, .main-wrapper-3 .body-overlay-4, .main-wrapper-4 .body-overlay, .main-wrapper-4 .body-overlay-2, .main-wrapper-4 .body-overlay-3, .main-wrapper-4 .body-overlay-4 {
  background-color: #232324;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  z-index: 99999;
}

.main-wrapper.overlay-active .body-overlay,
.main-wrapper.overlay-active-2 .body-overlay-2,
.main-wrapper.overlay-active-3 .body-overlay-3,
.main-wrapper.overlay-active-4 .body-overlay-4, .main-wrapper-2.overlay-active .body-overlay,
.main-wrapper-2.overlay-active-2 .body-overlay-2,
.main-wrapper-2.overlay-active-3 .body-overlay-3,
.main-wrapper-2.overlay-active-4 .body-overlay-4, .main-wrapper-3.overlay-active .body-overlay,
.main-wrapper-3.overlay-active-2 .body-overlay-2,
.main-wrapper-3.overlay-active-3 .body-overlay-3,
.main-wrapper-3.overlay-active-4 .body-overlay-4, .main-wrapper-4.overlay-active .body-overlay,
.main-wrapper-4.overlay-active-2 .body-overlay-2,
.main-wrapper-4.overlay-active-3 .body-overlay-3,
.main-wrapper-4.overlay-active-4 .body-overlay-4 {
  opacity: .7;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 110px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 99999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 70px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 50px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 55px;
  right: 50px;
  font-size: 25px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 25px;
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    right: 23px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  color: #a38b5e;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  margin: 0 0 45px;
  font-weight: 700;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  max-height: -webkit-calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li:last-child {
  margin-bottom: 0px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 75px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 0px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title p {
  font-size: 11px;
  margin: 7px 0 0px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title p span {
  font-size: 11px;
  font-weight: 700;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  font-family: Verdana,sans-serif;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 39px 0 38px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 14px;
  margin: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 18px;
  float: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 20px 20px 21px;
  background-color: #262626;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #a38b5e;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.quickinfo-wrapper-active {
  position: fixed;
  top: 0;
  width: 470px;
  padding: 68px 70px 70px;
  min-height: 100vh;
  overflow: auto;
  bottom: 0;
  z-index: 99999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.quickinfo-wrapper-active.quickinfo-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.quickinfo-wrapper-active a.quickinfo-close {
  position: absolute;
  top: 35px;
  right: 50px;
  font-size: 25px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickinfo-wrapper-active a.quickinfo-close {
    top: 25px;
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .quickinfo-wrapper-active a.quickinfo-close {
    top: 20px;
    right: 23px;
    font-size: 23px;
  }
}

.quickinfo-wrapper-active a.quickinfo-close:hover {
  color: #a38b5e;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-logo {
  margin: 0 0 31px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner {
  margin: 60px 0 58px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner a {
  display: block;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-banner a img {
  width: 100%;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-address {
  margin: 0 0 18px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-map-link a {
  display: inline-block;
  text-decoration: underline;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-map-link a:hover {
  color: #a38b5e;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social {
  margin: 15px 0 37px;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li {
  display: inline-block;
  margin: 0 21px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li:last-child {
  margin: 0 0px 0 0;
}

.quickinfo-wrapper-active .quickinfo-wrap .quickinfo-social ul li a {
  font-size: 14px;
}

.menu-support-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
  margin-right: 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li {
    margin-right: 35px;
  }
}

.main-menu > nav > ul > li:last-child {
  margin-right: 0px;
}

.main-menu > nav > ul > li > a {
  text-transform: uppercase;
  display: block;
}

.main-menu > nav > ul > li > a > span {
  position: absolute;
  top: 2px;
  right: 0px;
  background-color: #e43359;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px 3px;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
}

.main-menu > nav > ul > li > a > span::before {
  content: "";
  border-top: 4px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 100%;
  color: #e43359;
}

.main-menu > nav > ul > li > a.active {
  font-weight: 600;
  color: #a38b5e;
}

.main-menu > nav > ul > li > a.active::after {
  content: "";
  right: 0;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 2px;
  top: 64%;
  height: 2px;
  background-color: #a38b5e;
}

.main-menu > nav > ul > li > ul {
  position: absolute;
  text-align: left;
  top: 100%;
  margin-top: 20px;
  z-index: -111;
  visibility: hidden;
  opacity: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 38px 0 rgba(41, 44, 58, 0.09);
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
}

.main-menu > nav > ul > li > ul li a {
  color: #666;
  font-size: 12px;
  display: block;
  padding: 7px 40px;
  text-transform: capitalize;
}

.main-menu > nav > ul > li > ul li a span {
  position: relative;
  display: inline-block;
}

.main-menu > nav > ul > li > ul li a span::after {
  content: "";
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
  right: 0;
  left: auto;
  width: 0;
  position: absolute;
  transition: width 300ms ease-out;
  bottom: 0;
  color: #989898;
}

.main-menu > nav > ul > li > ul li a:hover span::after {
  left: 0;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
  color: #4a4141;
}

.main-menu > nav > ul > li > ul li a:hover {
  color: #4a4141;
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 240px;
  padding: 30px 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 240px;
  padding: 30px 0;
}

.main-menu > nav > ul > li > ul.mega-menu {
  padding: 34px 5px 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu {
    padding: 34px 0px 36px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu > li {
  float: left;
  display: inline-block;
}

.main-menu > nav > ul > li > ul.mega-menu > li a.menu-title {
  color: #4a4141;
  text-transform: uppercase;
  font-weight: 700;
}

.main-menu > nav > ul > li > ul.mega-menu > li ul {
  margin: 10px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 {
  width: 670px;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 li {
  width: 203.333px;
}

.main-menu > nav > ul > li > ul.mega-menu-width1.mega-menu-hm14-width1 {
  left: -295px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width1.mega-menu-hm14-width1 {
    left: -328px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width1.mega-menu-hm14-width1 {
    left: -176px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
  left: -500px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 {
  left: -580px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 {
    left: -745px;
    width: 1100px;
  }
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 li {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 {
    left: -750px;
    width: 1100px;
  }
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 li {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 {
    width: 890px;
    left: -605px;
  }
  .main-menu > nav > ul > li > ul.mega-menu-width2.res-mega-menu-width2 li {
    width: 288px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    left: -435px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 962px;
    left: -518px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width2 li {
  width: 370px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 li {
    width: 300px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width2.mega-menu-hm14-width2 {
  left: auto;
  right: -296px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.mega-menu-hm14-width2 {
    right: -328px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.mega-menu-hm14-width2 {
    right: -192px;
  }
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 {
  width: 100%;
  left: 0px;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li {
  float: inherit;
  display: block;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 {
  width: 1200px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 {
    width: 962px;
  }
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4.mega-menu-mrg-top {
  margin-top: 27px;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
  display: inline-block;
  float: left;
  width: 400px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
    width: 320.667px;
  }
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap {
  position: relative;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content {
  position: absolute;
  bottom: 25px;
  left: 40px;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content span {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  margin: 6px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
    font-size: 35px;
  }
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap:hover::before {
  animation: effect_shine 1s;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li > a {
  padding: 7px 15px;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li > a img {
  width: 100%;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul {
  min-width: 100%;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul > li {
  width: 48%;
  display: inline-block;
}

.main-menu > nav > ul > li.position-static > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul > li > a {
  padding: 7px 15px;
}

.main-menu > nav > ul > li:hover > a {
  color: #a38b5e;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  z-index: 111;
}

.main-menu.menu-lh-1 > nav > ul > li > a {
  line-height: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-lh-2 > nav > ul > li {
    margin-right: 22px;
  }
  .main-menu.menu-lh-2 > nav > ul > li:last-child {
    margin-right: 0;
  }
}

.main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-lh-3 > nav > ul > li {
    margin-right: 16px;
  }
  .main-menu.menu-lh-3 > nav > ul > li:last-child {
    margin-right: 0;
  }
}

.main-menu.menu-lh-3 > nav > ul > li > a {
  line-height: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-lh-3 > nav > ul > li > a {
    font-size: 16px !important;
  }
}

.main-menu.menu-lh-3 > nav > ul > li > a.active::after {
  top: 58% !important;
}

.main-menu.menu-lh-3 > nav > ul > li > a > span {
  top: 18px !important;
}

.main-menu.main-menu-white > nav > ul > li > a {
  color: #fff;
}

.main-menu.main-menu-white > nav > ul > li > a > span {
  top: 36px;
}

.main-menu.main-menu-white > nav > ul > li > a.active::after {
  background-color: #fff;
  top: 56%;
}

.main-menu.main-menu-white.menu-border-theme-color > nav > ul > li > a.active {
  color: #a38b5e;
}

.main-menu.main-menu-white.menu-border-theme-color > nav > ul > li > a.active::after {
  background-color: #a38b5e;
}

.main-menu.main-menu-blod > nav > ul > li > a {
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}

.main-menu.main-menu-blod > nav > ul > li > a > span {
  top: 36px;
}

.main-menu.main-menu-blod > nav > ul > li > a.active::after {
  top: 56%;
}

.main-menu.main-menu-center {
  justify-content: center;
  display: flex;
}

.main-menu.menu-mrg-left {
  margin-left: 274px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-mrg-left {
    margin-left: 147px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-mrg-left {
    margin-left: 147px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-mrg-left {
    margin-left: 20px;
  }
}

.headroom--not-top .main-menu.menu-stick-black > nav > ul > li > a {
  color: #4a4141;
}

.headroom--not-top .main-menu.menu-stick-black > nav > ul > li > a > span {
  top: 9px;
}

.headroom--not-top .main-menu.menu-stick-black > nav > ul > li > a.active {
  color: #a38b5e;
}

.headroom--not-top .main-menu.menu-stick-black > nav > ul > li > a.active::after {
  background-color: #a38b5e;
  top: 59%;
}

.headroom--not-top .main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 89px;
}

.headroom--not-top .main-menu.main-menu-blod > nav > ul > li > a > span {
  top: 8px;
}

.headroom--not-top .main-menu.main-menu-blod > nav > ul > li > a.active {
  color: #a38b5e;
}

.headroom--not-top .main-menu.main-menu-blod > nav > ul > li > a.active::after {
  top: 61%;
}

.headroom--not-top .header-ptb-hm11 {
  padding: 25px 0;
}

.support-demo-wrap {
  display: flex;
  justify-content: flex-end;
}

.support-demo-wrap a {
  text-transform: uppercase;
  margin-right: 28px;
}

.support-demo-wrap a:last-child {
  margin-right: 0;
}

/*--------- intelligent-header  -----------*/
.site-header-inner {
  position: fixed;
  top: 0;
  transform: translate(0, -100%);
  will-change: transform, background;
}

.site-header-outer .intelligent-header.headroom--pinned {
  transform: translate(0, 0);
  transition: all .5s ease 0s;
}

.intelligent-header.headroom--unpinned {
  transform: translate(0, -100%);
  visibility: hidden;
  opacity: 0;
}

.site-header-outer {
  position: relative;
}

.site-header-outer .intelligent-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  transition: all .0s ease 0s;
}

.site-header-outer .intelligent-header.header-width-14 {
  left: 50%;
  transform: translateX(-50%) !important;
}

.site-header-outer .headroom--not-top.intelligent-header {
  position: fixed;
  background-color: #fff;
}

.site-header-outer .headroom--not-top.intelligent-header.header-width-14 {
  width: 1310px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .site-header-outer .headroom--not-top.intelligent-header.header-width-14 {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .site-header-outer .headroom--not-top.intelligent-header.header-width-14 {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-header-outer .headroom--not-top.intelligent-header.header-width-14 {
    width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .site-header-outer .headroom--not-top.intelligent-header.header-width-14 {
    width: 100%;
  }
}

/*--------- intelligent-header 2  -----------*/
.site-header-outer .intelligent-header-2.headroom--pinned {
  transform: translate(0, 0);
}

.intelligent-header-2.headroom--unpinned {
  transform: translate(0, -100%);
  visibility: hidden;
  opacity: 0;
}

.site-header-outer .intelligent-header-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  transition: all .5s ease 0s;
}

.site-header-outer .headroom--not-top.intelligent-header-2 {
  position: fixed;
  background-color: #fff;
}

.site-header-outer .stick-bg-black.headroom--not-top.intelligent-header-2 {
  background-color: #1F1F1F;
}

/*--------- mobile menu  -----------*/
.header-small-mobile {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-mobile {
    display: block;
  }
}

.header-small-mobile .mobile-logo.logo-width a img {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .header-small-mobile .mobile-logo.logo-width a img {
    width: 120px;
  }
}

.header-small-mobile .mobile-header-right-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-small-mobile .mobile-header-right-wrap .cart-wrap {
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .cart-wrap.cart-wrap-white a {
  color: #fff;
}

.header-small-mobile .mobile-header-right-wrap .mobile-off-canvas {
  margin-left: 30px;
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .mobile-off-canvas a {
  font-size: 20px;
}

.header-small-mobile .mobile-header-right-wrap .mobile-off-canvas.off-canvas-white a {
  color: #fff;
}

.header-small-mobile .mobile-header-right-wrap .header-login {
  line-height: 1;
}

.header-small-mobile .mobile-header-right-wrap .header-login a {
  font-size: 20px;
  margin-right: 30px;
}

.header-small-mobile.header-small-mobile-ptb {
  padding: 30px 0;
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 260px;
    right: -260px;
  }
}

.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.mobile-off-canvas-active .mobile-aside-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: #343538;
}

.mobile-off-canvas-active .mobile-aside-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: #a38b5e;
  transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #333;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 15px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: #a38b5e;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #1f2226;
  transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #1f2226;
  transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 15px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #a38b5e;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 15px;
  display: block;
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 14px;
  position: relative;
  top: 5px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #a38b5e;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #a38b5e;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info {
  border-bottom: 1px solid #ddd;
  padding: 0 0 34px;
  margin: 0 0 34px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-quick-info ul li i {
  font-size: 17px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
  color: #bd081c;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
  color: #e4405f;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
  color: #dd4b39;
}

/*------- home 2 ------*/
.header-search-2 a {
  font-size: 16px;
}

.header-search-2.search-white a {
  color: #fff;
}

.headroom--not-top .header-search-2.stick-white a {
  color: #4a4141;
}

.headroom--not-top .cart-wrap.stick-white a {
  color: #4a4141;
}

.headroom--not-top .quick-info.stick-white a {
  color: #4a4141;
}

.search-content-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232324;
}

.search-content-wrap.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-content-wrap.search-visible .search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-content-wrap > .search-close {
  font-size: 30px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s ease 0s;
}

.search-content-wrap > .search-close i {
  transition: all .3s ease 0s;
  display: inline-block;
}

.search-content-wrap > .search-close i:hover {
  color: #a38b5e;
}

.search-content-wrap > .search-close:hover i {
  transform: rotate(90deg);
}

.search-content-wrap .search-content {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-content-wrap .search-content p {
  font-size: 18px;
  margin: 0 0 15px;
  color: #232324;
  font-weight: 300;
}

.search-content-wrap .search-content .search-form {
  position: relative;
}

.search-content-wrap .search-content .search-form input {
  width: 800px;
  background-color: transparent;
  border: 0;
  text-align: center;
  font-size: 30px;
  padding: 25px 40px 21px 36px;
  color: #232324;
  transition: all .3s ease-out;
  font-weight: 300;
  max-width: 100%;
  height: auto;
  border-bottom: 2px solid #232324;
}

.search-content-wrap .search-content .search-form input::-moz-input-placeholder {
  color: #232324;
}

.search-content-wrap .search-content .search-form input::-webkit-input-placeholder {
  color: #232324;
}

@media only screen and (max-width: 767px) {
  .search-content-wrap .search-content .search-form input {
    padding: 10px 50px 10px 20px;
    font-size: 18px;
  }
}

.search-content-wrap .search-content .search-form .button-search {
  position: absolute;
  top: 28px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  font-size: 30px;
  height: 30px;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .search-content-wrap .search-content .search-form .button-search {
    top: 10px;
    font-size: 20px;
  }
}

.search-content-wrap .search-content .search-form .button-search:hover {
  color: #a38b5e;
}

.header-padding {
  padding: 48px 0;
}

.header-sidebar-wrap-all {
  padding: 0px 0px 50px;
  background-color: #ffffff;
  width: 305px;
  z-index: 9;
  float: left;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all {
    padding: 0px 0px 30px;
    width: 270px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all {
    padding: 0px 0px 30px;
    width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all {
    padding: 0px 0px 30px;
    width: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sidebar-wrap-all {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidebar-wrap-all {
    display: none;
  }
}

.header-sidebar-wrap-all .header-sidebar-wrap {
  margin-right: 80px;
  padding-top: 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all .header-sidebar-wrap {
    padding-top: 50px;
    margin-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all .header-sidebar-wrap {
    padding-top: 50px;
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all .header-sidebar-wrap {
    padding-top: 50px;
    margin-right: 80px;
  }
}

.main-menu-2 > nav > ul > li {
  display: block;
  position: relative;
  margin-bottom: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li {
    margin-bottom: 15px;
  }
}

.main-menu-2 > nav > ul > li:last-child {
  margin-bottom: 0px;
}

.main-menu-2 > nav > ul > li > a {
  font-size: 24px;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > a {
    font-size: 18px;
  }
}

.main-menu-2 > nav > ul > li > a > span {
  position: absolute;
  top: -20px;
  right: -25px;
  background-color: #e43359;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px 3px;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
}

.main-menu-2 > nav > ul > li > a > span::before {
  content: "";
  border-top: 4px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 100%;
  color: #e43359;
}

.main-menu-2 > nav > ul > li > a.active {
  color: #a38b5e;
}

.main-menu-2 > nav > ul > li > ul {
  position: absolute;
  text-align: left;
  top: -30px;
  margin-top: 20px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  left: 100%;
  background-color: #fff;
  box-shadow: 0 0 38px 0 rgba(41, 44, 58, 0.09);
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
}

.main-menu-2 > nav > ul > li > ul li a {
  color: #989898;
  font-size: 14px;
  display: block;
  padding: 9px 40px;
  text-transform: capitalize;
}

.main-menu-2 > nav > ul > li > ul li a img {
  width: 100%;
}

.main-menu-2 > nav > ul > li > ul li a span {
  position: relative;
  display: inline-block;
}

.main-menu-2 > nav > ul > li > ul li a span::after {
  content: "";
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
  right: 0;
  left: auto;
  width: 0;
  position: absolute;
  transition: width 300ms ease-out;
  bottom: 0;
  color: #989898;
}

.main-menu-2 > nav > ul > li > ul li a:hover span::after {
  left: 0;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
  color: #4a4141;
}

.main-menu-2 > nav > ul > li > ul li a:hover {
  color: #4a4141;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width {
  width: 240px;
  padding: 30px 0;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width {
  width: 240px;
  padding: 30px 0;
}

.main-menu-2 > nav > ul > li > ul.mega-menu {
  padding: 34px 5px 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu {
    padding: 20px 5px 20px;
  }
}

.main-menu-2 > nav > ul > li > ul.mega-menu > li {
  float: left;
  display: inline-block;
}

.main-menu-2 > nav > ul > li > ul.mega-menu > li a.menu-title {
  color: #4a4141;
  text-transform: uppercase;
  font-weight: 700;
}

.main-menu-2 > nav > ul > li > ul.mega-menu > li ul {
  margin: 10px 0 0;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width1 {
  width: 670px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width1 li {
  width: 208.333px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
  left: 100%;
  top: -187.8px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width2 li {
  width: 370px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 li {
    width: 335px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 li {
    width: 310px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 li {
    width: 250px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 1050px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 970px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 790px;
  }
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 {
  width: 1485px;
  left: 100%;
  top: -276.8px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 {
    width: 1050px;
    top: -184.8px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 {
    width: 970px;
    top: -170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 {
    width: 790px;
    top: -170px;
  }
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li {
  float: inherit;
  display: block;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 {
  width: 1200px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 {
    width: 1050px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 {
    width: 1000px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 {
    width: 790px;
  }
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4.mega-menu-mrg-top {
  margin-top: 27px;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
  display: inline-block;
  float: left;
  width: 400px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
    width: 322px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li {
    width: 258px;
  }
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap {
  position: relative;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content {
  position: absolute;
  bottom: 25px;
  left: 40px;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content span {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  margin: 6px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap .menu-banner-content h2 {
    font-size: 30px;
  }
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li.menu-banner-wrap:hover::before {
  animation: effect_shine 1s;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li > a {
  padding: 7px 15px;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul {
  min-width: 100%;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul > li {
  width: 48%;
  display: inline-block;
}

.main-menu-2 > nav > ul > li.position-static-inherit > .mega-menu-width3 > li > ul.mega-menu-width4 > li > ul > li > a {
  padding: 7px 15px;
}

.main-menu-2 > nav > ul > li:hover > a {
  color: #a38b5e;
}

.main-menu-2 > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.sidebar-social-copyright {
  margin-top: 185px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-social-copyright {
    margin-top: 85px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-social-copyright {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-social-copyright {
    margin-top: 50px;
  }
}

.sidebar-social-copyright .sidebar-social a {
  font-size: 14px;
  margin-right: 15px;
}

.sidebar-social-copyright .sidebar-social a:last-child {
  margin-right: 0;
}

.sidebar-social-copyright .sidebar-copyright p {
  font-size: 12px;
  margin: 8px 0 0;
}

.clickablemenu-wrapper-active {
  position: fixed;
  top: 0;
  width: 350px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -350px;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

@media only screen and (max-width: 767px) {
  .clickablemenu-wrapper-active {
    width: 260px;
    right: -260px;
  }
}

.clickablemenu-wrapper-active.clickablemenu-visible {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.clickablemenu-wrapper-active .clickable-menu-close {
  position: absolute;
  right: 45px;
  top: 62px;
  z-index: 9;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .clickablemenu-wrapper-active .clickable-menu-close {
    right: 15px;
    top: 35px;
  }
}

.clickablemenu-wrapper-active .clickable-menu-close i {
  display: inline-block;
  color: #4a4141;
  transition: all .3s ease-in-out;
}

.clickablemenu-wrapper-active .clickable-menu-close:hover i {
  color: #a38b5e;
  transform: rotate(90deg);
}

.clickablemenu-wrapper-active .clickable-menu-wrap {
  padding: 70px 70px 40px;
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap {
    padding: 70px 80px 40px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap {
    padding: 70px 45px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap {
    padding: 30px 20px 50px;
  }
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top {
  margin-top: 37px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav {
  height: 100%;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li {
  display: block;
  position: relative;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children .menu-expand {
  line-height: 30;
  top: 0;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 30px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children .menu-expand {
    left: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children .menu-expand {
    left: 84%;
  }
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 9px;
  margin-top: 19px;
  border-bottom: 1px solid #262626;
  transition: all 250ms ease-out;
  margin-left: 5px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #262626;
  transition: 0.4s;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li a {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0 20px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li a {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 0 15px;
  }
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li:hover > a {
  color: #a38b5e;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li ul {
  padding: 0px 0 13px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li ul li a {
  padding: 10px 15px 11px 27px;
  font-size: 14px;
  color: #262626;
  font-weight: 400;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li ul li a:hover {
  color: #a38b5e;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .clickable-menu-top .clickable-menu-navigation nav .final-clickable-menu li ul li ul li a {
  padding: 10px 15px 11px 37px;
  font-size: 14px;
  color: #262626;
  font-weight: 400;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-address-2 {
  margin: 0 0 25px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-address-2 ul li {
  font-size: 12px;
  margin-bottom: 6px;
  display: block;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-address-2 ul li:last-child {
  margin-bottom: 0;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-address-2 ul li a {
  font-size: 12px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-map-link-2 a {
  display: inline-block;
  text-decoration: underline;
  font-size: 12px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-map-link-2 a:hover {
  color: #a38b5e;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-social-2 {
  margin: 22px 0 0px;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-social-2 ul li {
  display: inline-block;
  margin: 0 21px 0 0;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-social-2 ul li:last-child {
  margin: 0 0px 0 0;
}

.clickablemenu-wrapper-active .clickable-menu-wrap .quickinfo-social-2 ul li a {
  font-size: 12px;
}

.category-menu {
  position: absolute;
  top: 203px;
  left: 80px;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu {
    top: 150px;
    left: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu {
    top: 130px;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu {
    top: 130px;
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu {
    top: 100px;
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .category-menu {
    top: 80px;
    left: 15px;
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category-menu {
    top: 80px;
    left: 30px;
  }
}

.category-menu nav ul li {
  margin-bottom: 28px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav ul li {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav ul li {
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu nav ul li {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .category-menu nav ul li {
    margin-bottom: 5px;
  }
}

.category-menu nav ul li:last-child {
  margin-bottom: 0;
}

.category-menu nav ul li a {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu nav ul li a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .category-menu nav ul li a {
    font-size: 14px;
  }
}

.category-menu nav ul li a:hover {
  color: #a38b5e;
}

/*-------- 3. Slider style ---------*/
.slider-area {
  position: relative;
}

.slider-area img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.mt-110 {
    margin-top: 0;
  }
  .slider-area.mt-175 {
    margin-top: 0;
  }
  .slider-area.mt-150 {
    margin-top: 0;
  }
  .slider-area.mt-160 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area.mt-110 {
    margin-top: 0;
  }
  .slider-area.mt-175 {
    margin-top: 0;
  }
  .slider-area.mt-150 {
    margin-top: 0;
  }
  .slider-area.mt-160 {
    margin-top: 0;
  }
}

.parallax-slider-area .parallax-same-style {
  margin-bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .parallax-slider-area .parallax-same-style {
    margin-bottom: 50px;
  }
}

.parallax-slider-area .parallax-same-style span {
  font-size: 18px;
  color: #fff;
  display: block;
  line-height: 1;
}

.parallax-slider-area .parallax-same-style h1 {
  font-weight: 700;
  margin: 0 0 14px;
  line-height: 1.15;
  font-size: 130px;
  text-shadow: 0 2px 15px rgba(72, 72, 72, 0.5);
  color: #fff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .parallax-slider-area .parallax-same-style h1 {
    font-size: 40px;
  }
}

.parallax-slider-area .parallax-same-style .parallax-btn a {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.parallax-slider-area .parallax-same-style.parallax-content-2 span {
  color: #4a4141;
}

.parallax-slider-area .parallax-same-style.parallax-content-2 h1 {
  color: #ff256c;
}

.parallax-slider-area .parallax-same-style.parallax-content-2 .parallax-btn a {
  color: #4a4141;
  border-bottom: 2px solid #4a4141;
}

.slider-height-1 {
  height: 800px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 480px;
  }
}

.slider-height-2 {
  height: 868px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-2 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 480px;
  }
}

.slider-height-3 {
  height: 820px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: 480px;
  }
}

.owl-item.active .slider-animated-1 span {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h2 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h4 {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h3 {
  animation-delay: 0.5s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 a {
  animation-delay: 1.6s;
  animation-name: zoomIn;
}

.owl-item.active .slider-animated-2 h2 {
  animation-delay: 1.0s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-2 a {
  animation-delay: 1.4s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-3 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-3 a {
  animation-delay: 1.6s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-4 h1 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-4 a {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.nav-style-2 .owl-nav div {
  color: #fff;
  font-size: 50px;
  left: 33px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 .owl-nav div {
    left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nav-style-2 .owl-nav div {
    left: 15px;
  }
}

.nav-style-2 .owl-nav div:hover {
  opacity: 0.75;
}

.nav-style-2 .owl-nav div.owl-next {
  left: auto;
  right: 33px;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 .owl-nav div.owl-next {
    right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nav-style-2 .owl-nav div.owl-next {
    right: 15px;
  }
}

.nav-style-2:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.slider-content-mrg {
  margin: 195px -40px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-mrg {
    margin: 140px 0px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg {
    margin: 140px 0px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg {
    margin: 80px 0px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg {
    margin: 100px 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg {
    margin: 135px 10px 0 10px;
  }
}

.slider-content-mrg-2 {
  margin: 243px 0px 0 255px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-mrg-2 {
    margin: 143px 0px 0 155px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg-2 {
    margin: 143px 0px 0 155px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg-2 {
    margin: 130px 0px 0 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-2 {
    margin: 160px 0px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-2 {
    margin: 160px 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-mrg-2 {
    margin: 160px 10px 0 30px;
  }
}

.slider-content h2 {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  line-height: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h2 {
    font-size: 25px;
  }
}

.slider-content h1 {
  font-size: 100px;
  font-weight: 700;
  color: #fff;
  margin: 15px 0 45px;
  line-height: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content h1 {
    line-height: 60px;
    font-size: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content h1 {
    line-height: 60px;
    font-size: 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content h1 {
    line-height: 50px;
    font-size: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content h1 {
    line-height: 42px;
    font-size: 50px;
    margin: 15px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h1 {
    line-height: 25px;
    font-size: 32px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content h1 {
    line-height: 40px;
    font-size: 50px;
  }
}

.slider-content .miss-chance {
  margin: 0 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .miss-chance {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .miss-chance {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .miss-chance {
    margin: 0 0 20px;
  }
}

.slider-content .miss-chance h4 {
  font-size: 20px;
  color: #fff;
  margin: 0;
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .slider-content .miss-chance h4 {
    font-size: 16px;
  }
}

.slider-content .miss-chance h4::before {
  position: absolute;
  content: "";
  left: -75px;
  top: 11px;
  height: 2px;
  width: 60px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .slider-content .miss-chance h4::before {
    width: 25px;
    left: -40px;
    top: 8px;
  }
}

.slider-content .miss-chance h4:after {
  position: absolute;
  content: "";
  right: -75px;
  top: 11px;
  height: 2px;
  width: 60px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .slider-content .miss-chance h4:after {
    width: 25px;
    right: -40px;
    top: 8px;
  }
}

.slider-btn-1 a {
  display: inline-block;
  color: #4a4141;
  font-weight: 600;
  font-size: 12px;
  background-color: #fff;
  line-height: 1;
  letter-spacing: 1px;
  padding: 18px 40px 17px;
  box-shadow: 7px 7px 0 rgba(79, 84, 124, 0.4);
}

.slider-btn-1 a:hover {
  background-color: #4a4141;
  color: #fff;
}

.slider-content-2 h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 600;
  color: #4a4141;
  line-height: 60px;
  margin: 0 0 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-2 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h2 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 h2 {
    font-size: 36px;
    line-height: 50px;
  }
}

.slider-content-3 h1 {
  font-size: 100px;
  color: #fff;
  font-weight: 700;
  color: #fff;
  line-height: 100px;
  text-shadow: rgba(124, 59, 59, 0.5) 8px 8px 0px;
  margin: 0 0 36px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-3 h1 {
    line-height: 90px;
    font-size: 85px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-3 h1 {
    line-height: 90px;
    font-size: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 h1 {
    line-height: 80px;
    font-size: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 h1 {
    line-height: 80px;
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-3 h1 {
    line-height: 45px;
    font-size: 33px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-3 h1 {
    line-height: 55px;
    font-size: 50px;
    margin: 0 0 30px;
  }
}

.slider-content-4 h1 {
  font-size: 140px;
  font-weight: bold;
  line-height: 120px;
  font-family: "Playfair Display", serif;
  color: #fff;
  margin: 0 0 47px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-4 h1 {
    font-size: 115px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4 h1 {
    font-size: 115px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4 h1 {
    font-size: 85px;
    line-height: 78px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 h1 {
    font-size: 70px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-4 h1 {
    font-size: 38px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-4 h1 {
    font-size: 45px;
    line-height: 45px;
    margin: 0 0 30px;
  }
}

.slider-video a {
  display: inline-block;
  font-size: 20px;
  color: #4A4141;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.slider-video a i {
  line-height: 60px;
  color: #4A4141;
}

.slider-content-5 span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.slider-content-5 h1 {
  color: #fff;
  font-size: 140px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  line-height: 150px;
  margin: 2px 0 37px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-5 h1 {
    font-size: 90px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-5 h1 {
    font-size: 90px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-5 h1 {
    font-size: 70px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-5 h1 {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-5 h1 {
    font-size: 36px;
    line-height: 45px;
    margin: 2px 0 25px;
  }
}

.slider-content-6 h1 {
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  margin: 0 0 68px;
  line-height: 85px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-6 h1 {
    font-size: 85px;
    line-height: 76px;
    margin: 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-6 h1 {
    font-size: 85px;
    line-height: 76px;
    margin: 0 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-6 h1 {
    font-size: 70px;
    line-height: 64px;
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-6 h1 {
    font-size: 60px;
    line-height: 55px;
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-6 h1 {
    font-size: 44px;
    line-height: 45px;
    margin: 0 0 32px;
  }
}

.slider-content-mrg-3 {
  margin: 80px 0px 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-3 {
    margin: 44px 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-3 {
    margin: 35px 0px 0 0px;
  }
}

.slider-content-7 h3 {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 h3 {
    font-size: 30px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 h3 {
    font-size: 30px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 h3 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 h3 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-7 h3 {
    font-size: 20px;
    line-height: 20px;
  }
}

.slider-content-7 h1 {
  font-size: 144px;
  font-weight: 700;
  line-height: 114px;
  margin: 44px 0 41px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 h1 {
    font-size: 120px;
    line-height: 100px;
    margin: 34px 0 31px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 h1 {
    font-size: 120px;
    line-height: 100px;
    margin: 34px 0 31px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 h1 {
    font-size: 100px;
    line-height: 80px;
    margin: 30px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 h1 {
    font-size: 80px;
    line-height: 60px;
    margin: 30px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-7 h1 {
    font-size: 50px;
    line-height: 40px;
    margin: 25px 0 20px;
  }
}

.slider-content-7 h1.slider-red-color {
  color: #b04b63;
}

.slider-btn-2 a {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  line-height: 25px;
  color: #4a4141;
  margin: 0 0 0 23px;
}

.slider-btn-2 a::before {
  content: '';
  border-top: 3px solid #4A4141;
  width: 90px;
  position: absolute;
  left: -23px;
  bottom: 24px;
  transition: all 250ms ease-out;
}

.slider-content-mrg-4 {
  margin: 91px 0 0 -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg-4 {
    margin: 91px 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg-4 {
    margin: 91px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-4 {
    margin: 55px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-4 {
    margin: 50px 0 0 0px;
  }
}

.slider-content-mrg-5 {
  margin-top: 91px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-5 {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-5 {
    margin-top: 50px;
  }
}

.slider-content-8 h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 45px;
  color: #fff;
  text-shadow: rgba(21, 10, 63, 0.5) 4px 3px 0px;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-8 h3 {
    font-size: 36px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-8 h3 {
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-8 h3 {
    font-size: 25px;
    line-height: 30px;
  }
}

.slider-content-8 h1 {
  font-size: 144px;
  font-weight: 700;
  color: #fff;
  text-shadow: rgba(21, 10, 63, 0.5) 12px 9px 0px;
  margin: 20px 0 61px;
  line-height: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-8 h1 {
    font-size: 100px;
    line-height: 95px;
    margin: 15px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-8 h1 {
    font-size: 53px;
    line-height: 60px;
    margin: 5px 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-8 h1 {
    font-size: 60px;
  }
}

.slider-content-9 h1 {
  color: #4a4141;
  font-size: 72px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 57px;
  margin: 0 0 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 h1 {
    font-size: 57px;
    margin: 0 0 24px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9 h1 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-9 h1 {
    font-size: 40px;
  }
}

.slider-content-9 h1 span {
  font-size: 36px;
  line-height: 32px;
  display: inline-block;
  margin: 0 20px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 h1 span {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9 h1 span {
    margin: 0 5px 0 0;
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-9 h1 span {
    margin: 0 10px 0 0;
    font-size: 30px;
  }
}

.slider-active-2.slick-initialized .slick-list .single-slider-2 {
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  opacity: .5;
}

.slider-active-2.slick-initialized .slick-list .single-slider-2 .slider-content-10 {
  opacity: 0;
  visibility: hidden;
}

.slider-active-2.slick-initialized .slick-list .single-slider-2.slick-center {
  opacity: 1;
}

.slider-active-2.slick-initialized .slick-list .single-slider-2.slick-center .slider-content-10 {
  opacity: 1;
  visibility: visible;
}

.slider-active-2.slick-initialized .slick-slide {
  display: flex !important;
}

.slider-content-10 {
  margin: 0 0 85px 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-10 {
    margin: 0 0 85px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-10 {
    margin: 0 0 70px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-10 {
    margin: 0 0 70px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-10 {
    margin: 0 0 50px 15px;
  }
}

.slider-content-10 span {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  display: block;
}

.slider-content-10 h1 {
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin: 3px 0 37px;
  line-height: 56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-10 h1 {
    font-size: 45px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-10 h1 {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-10 h1 {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-10 h1 {
    font-size: 25px;
    line-height: 30px;
    margin: 3px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-10 h1 {
    font-size: 32px;
    line-height: 35px;
    margin: 3px 0 20px;
  }
}

.slider-content-10 .slider-btn-3 a {
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 2px solid #fff;
  padding: 0 0 2px;
  line-height: 1;
}

span.slider-icon {
  color: #fff;
  font-size: 50px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}

span.slider-icon:hover {
  color: #a38b5e;
}

span.slider-icon i {
  line-height: 1;
  display: inline-block;
}

span.slider-prev {
  left: 2.3%;
}

span.slider-next {
  left: auto;
  right: 2.3%;
}

.single-slider-2.slick-active .slider-animated-1 span, .single-slider-3.slick-active .slider-animated-1 span {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-slider-2.slick-active .slider-animated-1 h1, .single-slider-3.slick-active .slider-animated-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInUp;
}

.single-slider-2.slick-active .slider-animated-1 a, .single-slider-3.slick-active .slider-animated-1 a {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.slider-content-11 span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  line-height: 1;
}

.slider-content-11 h1 {
  color: #fff;
  font-size: 144px;
  font-weight: 700;
  display: block;
  line-height: 155px;
  margin: 2px 0 37px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-11 h1 {
    font-size: 100px;
    line-height: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-11 h1 {
    font-size: 100px;
    line-height: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-11 h1 {
    font-size: 90px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-11 h1 {
    font-size: 80px;
    line-height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-11 h1 {
    font-size: 45px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-11 h1 {
    font-size: 55px;
    line-height: 70px;
  }
}

.slider-content-12 span {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  display: block;
  line-height: 1;
  letter-spacing: 7px;
}

@media only screen and (max-width: 767px) {
  .slider-content-12 span {
    letter-spacing: 5px;
    font-size: 20px;
  }
}

.slider-content-12 h1 {
  color: #fff;
  font-size: 144px;
  font-weight: 700;
  display: block;
  line-height: 110px;
  margin: 18px 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-12 h1 {
    font-size: 100px;
    line-height: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-12 h1 {
    font-size: 100px;
    line-height: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-12 h1 {
    font-size: 90px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-12 h1 {
    font-size: 80px;
    line-height: 65px;
    margin: 18px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-12 h1 {
    font-size: 40px;
    line-height: 33px;
    margin: 15px 0 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-12 h1 {
    font-size: 58px;
    line-height: 50px;
    margin: 15px 0 35px;
  }
}

.slider-content-13 span {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  display: block;
  line-height: 1;
  letter-spacing: 7px;
}

.slider-content-13 h1 {
  color: #fff;
  font-size: 144px;
  font-weight: 700;
  display: block;
  line-height: 110px;
  margin: 18px 0 50px;
  text-shadow: rgba(0, 0, 0, 0.5) 9px 2px 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-13 h1 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-13 h1 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-13 h1 {
    font-size: 90px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-13 h1 {
    font-size: 80px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-13 h1 {
    font-size: 50px;
    line-height: 40px;
    margin: 10px 0 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-13 h1 {
    font-size: 60px;
    line-height: 48px;
    margin: 10px 0 40px;
  }
}

.slider-content-14 {
  margin-left: 95px;
}

@media only screen and (max-width: 767px) {
  .slider-content-14 {
    margin-left: 15px;
  }
}

.slider-content-14 h1 {
  font-size: 104px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 40px;
  font-family: "Playfair Display", serif;
  line-height: 105px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-14 h1 {
    font-size: 95px;
    line-height: 95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-14 h1 {
    font-size: 95px;
    line-height: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-14 h1 {
    font-size: 80px;
    line-height: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-14 h1 {
    font-size: 50px;
    line-height: 55px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-14 h1 {
    font-size: 60px;
    line-height: 70px;
  }
}

.slider-content-14 h1 span {
  color: #4A4141;
  letter-spacing: 2px;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  bottom: 33px;
  left: 37px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-14 h1 span {
    bottom: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-14 h1 span {
    bottom: 15px;
    left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-14 h1 span {
    bottom: 20px;
    left: 15px;
  }
}

.slider-content-15 h1 {
  font-size: 104px;
  line-height: 92px;
  font-weight: 700;
  color: #fff;
  letter-spacing: -4px;
  font-family: "Playfair Display", serif;
  margin: 0 0 43px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-15 h1 {
    font-size: 90px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-15 h1 {
    font-size: 90px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-15 h1 {
    font-size: 73px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-15 h1 {
    font-size: 65px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-15 h1 {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0px;
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-15 h1 {
    font-size: 40px;
    line-height: 45px;
  }
}

.slider-content-mrg-6 {
  margin-left: 520px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-mrg-6 {
    margin-left: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-mrg-6 {
    margin-left: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-mrg-6 {
    margin-left: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-mrg-6 {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-mrg-6 {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-mrg-6 {
    margin-left: 35px;
  }
}

.slider-content-16 > span {
  font-weight: 700;
  font-size: 10px;
  color: #fff;
  display: block;
  line-height: 1;
}

.slider-content-16 h1 {
  font-weight: 300;
  font-size: 104px;
  color: #fff;
  margin: 35px 0 35px;
  line-height: 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-16 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-16 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-16 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-16 h1 {
    font-size: 50px;
    margin: 18px 0 25px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-16 h1 {
    font-size: 30px;
    margin: 18px 0 20px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-16 h1 {
    font-size: 50px;
    margin: 18px 0 25px;
    line-height: 35px;
  }
}

.slider-content-16 h1 span {
  letter-spacing: 8px;
  font-size: 77px;
  font-weight: 300;
  color: #a38b5e;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-16 h1 span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-16 h1 span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-16 h1 span {
    font-size: 40px;
  }
}

.slider-content-17 > span {
  font-weight: 700;
  font-size: 10px;
  color: #fff;
  display: block;
  line-height: 1;
}

.slider-content-17 h1 {
  font-weight: 700;
  font-size: 104px;
  color: #fff;
  margin: 19px 0 34px;
  line-height: 95px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-17 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-17 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-17 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-17 h1 {
    font-size: 40px;
    margin: 10px 0 20px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-17 h1 {
    font-size: 27px;
    margin: 10px 0 12px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-17 h1 {
    font-size: 40px;
    margin: 10px 0 20px;
    line-height: 45px;
  }
}

.slider-btn-4 a {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  color: #fff;
  padding: 0 0 0 45px;
}

.slider-btn-4 a:hover {
  color: #a38b5e;
}

.slider-btn-4 a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 13px;
  background-color: #fff;
  height: 1px;
  width: 30px;
}

.slider-btn-4 a:hover:before {
  background-color: #a38b5e;
}

.slider-active-3.slick-initialized .slick-slide {
  display: flex !important;
}

.slider-active-3 .slick-dots {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active-3 .slick-dots {
    right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-active-3 .slick-dots {
    right: 15px;
  }
}

.slider-active-3 .slick-dots li {
  display: inline-block;
  line-height: 1;
}

.slider-active-3 .slick-dots li button {
  border: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  display: inline-block;
  height: 14px;
  margin: 0 8px;
  width: 14px;
  position: relative;
  text-indent: -99999px;
}

.slider-active-3 .slick-dots li button::before {
  position: absolute;
  content: "";
  left: -3px;
  right: -3px;
  top: -3px;
  bottom: -3px;
  border: 1px solid transparent;
  border-radius: 100%;
}

.slider-active-3 .slick-dots li.slick-active button {
  background-color: white;
}

.slider-active-3 .slick-dots li.slick-active button::before {
  border: 1px solid #fff;
}

@media only screen and (max-width: 767px) {
  .slider-hm1-1 {
    background-position: 16%;
  }
  .slider-hm1-2 {
    background-position: 77%;
  }
}

/*-------- 4. Banner style ---------*/
.banner-hover {
  position: relative;
  overflow: hidden;
}

.banner-hover::before {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 1;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
}

.banner-wrap .banner-img::after {
  background-color: transparent;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: all .35s;
  pointer-events: none;
}

.banner-wrap .banner-img-2 a {
  display: block;
}

.banner-wrap .banner-img-2 a img {
  width: 100%;
}

.banner-wrap .banner-zoom a img {
  transform: scale(1.05);
  transition: all .35s ease 0s;
}

.banner-wrap .banner-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content {
    padding: 0 10px;
  }
}

.banner-wrap .banner-content h3 {
  font-size: 36px;
  margin: 0;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content h3 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content h3 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content h3 a {
  color: #fff;
}

.banner-wrap .banner-content-2 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -30px;
  z-index: 99;
  opacity: 0;
  transition: all .45s ease-out;
}

.banner-wrap .banner-content-2 a {
  position: relative;
  display: inline-block;
  color: #4A4141;
  background-color: #fff;
  font-weight: 600;
  padding: 20px 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 a {
    padding: 11px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 a {
    padding: 10px 25px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 {
    left: 15px;
  }
}

.banner-wrap .banner-content-3 span {
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 span {
    font-size: 15px;
  }
}

.banner-wrap .banner-content-3 h2 {
  font-size: 72px;
  font-weight: 600;
  color: #fff;
  margin: 20px 0 35px;
  line-height: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 50px;
    margin: 5px 0 15px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 50px;
    margin: 5px 0 15px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 50px;
    margin: 10px 0 15px;
    line-height: 50px;
  }
}

.banner-wrap .banner-content-3 h2 a {
  color: #fff;
}

.banner-wrap .banner-content-3 .banner-btn a {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #fff;
  color: #fff;
  line-height: 1;
  padding: 18px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 .banner-btn a {
    padding: 15px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 .banner-btn a {
    padding: 15px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 .banner-btn a {
    padding: 12px 30px;
  }
}

.banner-wrap .banner-content-5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

.banner-wrap .banner-content-5 span {
  font-size: 18px;
  color: #fff;
}

.banner-wrap .banner-content-5 h2 {
  font-size: 75px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0 19px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-5 h2 a {
  color: #fff;
}

.banner-wrap .banner-content-5 .banner-btn-3 a {
  display: inline-block;
  font-size: 12px;
  padding: 15px 45px;
  color: #262626;
  font-weight: 700;
  background-color: #fff;
  letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 .banner-btn-3 a {
    padding: 10px 30px;
  }
}

.banner-wrap .banner-content-6 {
  position: absolute;
  bottom: 32px;
  left: 45px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    left: 15px;
  }
}

.banner-wrap .banner-content-6 span {
  font-size: 18px;
  color: #fff;
}

.banner-wrap .banner-content-6 h2 {
  font-size: 75px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-6 h2 a {
  color: #fff;
}

.banner-wrap .banner-content-7 {
  position: absolute;
  bottom: 173px;
  left: 125px;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-7 {
    left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 {
    left: 50px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 {
    left: 20px;
    bottom: 20px;
  }
}

.banner-wrap .banner-content-7 span {
  font-size: 18px;
  color: #fff;
}

.banner-wrap .banner-content-7 h2 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  margin: 0px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-7 h2 a {
  color: #fff;
}

.banner-wrap .banner-content-8 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 {
    padding: 0 10px;
  }
}

.banner-wrap .banner-content-8 h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  line-height: 1;
}

.banner-wrap .banner-content-8 h3 a {
  color: #fff;
}

.banner-wrap .banner-content-9 {
  position: absolute;
  left: 22px;
  bottom: 35px;
  width: 100%;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 {
    left: 15px;
    bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 {
    padding: 0 10px;
  }
}

.banner-wrap .banner-content-9 span {
  font-size: 18px;
  font-weight: 600;
  display: block;
  line-height: 1;
  color: #fff;
}

.banner-wrap .banner-content-9 h3 {
  color: #fff;
  font-size: 64px;
  font-weight: 600;
  margin: 8px 0 0;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 35px;
  }
}

.banner-wrap .banner-content-9 h3 a {
  color: #fff;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 84px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-1 {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-1 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    left: 15px;
  }
}

.banner-wrap:hover .banner-img::after {
  background-color: rgba(0, 0, 0, 0.2);
}

.banner-wrap:hover .banner-hover::before {
  animation: effect_shine 1s;
}

.banner-wrap:hover .banner-zoom a img {
  transform: scale(1.01);
}

.banner-wrap:hover .banner-content-2 {
  bottom: 30px;
  opacity: 1;
}

.banner-wrap:hover .banner-btn a {
  background-color: #a38b5e;
  border-color: #a38b5e;
}

@-webkit-keyframes effect_shine {
  100% {
    left: 150%;
  }
}

@keyframes effect_shine {
  100% {
    left: 150%;
  }
}

.banner-slider-active .owl-nav div {
  color: #858a9f;
  font-size: 30px;
  left: -43px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-slider-active .owl-nav div {
    left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-slider-active .owl-nav div {
    left: -18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-active .owl-nav div {
    left: -18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-active .owl-nav div {
    left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-slider-active .owl-nav div {
    left: -15px;
  }
}

.banner-slider-active .owl-nav div.owl-next {
  left: auto;
  right: -43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-slider-active .owl-nav div.owl-next {
    right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-slider-active .owl-nav div.owl-next {
    right: -18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-slider-active .owl-nav div.owl-next {
    right: -18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-slider-active .owl-nav div.owl-next {
    right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-slider-active .owl-nav div.owl-next {
    right: -15px;
  }
}

.product-parallax-img {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 250px 0 230px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-parallax-img {
    padding: 150px 0 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-parallax-img {
    padding: 100px 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-parallax-img {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .product-parallax-img {
    padding: 60px 0 60px;
  }
}

.banner-content-4 h3 {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .banner-content-4 h3 {
    font-size: 25px;
  }
}

.banner-content-4 span {
  color: #4a4141;
  font-size: 20px;
  margin: 14px 0 50px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .banner-content-4 span {
    margin: 14px 0 30px;
  }
}

.banner-content-4 .banner-btn-2 a {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  line-height: 1.2;
  color: #262626;
  margin: 0 0 0 23px;
}

.banner-content-4 .banner-btn-2 a::before {
  content: '';
  border-top: 2px solid #262626;
  width: 96px;
  position: absolute;
  left: -23px;
  bottom: 1em;
  transition: all 250ms ease-out;
}

.banner-content-4 .banner-btn-2 a:hover {
  color: #a38b5e;
}

.banner-content-4 .banner-btn-2 a:hover::before {
  border-top: 2px solid #a38b5e;
  bottom: 0;
}

.banner-content-4.banner-negative-mrg {
  margin-left: -15px;
}

@media only screen and (max-width: 767px) {
  .banner-content-4.banner-negative-mrg {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.mt-110 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-65 {
    padding-bottom: 15px;
  }
  .banner-area.mt-110 {
    margin-top: 0;
  }
}

/*-------- 2. Header style ---------*/
.product-wrap {
  position: relative;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.product-wrap .product-img > a img {
  width: 100%;
}

.product-wrap .product-img > a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}

.product-wrap .product-img > a > span {
  display: inline-block;
  color: #fff;
  top: 20px;
  width: 90px;
  height: 31px;
  line-height: 31px;
  border-radius: 50px;
  text-align: center;
  position: absolute;
  font-size: 12px;
}

.product-wrap .product-img > a > span.badge-right {
  right: 20px;
}

.product-wrap .product-img > a > span.badge-left {
  left: 20px;
}

.product-wrap .product-img > a > span.badge-black {
  background-color: #1c1c1c;
}

.product-wrap .product-img > a > span.badge-pink {
  background-color: #d64343;
}

.product-wrap .product-img > a > span.badge-green {
  background-color: #BBC15F;
}

.product-wrap .product-img .product-action {
  display: inline-block;
  position: absolute;
  right: 30px;
  bottom: 15px;
  z-index: 99;
}

.product-wrap .product-img .product-action.product-action-mrg {
  bottom: 53px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.product-action-mrg {
    bottom: 20px;
  }
  .product-wrap .product-img .product-action.product-action-mrg a {
    margin-bottom: 5px;
  }
}

.product-wrap .product-img .product-action.product-action-mrg-2 {
  bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.product-action-mrg-2 {
    bottom: 15px;
  }
  .product-wrap .product-img .product-action.product-action-mrg-2 a {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.product-action-mrg-2 {
    bottom: 12px;
  }
  .product-wrap .product-img .product-action.product-action-mrg-2 a {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-img .product-action.product-action-mrg-2 {
    bottom: 38px;
  }
  .product-wrap .product-img .product-action.product-action-mrg-2 a {
    margin-bottom: 10px;
  }
}

.product-wrap .product-img .product-action a {
  background-color: white;
  border-radius: 50px;
  color: #262626;
  display: block;
  font-size: 18px;
  height: 44px;
  left: 30px;
  margin-bottom: 10px;
  opacity: 0;
  position: relative;
  text-align: center;
  visibility: hidden;
  width: 44px;
}

.product-wrap .product-img .product-action a i {
  line-height: 44px;
}

.product-wrap .product-img .product-action a:nth-child(1) {
  transition-delay: 100ms;
}

.product-wrap .product-img .product-action a:nth-child(2) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action a:nth-child(3) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action a:nth-child(4) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action a span {
  visibility: hidden;
  position: absolute;
  background-color: #000;
  text-align: center;
  padding: 5px 10px 7px;
  border-radius: 5px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  top: -43px;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action a span::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action a:hover span {
  visibility: visible;
  opacity: 1;
}

.product-wrap .product-img .product-action a:hover {
  background-color: #a38b5e;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action.product-matro a {
    width: 36px;
    height: 36px;
    font-size: 15px;
  }
  .product-wrap .product-img .product-action.product-matro a i {
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action.product-matro {
    bottom: 5px;
  }
  .product-wrap .product-img .product-action.product-matro a {
    width: 34px;
    height: 34px;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .product-wrap .product-img .product-action.product-matro a i {
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action.product-matro {
    bottom: 3px;
  }
  .product-wrap .product-img .product-action.product-matro a {
    width: 32px;
    height: 32px;
    font-size: 14px;
    margin-bottom: 3px;
  }
  .product-wrap .product-img .product-action.product-matro a i {
    line-height: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-img .product-action.product-matro {
    bottom: 15px;
  }
  .product-wrap .product-img .product-action.product-matro a {
    width: 36px;
    height: 36px;
    font-size: 15px;
    margin-bottom: 7px;
  }
  .product-wrap .product-img .product-action.product-matro a i {
    line-height: 36px;
  }
}

.product-wrap .product-img .product-action-2 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 100px;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action-2 {
    bottom: 80px;
  }
}

.product-wrap .product-img .product-action-2 a {
  background-color: white;
  border-radius: 50px;
  color: #262626;
  display: inline-block;
  font-size: 18px;
  height: 44px;
  width: 44px;
  margin: 0 3px;
  position: relative;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 30px);
  transition: all 250ms ease-out;
}

.product-wrap .product-img .product-action-2 a i {
  line-height: 44px;
}

.product-wrap .product-img .product-action-2 a:nth-child(1) {
  transition-delay: 100ms;
}

.product-wrap .product-img .product-action-2 a:nth-child(2) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action-2 a:nth-child(3) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action-2 a span {
  visibility: hidden;
  position: absolute;
  background-color: #000;
  text-align: center;
  padding: 5px 10px 7px;
  border-radius: 5px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  top: -43px;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action-2 a span::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.product-wrap .product-img .product-action-2 a:hover span {
  visibility: visible;
  opacity: 1;
}

.product-wrap .product-img .product-action-2 a:hover {
  background-color: #a38b5e;
  color: #fff;
}

.product-wrap .product-img .product-action-3 {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  transform: translate(0, 40px);
  text-align: center;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease-out;
}

.product-wrap .product-img .product-action-3 a {
  display: block;
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 30px 20px 29px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-3 a {
    padding: 15px 20px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action-3 a {
    padding: 15px 20px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action-3 a {
    padding: 15px 20px 14px;
  }
}

.product-wrap .product-img .product-action-3 a:hover {
  background-color: #a38b5e;
}

.product-wrap .product-action-4 {
  flex: 0 0 45%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-action-4 {
    flex: 0 0 50%;
  }
}

.product-wrap .product-action-4 a {
  background-color: white;
  border-radius: 50px;
  color: #262626;
  display: inline-block;
  font-size: 18px;
  height: 44px;
  width: 44px;
  margin: 0 0 5px 10px;
  position: relative;
  text-align: center;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-action-4 a {
    font-size: 16px;
    height: 36px;
    width: 36px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-action-4 a {
    font-size: 16px;
    height: 34px;
    width: 34px;
    margin: 0 0 5px 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-action-4 a {
    font-size: 14px;
    height: 30px;
    width: 30px;
    margin: 0 0 5px 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-action-4 a {
    font-size: 16px;
    height: 34px;
    width: 34px;
    margin: 0 0 5px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-action-4 a {
    font-size: 16px;
    height: 40px;
    width: 40px;
    margin: 0 0 8px 8px;
  }
}

.product-wrap .product-action-4 a i {
  line-height: 44px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-action-4 a i {
    line-height: 36px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-action-4 a i {
    line-height: 34px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-action-4 a i {
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-action-4 a i {
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-action-4 a i {
    line-height: 40px;
  }
}

.product-wrap .product-action-4 a span {
  visibility: hidden;
  position: absolute;
  background-color: #000;
  text-align: center;
  padding: 5px 10px 7px;
  border-radius: 5px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  top: -43px;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%);
}

.product-wrap .product-action-4 a span::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.product-wrap .product-action-4 a:hover span {
  visibility: visible;
  opacity: 1;
}

.product-wrap .product-action-4 a:hover {
  background-color: #a38b5e;
  color: #fff;
}

.product-wrap .product-content {
  position: relative;
  z-index: 2;
}

.product-wrap .product-content h3 {
  font-size: 16px;
  margin: 0 0 8px;
}

.product-wrap .product-content h3.title-blod {
  font-weight: 700;
}

.product-wrap .product-content .product-price span {
  font-size: 16px;
  color: #989898;
  margin: 0 2px;
}

.product-wrap .product-content .product-price span.old-price {
  text-decoration: line-through;
}

.product-wrap .product-content .product-price span.new-price {
  color: #a38b5e;
}

.product-wrap .product-content .product-price.price-color-2 span {
  color: #000;
}

.product-wrap .product-content .product-price.price-color-2 span.new-price {
  color: #a38b5e;
}

.product-wrap .product-content-2 {
  position: absolute;
  left: 107px;
  bottom: 70px;
  z-index: 99;
  transform: translate(0, 80px);
  visibility: hidden;
  opacity: 0;
  max-width: 80%;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-content-2 {
    left: 30px;
    max-width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-content-2 {
    left: 30px;
    max-width: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-2 {
    left: 15px;
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content-2 {
    left: 15px;
    max-width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 {
    left: 15px;
    max-width: 60%;
    bottom: 20px;
  }
}

.product-wrap .product-content-2 h3 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 h3 {
    font-size: 16px;
  }
}

.product-wrap .product-content-2 h3 a {
  color: #fff;
}

.product-wrap .product-content-2 .product-price-2 span {
  font-size: 20px;
  color: #fff;
  margin: 0 2px;
}

.product-wrap .product-content-2 .product-price-2 span.old-price {
  text-decoration: line-through;
}

.product-wrap .product-content-2 .product-price-2 span.new-price {
  color: #a38b5e;
}

.product-wrap .product-content-2.product-content-2-left {
  left: 30px;
  bottom: 45px;
  max-width: 68%;
}

.product-wrap .product-content-3 {
  flex: 0 0 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-3 {
    flex: 0 0 50%;
  }
}

.product-wrap .product-content-3 h3 {
  margin: 0 0 7px;
  font-size: 16px;
  font-weight: 700;
}

.product-wrap .product-content-3 h3 a {
  color: #fff;
}

.product-wrap .product-content-3 h3 a:hover {
  text-decoration: underline;
}

.product-wrap .product-content-3 .product-price-3 span {
  font-size: 16px;
  color: #fff;
  margin: 0 2px;
}

.product-wrap .product-content-3 .product-price-3 span.old-price {
  text-decoration: line-through;
}

.product-wrap .product-content-3 .product-price-3 span.new-price {
  color: #a38b5e;
}

.product-wrap .product-content-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(18, 18, 18, 0.4);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 43px 27px 43px 30px;
  z-index: 99;
  transition: all .4s ease;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-content-wrap {
    padding: 43px 17px 43px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-content-wrap {
    padding: 43px 15px 43px 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-wrap {
    padding: 43px 15px 43px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content-wrap {
    padding: 43px 15px 43px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-wrap {
    padding: 43px 15px 43px 15px;
  }
}

.product-wrap .product-wrap-padding {
  padding: 10px 10px 30px;
  position: relative;
}

.product-wrap .product-wrap-padding::before {
  content: '';
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  transition: all 250ms ease-out;
}

.product-wrap:hover {
  z-index: 3;
}

.product-wrap:hover .product-img a img.hover-img {
  opacity: 1;
}

.product-wrap:hover .product-action a {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-2 a {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0px);
}

.product-wrap:hover .product-wrap-padding::before {
  box-shadow: 0 9px 30px 0 rgba(184, 184, 184, 0.3);
  background-color: #fff;
}

.product-wrap:hover .product-action-3 {
  transform: translate(0px, 0);
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .overlay-1::before {
  opacity: .6;
  background-color: #0D0D0D;
  pointer-events: none;
}

.product-wrap:hover .product-content-2 {
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
}

.product-wrap:hover .product-content-wrap {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-40 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
  .product-wrap.product-res-height .product-img a img {
    height: 260px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap.product-res-height .product-img a img {
    height: auto;
  }
}

.tooltip > .tooltip-inner {
  background-color: #000;
  padding: 6px 10px 8px;
  border-radius: 5px;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #000;
}

.dot-style-1 .owl-dots {
  text-align: center;
  margin-top: 25px;
}

.dot-style-1 .owl-dots .owl-dot {
  background-color: rgba(138, 138, 138, 0.4);
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  transition: all .3s ease 0s;
}

.dot-style-1 .owl-dots .owl-dot.active, .dot-style-1 .owl-dots .owl-dot:hover {
  background-color: #a38b5e;
}

.dot-style-1.dot-black .owl-dots .owl-dot {
  background-color: rgba(51, 51, 51, 0.4);
}

.dot-style-1.dot-black .owl-dots .owl-dot.active, .dot-style-1.dot-black .owl-dots .owl-dot:hover {
  background-color: #333;
}

.dot-style-1.dot-mrg .owl-dots {
  margin-top: 40px;
}

.modal-backdrop {
  background-color: #333;
}

.modal-backdrop.show {
  opacity: .9;
}

.modal-dialog {
  margin: 9.5% auto 8%;
  max-width: 1384px;
  width: 1384px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .modal-dialog {
    width: 1250px;
    max-width: 1250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog {
    width: 1100px;
    max-width: 1100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 920px;
    max-width: 920px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -60px;
  top: 0px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .modal-dialog .modal-content .modal-header {
    right: -40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog .modal-content .modal-header {
    right: -32px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog .modal-content .modal-header {
    right: -32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -10px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  line-height: 65px;
  background-color: #292929;
  font-size: 24px;
  text-shadow: none;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.modal-dialog .modal-content .modal-header .close span {
  transition: all .3s ease-in-out;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 40px;
    height: 40px;
    line-height: 44px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover span {
  transform: rotate(90deg);
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #a38b5e;
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
  overflow-y: auto;
  max-height: 495px;
  height: 495px;
}

.product-details-content {
  padding: 50px 40px 5px 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content {
    padding: 30px 40px 5px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content {
    padding: 30px 40px 5px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    padding: 20px 15px 5px 15px;
  }
}

.product-details-content h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.product-details-content h3 {
  font-size: 36px;
  color: #262626;
  margin: 27px 0 27px;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h3 {
    font-size: 30px;
    margin: 10px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h3 {
    font-size: 30px;
    margin: 10px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h3 {
    font-size: 25px;
    margin: 10px 0 12px;
  }
}

.product-details-content .product-dec-peragraph p {
  color: #333;
  margin: 0;
}

.product-details-content.product-details-ptb {
  padding: 0 0 0 43px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.product-details-ptb {
    padding: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.product-details-ptb {
    padding: 40px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.product-details-ptb {
    padding: 30px 0 0 0px;
  }
}

.product-details-content.product-details-ptb h3 {
  margin: 46px 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.product-details-ptb h3 {
    margin: 15px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.product-details-ptb h3 {
    margin: 15px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.product-details-ptb h3 {
    margin: 15px 0 15px;
  }
}

.product-dec-action-wrap {
  display: flex;
  align-items: center;
  margin: 30px 0 12px;
}

.product-dec-action-wrap .pro-dec-wishlist-compare {
  margin-left: 20px;
}

.product-dec-action-wrap .pro-dec-wishlist-compare a {
  font-size: 16px;
  color: #262626;
  margin-right: 20px;
}

.product-dec-action-wrap .pro-dec-wishlist-compare a:hover {
  color: #a38b5e;
}

.product-dec-action-wrap.pro-dec-action-2 {
  margin: 46px 0 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-action-wrap.pro-dec-action-2 {
    margin: 20px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-action-wrap.pro-dec-action-2 {
    margin: 20px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-action-wrap.pro-dec-action-2 {
    margin: 20px 0 12px;
  }
}

.quality-cart-wrap {
  display: flex;
  align-items: center;
  background-color: #4A4141;
  transition: all .3s ease 0s;
  min-height: 70px;
}

.quality-cart-wrap:hover {
  background-color: #a38b5e;
}

.quality-cart-wrap .quality-wrap {
  position: relative;
}

.quality-cart-wrap .quality-wrap::after {
  content: '';
  border-right: 1px solid;
  opacity: .3;
  position: absolute;
  height: 40px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  color: #fff;
}

.quality-cart-wrap .quality-wrap input {
  border: none;
  height: 70px;
  width: 80px;
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .quality-cart-wrap .quality-wrap input {
    width: 50px;
  }
}

.quality-cart-wrap .pro-cart-wrap a {
  padding: 20px 60px;
  font-weight: 600;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quality-cart-wrap .pro-cart-wrap a {
    padding: 20px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .quality-cart-wrap .pro-cart-wrap a {
    padding: 20px 20px;
  }
}

.store-delivery-wrap a {
  font-size: 12px;
  margin-right: 20px;
}

.store-delivery-wrap a:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .store-delivery-wrap a {
    margin-right: 12px;
  }
}

.store-delivery-wrap a i {
  font-size: 16px;
  padding-right: 5px;
}

.product-dec-meta {
  margin: 15px 0 30px;
}

.product-dec-meta span {
  display: block;
  font-size: 12px;
  color: #989898;
}

.product-dec-meta span a {
  color: #4a4141;
}

.product-dec-meta span a:hover {
  text-decoration: underline;
}

.product-social span {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin: 0 0 13px;
}

.product-social ul li {
  display: inline-block;
  margin-right: 17px;
}

.product-social ul li:last-child {
  margin-right: 0;
}

.product-social ul li a {
  font-size: 14px;
}

.quickview-slider-active.owl-carousel > .owl-nav div {
  color: #858a9f;
  font-size: 18px;
  left: 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

.quickview-slider-active.owl-carousel > .owl-nav div.owl-next {
  right: 15px;
  left: auto;
}

.quickview-slider-active.owl-carousel > .owl-nav div:hover {
  color: #a38b5e;
}

/*------  home 2 ----------*/
.new-collection-img a {
  display: block;
  overflow: hidden;
}

.new-collection-img a img {
  transform: scale(1.05);
  transition: transform 0.5s ease-out;
  width: 100%;
}

.new-collection-img a:hover:before {
  opacity: .2;
}

.new-collection-img a:hover img {
  transform: scale(1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .new-collection-img.mr-100 {
    margin-right: 0;
  }
  .new-collection-img.ml-100 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .new-collection-img.mr-100 {
    margin-right: 0;
  }
  .new-collection-img.ml-100 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .new-collection-img.mr-100 {
    margin-right: 0;
  }
  .new-collection-img.ml-100 {
    margin-left: 0;
  }
}

.new-collection-content span {
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.new-collection-content h2 {
  font-size: 64px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  line-height: 1;
  margin: 4px 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .new-collection-content h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .new-collection-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .new-collection-content h2 {
    font-size: 32px;
    margin: 4px 0 10px;
  }
}

.new-collection-content p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Playfair Display", serif;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .new-collection-content p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .new-collection-content p {
    font-size: 15px;
  }
}

.new-collection-content .new-collection-btn a {
  font-size: 16px;
  display: inline-block;
  color: #a38b5e;
  text-decoration: underline;
  font-weight: 600;
}

.new-collection-content .new-collection-btn a:hover {
  color: #262626;
  text-decoration: inherit;
}

@media only screen and (max-width: 767px) {
  .new-collection-content .new-collection-btn.pt-80 {
    padding-top: 20px;
  }
}

.product-tab-list {
  display: flex;
  justify-content: center;
}

.product-tab-list a {
  margin: 0 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list a {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list a {
    margin: 0 10px 10px;
  }
}

.product-tab-list a h3 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  color: #cdcdcd;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list a h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list a h3 {
    font-size: 20px;
  }
}

.product-tab-list a.active h3 {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list.pb-55 {
    padding-bottom: 20px;
  }
}

.product-tab-list-2 {
  display: flex;
  justify-content: center;
}

.product-tab-list-2 a {
  margin: 0 29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list-2 a {
    margin: 0 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-2 a {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    margin: 0 5px 10px;
  }
}

.product-tab-list-2 a h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  color: #cdcdcd;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a h3 {
    font-size: 20px;
  }
}

.product-tab-list-2 a.active h3 {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2.pb-55 {
    padding-bottom: 20px;
  }
}

.product-tab-list-3 {
  display: flex;
  justify-content: center;
}

.product-tab-list-3 a {
  margin: 0 21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list-3 a {
    margin: 0 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-3 a {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0 5px 10px;
  }
}

.product-tab-list-3 a h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 2px;
  color: #cdcdcd;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a h3 {
    font-size: 20px;
  }
}

.product-tab-list-3 a.active h3 {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3.pb-55 {
    padding-bottom: 20px;
  }
}

.product-btn-list-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .product-btn-list-wrap {
    display: block;
  }
}

.product-btn-list-wrap .product-tab-width {
  flex: 0 0 92%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-btn-list-wrap .product-tab-width {
    flex: 0 0 85%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-btn-list-wrap .product-tab-width {
    flex: 0 0 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-btn-list-wrap .product-tab-width {
    flex: 0 0 70%;
  }
}

@media only screen and (max-width: 767px) {
  .product-btn-list-wrap .product-tab-width.pb-45 {
    padding-bottom: 6px;
  }
}

.product-btn-list-wrap .product-btn-width {
  flex: 0 0 8%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-btn-list-wrap .product-btn-width {
    flex: 0 0 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-btn-list-wrap .product-btn-width {
    flex: 0 0 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-btn-list-wrap .product-btn-width {
    flex: 0 0 30%;
  }
}

@media only screen and (max-width: 767px) {
  .product-btn-list-wrap .product-btn-width {
    padding-bottom: 20px;
  }
}

.all-product-btn {
  text-align: center;
}

.all-product-btn a {
  color: #262626;
  text-decoration: underline;
  display: inline-block;
}

.all-product-btn a:hover {
  text-decoration: none;
  color: #a38b5e;
}

@media only screen and (max-width: 767px) {
  .all-product-btn.mt-10 {
    margin-top: 0;
  }
  .all-product-btn.text-right {
    text-align: center !important;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: moveUp;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

/*------  home 5 ----------*/
.pro-categories-wrap {
  margin: 0px 0 44px;
}

.pro-categories-wrap .pro-categorie-title {
  margin: 0 0 15px;
}

.pro-categories-wrap .pro-categorie-title h3 {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
}

.pro-categories-wrap .pro-categorie-list ul li {
  margin-bottom: 10px;
}

.pro-categories-wrap .pro-categorie-list ul li:last-child {
  margin-bottom: 0;
}

.pro-categories-wrap .pro-categorie-list ul li a {
  color: #989898;
}

.grid-sizer {
  width: 25%;
}

.category-wrap {
  position: relative;
}

.category-wrap.category-overly {
  position: relative;
}

.category-wrap.category-overly:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s ease-out;
  background-color: #262626;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  pointer-events: none;
}

.category-wrap a img {
  width: 100%;
}

.category-wrap .category-position {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  transition: all 0.4s 0.1s;
}

@media only screen and (max-width: 767px) {
  .category-wrap .category-position {
    padding: 0 10px;
  }
}

.category-wrap .category-content h4 {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  margin: 20px 0 0;
  display: inline-block;
  transition: all 0.4s 0.1s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrap .category-content h4 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .category-wrap .category-content h4 {
    font-size: 20px;
  }
}

.category-wrap .category-content h4 a {
  color: #fff;
}

.category-wrap:hover .category-position {
  opacity: 1;
  visibility: visible;
}

.category-wrap:hover .category-content h4 {
  margin: 0px 0 0;
}

.category-wrap:hover.category-overly:before {
  transform: scale(1);
  opacity: .6;
  visibility: visible;
}

.toggle-item-active {
  display: none;
}

.shop-instagram-wrap {
  position: relative;
  overflow: hidden;
}

.shop-instagram-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(163, 139, 94, 0.8);
  transition: all .4s;
  pointer-events: none;
}

.shop-instagram-wrap a img {
  width: 100%;
}

.shop-instagram-wrap .shop-insta-content-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 42px 29px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 20px 29px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap {
    padding: 10px 15px 29px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
  max-width: 90%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content {
    max-width: 80%;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 600;
  font-size: 32px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .1s;
  margin: 0 0 8px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 {
    font-size: 20px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content h3 a {
  color: #fff;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-content p {
  transform: translateY(20px);
  opacity: 0;
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #fff;
  transition: all .4s ease-in-out .3s;
  margin: 0;
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup {
    right: 20px;
  }
}

.shop-instagram-wrap .shop-insta-content-wrap .shop-insta-popup a {
  color: #fff;
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 3px solid #fff;
  transform: translateX(20px);
  opacity: 0;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  transition: all .4s ease-in-out .4s;
  line-height: 42px;
}

.shop-instagram-wrap:hover::after {
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content h3 {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-content p {
  transform: translateY(0px);
  opacity: 1;
}

.shop-instagram-wrap:hover .shop-insta-popup a {
  transform: translateX(0px);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .product-area.pt-70 {
    padding-top: 20px;
  }
  .product-area.pt-60 {
    padding-top: 20px;
  }
  .product-area.pb-45 {
    padding-bottom: 20px;
  }
  .product-area.mt-150 {
    margin-top: 0;
  }
  .mrg-bottom-xs {
    margin-bottom: 30px;
  }
  .mrg-top-xs {
    margin-top: 30px;
  }
  .mrg-top-bottom-sm {
    margin-bottom: 0;
  }
  .product-all-wrap.pb-45 {
    padding-bottom: 10px;
  }
  .category-area.mt-110 {
    margin-top: 0;
  }
  .header-res-border {
    border-bottom: 1px solid #ddd;
  }
  .show-more-btn.mt-30 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-sticky .product-details-content {
    margin-top: 0;
  }
}

.sidebar-sticky.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-sticky.col-lg-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-sticky.col-lg-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mrg-top-none-sm {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-res-border {
    border-bottom: 1px solid #ddd;
  }
  .category-area.mt-110 {
    margin-top: 0;
  }
  .product-area.mt-150 {
    margin-top: 0;
  }
}

/*-------- 5. Section title style ---------*/
.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 12px;
  line-height: 29px;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 30px;
  }
}

.section-title p {
  color: #989898;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title.mb-45 {
    margin-bottom: 30px;
  }
}

.section-title-2 h2 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  font-family: "Playfair Display", serif;
  line-height: 38px;
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 35px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-40 {
    margin-bottom: 30px;
  }
}

.section-title-3 h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 27px;
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-50 {
    margin-bottom: 20px;
  }
}

.section-title-4 h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
  line-height: 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-4.mb-45 {
    margin-bottom: 20px;
  }
}

.sec-title-pro-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .sec-title-pro-btn-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-5 h2 {
  font-size: 72px;
  font-weight: 700;
  margin: 0 0 45px;
  line-height: 54px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-5 h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 h2 {
    font-size: 36px;
    margin: 0 0 15px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 25px;
    line-height: 25px;
    margin: 0 0 15px;
  }
}

.section-title-5 p {
  color: #989898;
  font-family: "Playfair Display", serif;
  margin: 0 auto;
  line-height: 1.714286;
  width: 66%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-5 p {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 p {
    width: 85%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-6 h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  line-height: 22px;
}

.section-title-6 p {
  color: #989898;
  margin: 12px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-50 {
    margin-bottom: 30px;
  }
  .section-title-6.mb-70 {
    margin-bottom: 30px;
  }
  .section-title-6.mb-65 {
    margin-bottom: 30px;
  }
  .section-title-6.mb-35 {
    margin-bottom: 20px;
  }
}

.section-title-7 h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  line-height: 32px;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-40 {
    margin-bottom: 20px;
  }
}

.section-title-8 h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 22px;
  }
}

.section-title-9 h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 29px;
  margin: 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-45 {
    margin-bottom: 20px;
  }
}

.section-title-paragraph p {
  font-family: "Playfair Display", serif;
  margin: 0;
  font-size: 18px;
  line-height: 1.714286;
  width: 41%;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-paragraph p {
    width: 65%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-paragraph p {
    width: 78%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-paragraph p {
    width: 78%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-paragraph p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-paragraph.mb-45 {
    margin-bottom: 20px;
  }
}

/*-------- 6. Subscribe style ---------*/
.subscribe-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 45px;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap h3 {
    font-size: 20px;
    margin: 0 0 15px;
  }
}

.subscribe-wrap .subscribe-form .mc-form {
  position: relative;
}

.subscribe-wrap .subscribe-form .mc-form > input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e7e7e7;
  color: #989898;
  padding: 5px 100px 5px 0;
  height: 43px;
  font-size: 16px;
}

.subscribe-wrap .subscribe-form .mc-form .mc-news {
  display: none;
}

.subscribe-wrap .subscribe-form .mc-form .clear {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}

.subscribe-wrap .subscribe-form .mc-form .clear input {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #262626;
  font-weight: 700;
  font-size: 16px;
  height: 43px;
}

.subscribe-wrap .subscribe-form .mc-form .clear input:hover {
  color: #a38b5e;
}

.subscribe-form-2 .mc-form-2 {
  position: relative;
}

.subscribe-form-2 .mc-form-2 > input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e7e7e7;
  color: #333;
  padding: 5px 60px 5px 0;
  height: 43px;
  font-size: 12px;
}

.subscribe-form-2 .mc-form-2 > input:focus {
  color: #262626;
}

.subscribe-form-2 .mc-form-2 .mc-news-2 {
  display: none;
}

.subscribe-form-2 .mc-form-2 .clear-2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}

.subscribe-form-2 .mc-form-2 .clear-2 input {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #262626;
  font-weight: 700;
  font-size: 12px;
  height: 43px;
}

.subscribe-form-2 .mc-form-2 .clear-2 input:hover {
  color: #a38b5e;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.mt-20 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2.mt-20 {
    margin-top: 0;
  }
}

.subscribe-content {
  margin: -8px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-content {
    margin: 0px 0 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-content {
    margin: 0px 0 30px;
    text-align: center;
  }
}

.subscribe-content h4 {
  font-weight: 700;
  font-size: 36px;
  margin: 0 0 13px;
  color: #fff;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .subscribe-content h4 {
    font-size: 30px;
  }
}

.subscribe-content p {
  color: #fff;
  margin: 0;
  line-height: 1;
}

.subscribe-form-3 {
  margin-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-3 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 {
    margin-left: 0px;
  }
}

.subscribe-form-3 .mc-form-3 {
  position: relative;
}

.subscribe-form-3 .mc-form-3 > input {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  height: 84px;
  font-size: 16px;
  padding: 5px 125px 5px 30px;
}

.subscribe-form-3 .mc-form-3 > input::-webkit-input-placeholder {
  color: #fff;
}

.subscribe-form-3 .mc-form-3 > input::-moz-input-placeholder {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 .mc-form-3 > input {
    padding: 5px 125px 5px 17px;
    height: 60px;
  }
}

.subscribe-form-3 .mc-form-3 .mc-news-3 {
  display: none;
}

.subscribe-form-3 .mc-form-3 .clear-3 {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form-3 .mc-form-3 .clear-3 > input {
  background-color: transparent;
  border: none;
  color: #fff;
  height: auto;
  font-size: 16px;
  padding: 0;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pt-100 {
    padding-top: 50px;
  }
  .subscribe-area.pb-100 {
    padding-bottom: 50px;
  }
}

/*-------- 7. Instagram style ---------*/
.instagram-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 45px;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap h3 {
    font-size: 20px;
    margin: 0 0 27px;
  }
}

.instagram-item {
  position: relative;
}

.instagram-item > a {
  display: block;
  position: relative;
}

.instagram-item > a::before {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.instagram-item > a img {
  width: 100%;
}

.instagram-item > .instagram-hvr-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  z-index: 99;
}

.instagram-item > .instagram-hvr-content span {
  font-size: 20px;
  margin: 0 5px;
  color: #fff;
}

.instagram-item > .instagram-hvr-content span i {
  color: #a38b5e;
  margin-right: 3px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-item > .instagram-hvr-content span {
    font-size: 19px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-item > .instagram-hvr-content span {
    font-size: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-item > .instagram-hvr-content span {
    font-size: 17px;
  }
}

.instagram-item:hover > a::before {
  opacity: 1;
  visibility: visible;
}

.instagram-item:hover > .instagram-hvr-content {
  opacity: 1;
  visibility: visible;
}

/*-------- 8. Footer style ---------*/
.footer-about ul li {
  font-size: 12px;
  color: #ddd;
  line-height: 1;
  margin: 0 0 9px;
}

.footer-about ul li:last-child {
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .footer-about ul li {
    line-height: 20px;
  }
}

.footer-about ul li a {
  color: #ddd;
  font-size: 12px;
}

.footer-about ul li a:hover {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .footer-about {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-about {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo {
    margin: 30px 0;
  }
  .footer-bottom.pt-25 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-logo a img {
    width: 100%;
  }
}

.footer-social {
  float: right;
}

.footer-social a {
  color: #ddd;
  font-size: 16px;
  margin-left: 20px;
}

.footer-social a:first-child {
  margin-left: 0;
}

.footer-social a:hover {
  color: #fff;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-social a {
    font-size: 14px;
    margin-left: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-social {
    float: inherit;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-social {
    float: right;
    text-align: inherit;
  }
}

.footer-menu ul li {
  display: inline-block;
  margin-right: 20px;
}

.footer-menu ul li:last-child {
  margin-right: 0;
}

.footer-menu ul li a {
  font-size: 12px;
  color: #ddd;
}

.footer-menu ul li a:hover {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .footer-menu {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-menu {
    text-align: inherit;
  }
}

.copyright {
  float: right;
}

.copyright p {
  margin: 0;
  font-size: 12px;
  color: #ddd;
}

@media only screen and (max-width: 767px) {
  .copyright {
    float: inherit;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright {
    float: inherit;
    text-align: center;
  }
}

.footer-mrg {
  padding: 70px 0;
}

@media only screen and (max-width: 767px) {
  .footer-mrg {
    padding: 50px 0;
  }
}

.footer-column {
  padding-left: 15px;
  padding-right: 15px;
}

.footer-top .footer-column-1, .footer-top .footer-column-6 {
  width: calc((100% - (180px * 4)) / 2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-top .footer-column-1, .footer-top .footer-column-6 {
    width: 33.333%;
  }
}

.footer-top .footer-column-2,
.footer-top .footer-column-3,
.footer-top .footer-column-4,
.footer-top .footer-column-5 {
  width: 180px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-top .footer-column-2,
  .footer-top .footer-column-3,
  .footer-top .footer-column-4,
  .footer-top .footer-column-5 {
    width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-top .footer-column-1 {
    width: 40%;
  }
  .footer-top .footer-column-2,
  .footer-top .footer-column-3,
  .footer-top .footer-column-4 {
    width: 20%;
  }
  .footer-top .footer-column-5 {
    width: 40%;
  }
  .footer-top .footer-column-6 {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top .footer-column-1 {
    width: 100%;
  }
  .footer-top .footer-column-2,
  .footer-top .footer-column-3,
  .footer-top .footer-column-4,
  .footer-top .footer-column-5 {
    width: 50%;
  }
  .footer-top .footer-column-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-top .footer-column-1 {
    width: 50%;
  }
  .footer-top .footer-column-2,
  .footer-top .footer-column-3,
  .footer-top .footer-column-4,
  .footer-top .footer-column-5 {
    width: 50%;
  }
  .footer-top .footer-column-6 {
    width: 50%;
  }
}

.footer-widget .footer-info {
  margin: 25px 0 26px;
}

.footer-widget .footer-info ul li {
  font-size: 12px;
  color: #333;
  line-height: 1;
  margin: 0 0 9px;
}

.footer-widget .footer-info ul li:last-child {
  margin: 0 0 0px;
}

.footer-widget .footer-info ul li a {
  color: #333;
  font-size: 12px;
}

.footer-widget .footer-info ul li a:hover {
  color: #989898;
}

.footer-widget .footer-map a {
  font-size: 12px;
  color: #333;
  text-decoration: underline;
}

.footer-widget .widget-title {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .widget-title {
    margin: 0 0 15px;
  }
}

.footer-widget .widget-title h3 {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}

.footer-widget .footer-list ul li {
  margin: 0 0 17px;
}

.footer-widget .footer-list ul li:last-child {
  margin: 0 0 0;
}

.footer-widget .footer-list ul li a {
  color: #333;
  font-size: 12px;
  display: block;
  line-height: 1;
}

.footer-widget .footer-list ul li a:hover {
  color: #989898;
}

.footer-widget.subscribe-right {
  width: 400px;
  max-width: 100%;
  margin-left: auto;
}

.footer-widget.footer-social-2 a {
  color: #616161;
  font-size: 12px;
  margin-right: 18px;
}

.footer-widget.footer-social-2 a:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-social-2 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget.footer-social-2 {
    text-align: left;
  }
}

.footer-widget.copyright-2 p {
  color: #333;
  font-size: 12px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .footer-widget.copyright-2 {
    margin: 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget.copyright-2 {
    margin: 0px 0 0px;
  }
}

.footer-widget.payments-img {
  text-align: right;
}

.footer-widget.payments-img p {
  color: #616161;
  font-size: 12px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .footer-widget.payments-img {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget.payments-img {
    margin: 10px 0 0px;
  }
}

.footer-hm13 {
  display: block;
  position: absolute;
  left: 0;
  bottom: 40px;
  right: 0;
  padding: 0 80px;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-hm13 {
    padding: 0 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-hm13 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-hm13 {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-hm13 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-hm13 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-hm13 {
    padding: 0 30px;
  }
}

.footer-hm13 .footer-hm13-wrap {
  display: flex;
  justify-content: space-between;
}

.footer-hm13 .footer-hm13-wrap .footer-social-hm13 a {
  color: #ccc;
  margin-right: 18px;
  font-size: 12px;
}

.footer-hm13 .footer-hm13-wrap .footer-social-hm13 a:last-child {
  margin-right: 0px;
}

.footer-hm13 .footer-hm13-wrap .footer-social-hm13 a:hover {
  color: #a38b5e;
}

@media only screen and (max-width: 767px) {
  .footer-hm13 .footer-hm13-wrap .footer-social-hm13 a {
    margin-right: 4px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-hm13 .footer-hm13-wrap .footer-social-hm13 a {
    margin-right: 8px;
  }
}

.footer-hm13 .footer-hm13-wrap .footer-copyright-hm13 p {
  color: #ccc;
  font-size: 12px;
  margin: 0;
}

/*-------- 9. Others style ---------*/
.popup-wrapper-area {
  background: rgba(51, 51, 51, 0.9) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

.popup-wrapper-area .popup-wrapper {
  background: #fff none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 206px;
  width: 1070px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .popup-wrapper-area .popup-wrapper {
    top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper {
    top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper {
    top: 50px;
    width: 940px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper {
    top: 50px;
    width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper {
    top: 100px;
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper {
    width: 400px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off {
  position: absolute;
  right: -60px;
  top: 0px;
  width: 60px;
  height: 60px;
  background-color: #292929;
  color: #fff;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    width: 45px;
    height: 45px;
    right: -45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    right: 0px;
    top: 0px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    right: 0px;
    top: 0px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    right: 0px;
    top: 0px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off i {
  line-height: 60px;
  transition: all .3s ease 0s;
  display: inline-block;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper span.popup-off i {
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper span.popup-off i {
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper span.popup-off i {
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper span.popup-off i {
    line-height: 35px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off:hover i {
  transform: rotate(90deg);
}

.popup-wrapper-area .popup-wrapper span.popup-off:hover {
  color: #a38b5e;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all {
    display: block;
    height: 450px;
    overflow-y: auto;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
  flex: 0 0 50%;
  padding: 82px 30px 31px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
    padding: 30px 30px 31px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
    padding: 15px 15px 15px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area {
    padding: 15px 15px 15px 15px;
    flex: 0 0 100%;
    width: 100%;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
  font-size: 48px;
  margin: 0px 0 25px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  line-height: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 10px;
    font-size: 23px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area h2 {
    margin: 8px 0 10px;
    font-size: 30px;
    line-height: 30px;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area span {
  font-weight: 700;
  color: #333;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom {
  margin: 30px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom {
    margin: 15px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom {
    margin: 15px 0 15px;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input {
  border: 1px solid #e7e7e7;
  height: 50px;
  padding: 0px 0px 0px 15px;
  font-size: 14px;
  color: #989898;
  background-color: transparent;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form input:focus {
  color: #262626;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .mc-news {
  display: none;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
  margin-top: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 {
    margin-top: 15px;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input {
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #262626;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 700;
  text-align: center;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .subscribe-bottom .mc-form .clear-2 input:hover {
  background-color: #a38b5e;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show {
  display: flex;
  align-items: center;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show input {
  width: auto;
  height: auto;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-subscribe-area .dont-show label {
  color: #262626;
  display: block;
  margin: 0 0 0 5px;
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image {
  flex: 0 0 50%;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image {
    flex: 0 0 100%;
    width: 100%;
  }
}

.popup-wrapper-area .popup-wrapper .popup-wrapper-all .popup-image img {
  width: 100%;
}

.body-bg-color {
  background-color: #9E9393;
}

.content-wrapper {
  display: block;
  margin: 0 auto;
  width: 1310px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .content-wrapper {
    width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .content-wrapper {
    width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-wrapper {
    width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .content-wrapper {
    width: 100%;
  }
}

.page-middle-area {
  display: block;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-middle-area.mt-120 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-middle-area.mt-120 {
    margin-top: 0;
  }
}

.main-sidebar-right {
  position: relative;
  float: left;
  width: calc(100% - 305px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-sidebar-right {
    width: calc(100% - 270px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right {
    width: calc(100% - 250px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right {
    width: calc(100% - 230px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right {
    width: calc(100% - 0px);
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right {
    width: calc(100% - 0px);
  }
}

.mfp-bg {
  z-index: 99999;
}

.mfp-wrap {
  z-index: 999999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area.mt-175 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .error-area.mt-175 {
    margin-top: 0;
  }
}

.error-content h2 {
  font-size: 80px;
  font-weight: bold;
  margin: 0;
  line-height: 58px;
}

.error-content p {
  font-size: 18px;
  margin: 25px 0 38px;
}

.error-content a {
  background-color: #262626;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 18px 40px 19px;
  border-radius: 50px;
}

.error-content a:hover {
  background-color: #a38b5e;
}

.coming-soon-area {
  position: relative;
}

.coming-soon-area .coming-soon-img {
  height: 100vh;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-img {
    position: static;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-img {
    position: static;
    width: 100%;
    height: 100%;
  }
}

.coming-soon-area .coming-soon-img img {
  height: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .height-100vh {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .height-100vh {
    height: 100%;
  }
}

.coming-soon-content {
  padding: 0px 195px 0px 195px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-content {
    padding: 0px 60px 0px 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content {
    padding: 0px 50px 0px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content {
    padding: 0px 30px 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content {
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-content {
    padding: 30px 40px 30px 40px;
  }
}

.coming-soon-content h4 {
  font-size: 20px;
  margin: 0 0 5px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .coming-soon-content h4 {
    font-size: 18px;
  }
}

.coming-soon-content .timer-style-3 {
  margin: 55px 0 43px;
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .timer-style-3 {
    margin: 25px 0 0px;
  }
}

.coming-soon-content .timer-style-3 div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coming-soon-content .timer-style-3 div > span {
  margin: 0 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content .timer-style-3 div > span {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content .timer-style-3 div > span {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .timer-style-3 div > span {
    margin: 0 15px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-content .timer-style-3 div > span {
    margin: 0 30px 20px;
  }
}

.coming-soon-content .timer-style-3 div > span > span {
  color: #a38b5e;
  font-family: "Playfair Display", serif;
  font-size: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content .timer-style-3 div > span > span {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .timer-style-3 div > span > span {
    font-size: 40px;
  }
}

.coming-soon-content .timer-style-3 div > span > p {
  color: #989898;
  margin: 5px 0 0;
  font-size: 14px;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 {
  position: relative;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 > input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e7e7e7;
  height: 43px;
  color: #989898;
  padding: 2px 95px 0 0;
  font-size: 16px;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 > input:focus {
  color: #262626;
  border-bottom: 1px solid #262626;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 .mc-news-4 {
  display: none;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 .clear-4 {
  position: absolute;
  right: 0;
  top: 0;
}

.coming-soon-content .subscribe-form-4 .mc-form-4 .clear-4 input {
  color: #262626;
  font-weight: bold;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 16px;
}

.coming-soon-content .go-back {
  margin: 80px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content .go-back {
    margin: 40px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .go-back {
    margin: 30px 0 0;
  }
}

.coming-soon-content .go-back a {
  display: inline-block;
  text-decoration: underline;
  color: #262626;
}

.coming-soon-content .go-back a:hover {
  text-decoration: none;
  color: #a38b5e;
}

.modal-backdrop {
  z-index: 9999;
}

.modal {
  z-index: 99999;
}

/*-------- 10. Service style ---------*/
.service-wrap {
  display: flex;
}

.service-wrap .service-icon {
  margin: 0 20px 0px 0;
}

.service-wrap .service-icon i {
  font-size: 30px;
}

.service-wrap .service-content h6 {
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 11px;
  line-height: 1;
}

.service-wrap .service-content p {
  color: #989898;
  margin: 0;
  font-size: 12px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .service-area.pt-70 {
    padding-top: 20px;
  }
}

/*-------- 11. Animation style ---------*/
@keyframes lastudioFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lastudioZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes lastudioMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lastudioMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes lastudioFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes lastudioFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes lastudioFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

/*-------- 12. Testimonial style ---------*/
.single-testimonial .client-img img {
  width: 65px;
  display: inline-block;
}

.single-testimonial p {
  font-size: 18px;
  line-height: 1.56;
  margin: 19px 0 51px;
  font-family: "Playfair Display", serif;
  color: #262626;
}

.single-testimonial .client-info {
  position: relative;
}

.single-testimonial .client-info:before {
  position: absolute;
  left: 0;
  top: -28px;
  right: 0;
  margin: 0 auto;
  width: 35px;
  height: 2px;
  background-color: #828282;
  content: "";
}

.single-testimonial .client-info h5 {
  color: #262626;
  line-height: 1;
  margin: 0 0 2px;
}

.single-testimonial .client-info span {
  color: #262626;
  line-height: 1;
  margin: 0;
}

/*-------- 13. Video style -------------*/
.video-area.video-area-ptb {
  padding: 240px 0 205px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-area.video-area-ptb {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area.video-area-ptb {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area.video-area-ptb {
    padding: 100px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area.video-area-ptb {
    padding: 50px 0 50px;
  }
}

.video-area.video-area-ptb-2 {
  padding: 300px 0 300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-area.video-area-ptb-2 {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area.video-area-ptb-2 {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area.video-area-ptb-2 {
    padding: 100px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area.video-area-ptb-2 {
    padding: 50px 0 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-area.video-area-ptb-2 {
    padding: 80px 0 80px;
  }
}

.video-area.video-area-ptb-3 {
  padding: 218px 0 218px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-area.video-area-ptb-3 {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area.video-area-ptb-3 {
    padding: 140px 0 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area.video-area-ptb-3 {
    padding: 100px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area.video-area-ptb-3 {
    padding: 50px 0 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-area.video-area-ptb-3 {
    padding: 80px 0 80px;
  }
}

.video-area .video-content h2 {
  font-size: 72px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  margin: 0 0 49px;
  color: #fff;
  line-height: 64px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area .video-content h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area .video-content h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area .video-content h2 {
    font-size: 30px;
    margin: 0 0 25px;
    line-height: 40px;
  }
}

.video-btn {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: all .5s;
}

.video-btn::before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.video-btn a {
  display: block;
  z-index: 99;
  position: relative;
}

.video-btn a img {
  width: auto;
  transform: scale(1);
  transition: all .5s;
}

.video-btn:hover::before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

.video-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  margin: -10px;
  border-radius: 50%;
}

.video-btn:hover a img {
  transform: scale(0.9);
}

/*-------- 14. Blog style ---------*/
.blog-wrap .blog-img {
  overflow: hidden;
}

.blog-wrap .blog-img img {
  width: 100%;
  transform: scale(1.1);
  transition: transform 0.5s ease-out;
}

.blog-wrap .blog-img .blog-quote-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 85px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img .blog-quote-content {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .blog-quote-content {
    left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content {
    left: 15px;
  }
}

.blog-wrap .blog-img .blog-quote-content p {
  color: #fff;
  font-size: 36px;
  font-family: "Playfair Display", serif;
  margin: 0 0 26px;
  line-height: 48px;
  width: 37%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img .blog-quote-content p {
    width: 47%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .blog-quote-content p {
    width: 64%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content p {
    font-size: 16px;
    line-height: 22px;
    width: 98%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content p {
    font-size: 26px;
    line-height: 36px;
  }
}

.blog-wrap .blog-img .blog-quote-content span {
  color: #fff;
  font-size: 18px;
  font-family: "Playfair Display", serif;
  border-top: 2px solid #fff;
  padding: 10px 0 0;
}

.blog-wrap .blog-img .blog-quote-content.blog-quote2 p {
  width: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote2 p {
    width: 66%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote2 p {
    width: 64%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote2 p {
    width: 98%;
  }
}

.blog-wrap .blog-img .blog-quote-content.blog-quote3 {
  left: 40px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote3 {
    left: 15px;
  }
}

.blog-wrap .blog-img .blog-quote-content.blog-quote3 p {
  width: 60%;
  font-size: 22px;
  line-height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote3 p {
    width: 90%;
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote3 p {
    width: 98%;
    font-size: 16px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-img .blog-quote-content.blog-quote3 p {
    width: 80%;
    font-size: 20px;
    line-height: 31px;
  }
}

.blog-wrap .blog-img .blog-video-popup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

.blog-wrap .blog-img .blog-video-popup a {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 16px;
  background-color: #fff;
  text-align: center;
  display: inline-block;
}

.blog-wrap .blog-img .blog-video-popup a i {
  line-height: 60px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-20 {
    margin-bottom: 10px;
  }
}

.blog-wrap .blog-content {
  padding: 23px 30px 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content {
    padding: 23px 15px 22px;
  }
}

.blog-wrap .blog-content .blog-meta ul li {
  display: inline-block;
  margin-right: 3px;
}

.blog-wrap .blog-content .blog-meta ul li:Last-child {
  margin-right: 0;
}

.blog-wrap .blog-content .blog-meta ul li a {
  font-size: 12px;
  color: #989898;
}

.blog-wrap .blog-content h3 {
  font-size: 24px;
  margin: 5px 0 16px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 20px;
  }
}

.blog-wrap .blog-content p {
  font-size: 12px;
  margin: 0px;
  color: #989898;
  line-height: 1.8;
}

.blog-wrap .blog-content .blog-btn {
  margin: 16px 0 0;
}

.blog-wrap .blog-content .blog-btn a {
  color: #262626;
}

.blog-wrap .blog-content .blog-btn a:hover {
  color: #a38b5e;
}

.blog-wrap .blog-content-2 .blog-meta-2 ul li {
  display: inline-block;
  margin-right: 3px;
}

.blog-wrap .blog-content-2 .blog-meta-2 ul li:Last-child {
  margin-right: 0;
}

.blog-wrap .blog-content-2 .blog-meta-2 ul li a {
  font-size: 12px;
  color: #989898;
}

.blog-wrap .blog-content-2 h3 {
  font-size: 36px;
  font-family: "Playfair Display", serif;
  margin: 3px 0 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 28px;
    margin: 3px 0 10px;
  }
}

.blog-wrap .blog-content-2 p {
  font-size: 12px;
  line-height: 2;
  margin: 0;
  color: #989898;
}

.blog-wrap .blog-content-2 .blog-btn-2 {
  margin-top: 26px;
}

.blog-wrap .blog-content-2 .blog-btn-2 a {
  display: inline-block;
  color: #262626;
}

.blog-wrap .blog-content-2 .blog-btn-2 a:hover {
  color: #a38b5e;
}

.blog-wrap.blog-shadow {
  box-shadow: 3px 2px 15px 0 rgba(213, 213, 213, 0.2);
}

.blog-wrap:hover .blog-img img {
  transform: scale(1);
}

.blog-wrap:hover .blog-img.default-overlay:before {
  opacity: .1;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .blog-wrap.mb-65 {
    margin-bottom: 30px;
  }
}

.blog-gallery-img.owl-carousel .owl-item {
  overflow: hidden;
}

.blog-gallery-img.owl-carousel .owl-item a {
  position: relative;
  display: block;
}

.blog-gallery-img.owl-carousel .owl-item a::before {
  content: '';
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: all 0.5s ease-out;
  z-index: 9;
}

.blog-gallery-img.owl-carousel .owl-item a img {
  transform: scale(1.1);
  transition: transform 0.5s ease-out;
}

.blog-gallery-img.owl-carousel:hover .owl-item a img {
  transform: scale(1);
}

.blog-gallery-img.owl-carousel:hover .owl-item a::before {
  opacity: .1;
  pointer-events: none;
}

.nav-style-1 .owl-nav div {
  color: #232324;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  left: 20px;
  position: absolute;
  top: 50%;
  background-color: #fff;
  text-align: center;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

.nav-style-1 .owl-nav div:hover {
  color: #a38b5e;
}

.nav-style-1 .owl-nav div i {
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .nav-style-1 .owl-nav div {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  .nav-style-1 .owl-nav div i {
    line-height: 30px;
  }
}

.nav-style-1 .owl-nav div.owl-next {
  left: auto;
  right: 20px;
}

.blog-details-content img {
  width: 100%;
}

.blog-details-content .blog-details-meta {
  margin: 23px 0 19px;
}

.blog-details-content .blog-details-meta ul li {
  display: inline-block;
  margin-right: 3px;
}

.blog-details-content .blog-details-meta ul li:Last-child {
  margin-right: 0;
}

.blog-details-content .blog-details-meta ul li a {
  font-size: 12px;
  color: #989898;
}

.blog-details-content .blog-details-meta ul li a:hover {
  color: #a38b5e;
}

.blog-details-content p {
  line-height: 1.9;
  color: #989898;
  margin: 0 0 23px;
}

.blog-details-content .blog-natural-area {
  margin: 32px 0 44px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-natural-area {
    margin: 32px 0 0px;
  }
}

.blog-details-content .blog-natural-area .natural-img {
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-natural-area .natural-img {
    margin-right: 0px;
  }
}

.blog-details-content .blog-natural-area .natural-content {
  margin-left: -50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-natural-area .natural-content {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.blog-details-content .blog-natural-area .natural-content h3 {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-natural-area .natural-content h3 {
    font-size: 20px;
  }
}

.blog-details-content .reason-reason-area h3 {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .reason-reason-area h3 {
    font-size: 20px;
  }
}

.blog-details-content .reason-reason-area blockquote {
  padding: 0 60px 0 30px;
  margin: 35px 0 29px;
  border-left: 2px solid #eeeeee;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .reason-reason-area blockquote {
    padding: 0 15px 0 30px;
  }
}

.blog-details-content .reason-reason-area blockquote p {
  color: #262626;
  font-family: "Playfair Display", serif;
  margin: 0 0 15px;
}

.blog-details-content .reason-reason-area blockquote span {
  font-weight: 600;
  color: #262626;
  font-size: 12px;
}

.blog-details-content .reason-reason-area.blog-mrg-increase h3 {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .reason-reason-area.blog-mrg-increase h3 {
    margin: 0 0 20px;
  }
}

.blog-details-content .blog-details-list {
  margin: 49px 0 46px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-list {
    margin: 30px 0 30px;
  }
}

.blog-details-content .blog-details-list h3 {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-list h3 {
    font-size: 19px;
    margin: 0 0 20px;
  }
}

.blog-details-content .blog-details-list ul li {
  line-height: 1.9;
  position: relative;
  color: #989898;
  margin-bottom: 1.5em;
  padding-left: 15px;
}

.blog-details-content .blog-details-list ul li::before {
  content: '\f111';
  font-family: FontAwesome;
  font-size: 5px;
  text-rendering: auto;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 1.6em;
}

.blog-details-content .blog-dec-tag-social {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DCDCDC;
  padding: 0 0 12px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-tag-social {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .blog-dec-tag-social {
    display: flex;
  }
}

.blog-details-content .blog-dec-tag-social .blog-dec-tag span {
  color: #989898;
  font-size: 12px;
}

.blog-details-content .blog-dec-tag-social .blog-dec-tag span.tags-list-item {
  color: #262626;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap {
  flex: 0 0 50%;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-tag-social .blog-dec-social-wrap {
    text-align: left;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-content .blog-dec-tag-social .blog-dec-social-wrap {
    text-align: right;
    margin-top: 0px;
  }
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post {
  display: inline-block;
  position: relative;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post span {
  color: #989898;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post span > i {
  margin-left: 5px;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social {
  position: absolute;
  top: -33px;
  right: 0;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social {
    left: 0;
    right: auto;
  }
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social a.facebook {
  background-color: #5678bf;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social a.twitter {
  background-color: #73c2fd;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social a.pinterest {
  background-color: #c32026;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post .blog-dec-social a.google {
  background-color: #dd4b39;
}

.blog-details-content .blog-dec-tag-social .blog-dec-social-wrap .share-post:hover .blog-dec-social {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .blog-details-content.mt-65 {
    margin-top: 30px;
  }
  .blog-details-content.mb-65 {
    margin-bottom: 30px;
  }
}

.blog-details-banner img {
  width: 100%;
}

.blog-comment {
  margin: 47px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-comment {
    margin: 48px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-comment {
    margin: 20px 0 0;
  }
}

.blog-comment .no-review p {
  margin: 0 0 45px;
  color: #989898;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-comment .no-review p {
    margin: 0 0 24px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-comment .no-review p {
    margin: 0 0 20px;
  }
}

.blog-comment .comment-form > h3 {
  font-size: 14px;
  margin: 0 0 11px;
  font-weight: 600;
  color: #262626;
  text-transform: uppercase;
}

.blog-comment .comment-form p {
  color: #989898;
  font-style: italic;
  font-size: 12px;
  margin: 0 0 15px;
}

.blog-comment .comment-form p span {
  color: red;
}

.blog-comment .comment-form form .leave-form {
  margin: 0 0 12px;
}

.blog-comment .comment-form form .leave-form label {
  display: block;
  margin: 0 0 7px;
  color: #989898;
}

.blog-comment .comment-form form .leave-form label span {
  color: red;
}

.blog-comment .comment-form form .leave-form input {
  background-color: transparent;
  height: 50px;
  border: 1px solid #e7e7e7;
}

.blog-comment .comment-form form .leave-form input:focus {
  border: 1px solid #262626;
}

.blog-comment .comment-form form .leave-form textarea {
  background-color: transparent;
  height: 150px;
  border: 1px solid #e7e7e7;
}

.blog-comment .comment-form form .leave-form textarea:focus {
  border: 1px solid #262626;
}

.blog-comment .comment-form form .text-submit {
  margin-top: 13px;
}

.blog-comment .comment-form form .text-submit input {
  height: auto;
  border: none;
  display: inline-block;
  padding: 12px 35px;
  font-weight: 600;
  background-color: #262626;
  text-transform: uppercase;
  color: #fff;
  width: auto;
  font-size: 12px;
}

.blog-comment .comment-form form .text-submit input:hover {
  background-color: #a38b5e;
}

.blog-related-wrap .blog-related-title {
  margin: 0 0 35px;
}

@media only screen and (max-width: 767px) {
  .blog-related-wrap .blog-related-title {
    margin: 0 0 15px;
  }
}

.blog-related-wrap .blog-related-title h3 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  display: inline-block;
}

.blog-related-wrap .blog-related-title h3:before {
  position: absolute;
  left: -56px;
  top: 7px;
  content: "";
  background-color: #fff;
  height: 2px;
  width: 35px;
}

.blog-related-wrap .blog-related-title h3:after {
  position: absolute;
  right: -56px;
  top: 7px;
  content: "";
  background-color: #fff;
  height: 2px;
  width: 35px;
}

.blog-related-wrap .single-blog-related h2 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  margin: 0;
  color: #fff;
}

.blog-related-wrap .single-blog-related h2 a {
  color: #fff;
}

.blog-related-wrap .single-blog-related p {
  line-height: 1.8;
  margin: 18px auto 25px;
  color: #fff;
  width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-related-wrap .single-blog-related p {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-related-wrap .single-blog-related p {
    width: 98%;
  }
}

.blog-related-wrap .single-blog-related .blog-related-btn a {
  font-size: 12px;
  text-transform: uppercase;
  border: 2px solid #fff;
  color: #fff;
  padding: 15px 35px 14px;
  display: inline-block;
  font-weight: 600;
}

.blog-related-wrap .single-blog-related .blog-related-btn a:hover {
  background-color: #fff;
  color: #262626;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-mt-md {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .res-mt-xs {
    margin-top: 30px;
  }
  .blog-related-post.pt-75 {
    padding-top: 40px;
  }
}

/*-------- 15. Breadcrumb style ---------*/
.breadcrumb-area {
  padding: 43px 0 49px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area.mt-175 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area.mt-175 {
    margin-top: 0;
  }
}

.breadcrumb-area.breadcrumb-height-2 {
  padding: 120px 0 120px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area.breadcrumb-height-2 {
    padding: 50px 0 50px;
  }
}

.breadcrumb-bg-1 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-1.png);
  background-position: 70% 50px;
  background-repeat: no-repeat;
  background-size: 200px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-bg-1 {
    background-position: 77% 105px;
  }
}

.breadcrumb-bg-2 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.breadcrumb-bg-3 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-3.jpg);
  background-repeat: repeat;
  background-position: left top;
  background-size: cover;
  background-color: transparent;
}

.breadcrumb-bg-4 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-4.jpg);
  background-repeat: repeat;
  background-position: left top;
}

.breadcrumb-bg-5 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-5.jpg);
  background-repeat: repeat;
  background-position: left top;
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content .breadcrumb-title {
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title {
    margin: 0 0 10px;
  }
}

.breadcrumb-content .breadcrumb-title h2 {
  font-size: 36px;
  margin: 0;
  font-weight: bold;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 25px;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 18px 0 0;
  text-transform: capitalize;
  color: #989898;
  position: relative;
}

.breadcrumb-content ul li::before {
  position: absolute;
  width: 13px;
  height: 1px;
  background-color: #989898;
  content: "";
  right: -17px;
  top: 13px;
  z-index: 99;
  transform: rotate(115deg);
}

.breadcrumb-content ul li:last-child::before {
  display: none;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
  color: #989898;
}

.breadcrumb-content ul li a:hover {
  color: #a38b5e;
}

.breadcrumb-content.breadcrumb-content-white .breadcrumb-title h2 {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li::before {
  background-color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li a {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li a:hover {
  color: #a38b5e;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content.mb-35 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content.mb-35 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content.mb-35 {
    margin-bottom: 15px;
  }
}

.breadcrumb-area-2 {
  padding: 120px 0 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area-2.mt-175 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area-2 {
    padding: 48px 0 45px;
  }
  .breadcrumb-area-2.mt-175 {
    margin-top: 0;
  }
}

.breadcrumb-content-2 .breadcrumb-title-2 {
  margin: 0 0 90px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 .breadcrumb-title-2 {
    margin: 0 0 20px;
  }
}

.breadcrumb-content-2 .breadcrumb-title-2 h2 {
  font-size: 72px;
  color: #fff;
  margin: 0 0 24px;
  font-family: "Playfair Display", serif;
  line-height: 63px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 .breadcrumb-title-2 h2 {
    font-size: 40px;
    margin: 0 0 15px;
    line-height: 35px;
  }
}

.breadcrumb-content-2 .breadcrumb-title-2 p {
  font-size: 18px;
  color: #fff;
  margin: 0 auto;
  font-family: "Playfair Display", serif;
  width: 54%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content-2 .breadcrumb-title-2 p {
    width: 64%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 .breadcrumb-title-2 p {
    width: 84%;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 .breadcrumb-title-2 p {
    width: 98%;
  }
}

.breadcrumb-content-2 ul li {
  display: inline-block;
  margin: 0 18px 0 0;
  text-transform: capitalize;
  color: #fff;
  position: relative;
  font-size: 13px;
}

.breadcrumb-content-2 ul li::before {
  position: absolute;
  width: 13px;
  height: 1px;
  background-color: #fff;
  content: "";
  right: -17px;
  top: 13px;
  z-index: 99;
  transform: rotate(115deg);
}

.breadcrumb-content-2 ul li:last-child::before {
  display: none;
}

.breadcrumb-content-2 ul li a {
  text-transform: capitalize;
  color: #fff;
  font-size: 13px;
}

.breadcrumb-content-2 ul li a:hover {
  color: #a38b5e;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area.breadcrumb-bg-3.pt-200 {
    padding-top: 120px;
  }
  .breadcrumb-area.breadcrumb-bg-3.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area.pt-200 {
    padding-top: 75px;
  }
  .breadcrumb-area.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area.pt-200 {
    padding-top: 55px;
  }
  .breadcrumb-area.pb-120 {
    padding-bottom: 60px;
  }
}

/*-------- 16. Shop style ---------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  line-height: 24px;
  display: block;
  line-height: 1;
  color: #989898;
  font-size: 12px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  margin-left: 45px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 45px;
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #989898;
  margin-right: 11px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active {
  border-bottom: 1px solid #a38b5e;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #a38b5e;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #989898;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #262626;
  font-weight: 600;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 30px 0;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #262626;
  font-weight: 600;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #989898;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #fff;
  background-color: #a38b5e;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #fff;
  background-color: #a38b5e;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 40px 55px 34px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 14px;
  margin: 0 0 29px;
  text-transform: uppercase;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .sort-filter ul li, .product-filter-wrapper .product-filter .price-filter ul li, .product-filter-wrapper .product-filter .category-filter ul li {
  margin-bottom: 7px;
  display: block;
}

.product-filter-wrapper .product-filter .sort-filter ul li:last-child, .product-filter-wrapper .product-filter .price-filter ul li:last-child, .product-filter-wrapper .product-filter .category-filter ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .sort-filter ul li a, .product-filter-wrapper .product-filter .price-filter ul li a, .product-filter-wrapper .product-filter .category-filter ul li a {
  color: #989898;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .sort-filter ul li a.active, .product-filter-wrapper .product-filter .sort-filter ul li a:hover, .product-filter-wrapper .product-filter .price-filter ul li a.active, .product-filter-wrapper .product-filter .price-filter ul li a:hover, .product-filter-wrapper .product-filter .category-filter ul li a.active, .product-filter-wrapper .product-filter .category-filter ul li a:hover {
  color: #a38b5e;
}

.product-filter-wrapper .product-filter .category-filter ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-filter .category-filter ul li a {
  color: #4a4141;
}

.product-filter-wrapper .product-filter .color-filter ul li {
  margin-bottom: 8px;
}

.product-filter-wrapper .product-filter .color-filter ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 3px 10px 5px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.black {
  background-color: #484848;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.blue {
  background-color: #475bd7;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.green {
  background-color: #93cda4;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.orange {
  background-color: #ffa875;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.pink {
  background-color: #f7a2c8;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.red {
  background-color: #f18787;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.white {
  background-color: #f3f3f3;
}

.product-filter-wrapper .product-filter .color-filter ul li a .swatch-anchor.yellow {
  background-color: #dbdd30;
}

.product-filter-wrapper .product-filter .color-filter ul li a .color-text {
  color: #989898;
  font-size: 12px;
  margin-left: 10px;
}

.product-filter-wrapper .product-filter .color-filter ul li a:hover .color-text {
  color: #a38b5e;
}

.product-filter-wrapper .product-filter .fliter-size ul li {
  display: flex;
  align-items: center;
  margin: 0 0 17px;
}

.product-filter-wrapper .product-filter .fliter-size ul li:last-child {
  margin: 0 0 0px;
}

.product-filter-wrapper .product-filter .fliter-size ul li input {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.product-filter-wrapper .product-filter .fliter-size ul li a {
  color: #989898;
  font-size: 12px;
  margin-left: 10px;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 4px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 2px;
  }
}

.pro-pagination-style ul li a {
  font-size: 12px;
  text-align: center;
  display: block;
  position: relative;
  background: #fff;
  border: 1px solid transparent;
  padding: 9px 10px;
  line-height: 20px;
  min-width: 40px;
  margin: 0 1px;
  color: #343538;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a {
    padding: 9px 7px;
  }
}

.pro-pagination-style ul li a:hover {
  border: 1px solid #343538;
}

.pro-pagination-style ul li a.active {
  background-color: #a38b5e;
  color: #fff;
  border-radius: 100%;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-25 {
    margin-top: 0px;
  }
  .pro-pagination-style.mt-15 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.mb-55 {
    margin-bottom: 35px;
  }
}

.sidebar-widget h4.pro-sidebar-title {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}

.sidebar-widget .sidebar-widget-list ul li {
  padding-bottom: 21px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list ul li {
    padding-bottom: 12px;
  }
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding-bottom: 0;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-list.mt-35 {
    margin-top: 20px;
  }
}

.sidebar-widget .sidebar-widget-color ul li {
  margin-right: 8px;
  display: inline-block;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 4px;
  }
}

.sidebar-widget .sidebar-widget-color ul li:last-child {
  margin-right: 0;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 3px 10px 5px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.black {
  background-color: #484848;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.blue {
  background-color: #475bd7;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.green {
  background-color: #93cda4;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.orange {
  background-color: #ffa875;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.pink {
  background-color: #f7a2c8;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.red {
  background-color: #f18787;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.white {
  background-color: #f3f3f3;
}

.sidebar-widget .sidebar-widget-color ul li a .swatch-anchor.yellow {
  background-color: #dbdd30;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-size.mt-40 {
    margin-top: 26px;
  }
}

.sidebar-widget .sidebar-widget-size ul li {
  display: inline-block;
  margin: 0 2px 5px 0;
}

.sidebar-widget .sidebar-widget-size ul li a {
  display: inline-block;
  color: #989898;
  border: 1px solid #989898;
  padding: 8px 32px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 8px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 3px 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 3px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 8px 28px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    padding: 6px 22px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a:hover {
  background-color: #262626;
  color: #fff;
  border: 1px solid #262626;
}

.sidebar-widget .price-filter #slider-range {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 6px;
  margin-bottom: 12px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #D8D8D8 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 20px;
  margin-left: 0;
  width: 20px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  top: -8px;
  cursor: ew-resize;
}

.sidebar-widget .price-filter .price-slider-amount {
  margin: 20px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  margin: 23px 0 0;
  display: flex;
  align-items: center;
}

.sidebar-widget .price-filter .price-slider-amount .label-input span {
  color: #989898 !important;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-weight: 400;
  height: auto;
  color: #989898 !important;
}

.sidebar-widget .price-filter .price-slider-amount button {
  width: 90px;
  background-color: #262626;
  padding: 7px 10px 7px;
  margin-top: 22px;
  font-weight: 400;
  order: 2;
  color: #fff;
  transition: all 0.3s;
  border: none;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #a38b5e;
  color: #fff;
}

.sidebar-widget .sidebar-widget-search .search-form-2 {
  position: relative;
}

.sidebar-widget .sidebar-widget-search .search-form-2 input {
  background: transparent;
  height: 50px;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  color: #232323;
  padding: 2px 40px 2px 20px;
}

.sidebar-widget .sidebar-widget-search .search-form-2 .button-search-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #232323;
  line-height: 1;
}

.sidebar-widget .sidebar-widget-search .search-form-2 .button-search-2:hover {
  color: #a38b5e;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-product-wrap.mt-40 {
    margin-top: 26px;
  }
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product {
  display: flex;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #DCDCDC;
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
  flex: 0 0 70px;
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img a img {
  width: 100%;
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content {
  margin-left: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content {
    margin-left: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content {
    margin-left: 8px;
  }
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 {
  font-size: 16px;
  margin: 0 0 5px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 {
    font-size: 15px;
  }
}

.sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
  font-size: 12px;
  color: #8a8a8a;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-product-wrap .single-sidebar-product.mb-40 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-archive.mt-40 {
    margin-top: 17px;
  }
}

.sidebar-widget .sidebar-widget-archive ul li {
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
  padding: 4px 0px 13px;
  cursor: pointer;
}

.sidebar-widget .sidebar-widget-archive ul li a {
  font-size: 14px;
  text-transform: capitalize;
}

.sidebar-widget .sidebar-widget-archive ul li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-meta.mt-40 {
    margin-top: 20px;
  }
}

.sidebar-widget .sidebar-widget-meta ul li {
  margin-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-meta ul li {
    margin-bottom: 10px;
  }
}

.sidebar-widget .sidebar-widget-meta ul li:last-child {
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-widget-meta ul li a {
  font-size: 14px;
  text-transform: capitalize;
}

.sidebar-widget .sidebar-widget-meta ul li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-about-author.mt-40 {
    margin-top: 26px;
  }
}

.sidebar-widget .sidebar-about-author img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-about-author img {
    width: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-widget .sidebar-about-author img {
    width: auto;
  }
}

.sidebar-widget .sidebar-about-author h6 {
  font-size: 14px;
  margin: 17px 0 0;
  font-family: "Playfair Display", serif;
}

.sidebar-widget .sidebar-about-author p {
  font-size: 12px;
  color: #989898;
  margin: 11px 0 13px;
}

.sidebar-widget .sidebar-about-author .sidebar-author-social a {
  margin-right: 16px;
}

.sidebar-widget .sidebar-about-author .sidebar-author-social a:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-about-author.blog-dec-author img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-about-author.blog-dec-author img {
    width: auto;
  }
}

.collection-content {
  margin-bottom: 45px;
}

.collection-content p {
  margin: 0 auto;
  width: 56%;
  font-size: 16px;
  line-height: 28px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-content p {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .collection-content p {
    width: 100%;
  }
}

.vendor-content {
  padding-left: 330px;
  position: relative;
  padding-bottom: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content {
    padding-left: 290px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content {
    padding-left: 280px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content {
    padding-left: 90px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .vendor-content {
    padding-left: 120px;
    padding-bottom: 15px;
  }
}

.vendor-content h2 {
  font-weight: 700;
  font-size: 36px;
  margin: 0 0 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content h2 {
    font-size: 25px;
  }
}

.vendor-content .vendor-address-wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-address-wrap {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-address-wrap {
    display: block;
  }
}

.vendor-content .vendor-address-wrap .vendor-address ul {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-address-wrap .vendor-address ul {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-address-wrap .vendor-address ul {
    display: block;
  }
}

.vendor-content .vendor-address-wrap .vendor-address ul li {
  color: #989898;
  font-size: 12px;
  margin-right: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .vendor-address-wrap .vendor-address ul li {
    margin-right: 6px;
  }
}

.vendor-content .vendor-address-wrap .vendor-address ul li:last-child {
  margin-right: 0;
}

.vendor-content .vendor-address-wrap .vendor-address ul li i {
  padding-right: 7px;
}

.vendor-content .vendor-address-wrap .vendor-address ul li a {
  color: #989898;
  font-size: 12px;
}

.vendor-content .vendor-address-wrap .vendor-address ul li a:hover {
  color: #a38b5e;
}

.vendor-content .vendor-address-wrap .vendor-social {
  margin: -29px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-address-wrap .vendor-social {
    margin: 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-address-wrap .vendor-social {
    margin: 0px 0 0;
  }
}

.vendor-content .vendor-address-wrap .vendor-social ul {
  display: flex;
  flex-wrap: wrap;
}

.vendor-content .vendor-address-wrap .vendor-social ul li {
  margin-left: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .vendor-address-wrap .vendor-social ul li {
    margin-left: 10px;
  }
}

.vendor-content .vendor-address-wrap .vendor-social ul li:first-child {
  margin-left: 0;
}

.vendor-content .vendor-address-wrap .vendor-social ul li a {
  color: #989898;
}

.vendor-content .vendor-address-wrap .vendor-social ul li a:hover {
  color: #a38b5e;
}

.vendor-content .vendor-img {
  position: absolute;
  left: 0;
  top: -158px;
  width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-img {
    top: -115px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-img {
    top: 5px;
    width: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .vendor-content .vendor-img {
    top: 5px;
    width: 100px;
  }
}

.vendor-content .vendor-img img {
  width: 100%;
}

/*-------- 17. Product details style ---------*/
.product-big-img-style .easyzoom-style {
  position: relative;
}

.product-big-img-style .easyzoom-style .easyzoom a img {
  width: 100%;
}

.product-big-img-style .easyzoom-style .img-gallery img {
  width: 100%;
}

.product-big-img-style .easyzoom-style > span {
  display: inline-block;
  color: #fff;
  top: 20px;
  width: 90px;
  height: 31px;
  line-height: 31px;
  border-radius: 50px;
  text-align: center;
  position: absolute;
  font-size: 12px;
  z-index: 999;
}

.product-big-img-style .easyzoom-style > span.badge-right {
  right: 20px;
}

.product-big-img-style .easyzoom-style > span.badge-black {
  background-color: #1c1c1c;
}

.product-big-img-style .easyzoom-style > .pro-dec-video {
  position: absolute;
  right: 25px;
  bottom: 27px;
  z-index: 999;
}

.product-big-img-style .easyzoom-style > .pro-dec-video a {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 16px;
  background-color: #fff;
  text-align: center;
  display: inline-block;
}

.product-big-img-style .easyzoom-style > .pro-dec-video a i {
  line-height: 50px;
}

.product-big-img-style .easyzoom-style > .pro-dec-zoom-img {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 999;
}

.product-big-img-style .easyzoom-style > .pro-dec-zoom-img a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 14px;
  background-color: #a38b5e;
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
}

.product-big-img-style .easyzoom-style > .pro-dec-zoom-img a i {
  line-height: 30px;
}

.product-small-img-style .slick-list {
  width: 450px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .product-small-img-style .slick-list {
    width: auto;
  }
}

.product-small-img-style .slick-list .product-dec-small {
  border: 1px solid transparent;
  margin: 0 3px;
  position: relative;
}

.product-small-img-style .slick-list .product-dec-small.slick-current {
  border: 1px solid #262626;
}

.product-small-img-style .slick-list .product-dec-small.slick-current img {
  opacity: 1;
}

.product-small-img-style .slick-list .product-dec-small img {
  width: 100%;
  opacity: 0.4;
}

span.pro-dec-icon {
  color: #262626;
  font-size: 25px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}

span.pro-dec-icon:hover {
  color: #a38b5e;
}

span.pro-dec-icon i {
  line-height: 1;
  display: inline-block;
}

span.pro-dec-prev {
  left: 22%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  span.pro-dec-prev {
    left: 7%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  span.pro-dec-prev {
    left: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  span.pro-dec-prev {
    left: 14%;
  }
}

@media only screen and (max-width: 767px) {
  span.pro-dec-prev {
    left: -8px;
  }
}

span.pro-dec-next {
  left: auto;
  right: 22%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  span.pro-dec-next {
    right: 7%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  span.pro-dec-next {
    right: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  span.pro-dec-next {
    right: 14%;
  }
}

@media only screen and (max-width: 767px) {
  span.pro-dec-next {
    right: -8px;
  }
}

.pro-dec-icon2 span.pro-dec-prev {
  left: 16%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pro-dec-icon2 span.pro-dec-prev {
    left: 2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-dec-icon2 span.pro-dec-prev {
    left: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-dec-icon2 span.pro-dec-prev {
    left: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-icon2 span.pro-dec-prev {
    left: 14%;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-icon2 span.pro-dec-prev {
    left: -8px;
  }
}

.pro-dec-icon2 span.pro-dec-next {
  left: auto;
  right: 16%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pro-dec-icon2 span.pro-dec-next {
    right: 2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-dec-icon2 span.pro-dec-next {
    right: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-dec-icon2 span.pro-dec-next {
    right: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-icon2 span.pro-dec-next {
    right: 14%;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-icon2 span.pro-dec-next {
    right: -8px;
  }
}

.description-review-wrapper {
  margin: 42px 0 29px;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a {
  display: block;
  position: relative;
  text-transform: capitalize;
  border-bottom: 1px solid #D8D8D8;
  padding: 16px 0 17px;
  color: #a38b5e;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a::after {
  content: "\f068";
  color: #262626;
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 19px;
  font-size: 11px;
  font-weight: normal;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed {
  color: #262626;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed:hover {
  color: #a38b5e;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed::after {
  content: "\f067";
}

.description-review-wrapper .pro-dec-accordion .product-description-wrapper .panel-body {
  padding: 23px 0 16px;
}

.description-review-wrapper .pro-dec-accordion .product-description-wrapper .panel-body p {
  color: #989898;
  margin: 0;
}

.description-review-wrapper .pro-dec-accordion .pro-additional-info .panel-body {
  padding: 23px 0 16px;
}

.description-review-wrapper .pro-dec-accordion .pro-additional-info .panel-body ul li {
  color: #989898;
}

.description-review-wrapper .pro-dec-accordion .pro-additional-info .panel-body ul li a {
  color: #989898;
}

.description-review-wrapper .pro-dec-accordion .pro-additional-info .panel-body ul li i {
  color: #f5b223;
  margin: 0 5px 0 0;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body {
  padding: 23px 0 16px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper > h6 {
  font-weight: 600;
  margin: 0 0 29px;
  color: #262626;
  font-size: 14px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .review-comments p {
  color: #989898;
  margin: 0;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .review-comments h6 {
  color: #262626;
  font-weight: 600;
  font-size: 14px;
  margin: 5px 0 18px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper > span {
  font-size: 12px;
  color: #989898;
  display: block;
  line-height: 1;
  margin: 0 0 9px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper > p {
  color: #989898;
  font-size: 12px;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper > p span {
  color: red;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .star-box-wrap {
  display: flex;
  margin: 27px 0 18px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .star-box-wrap {
    display: block;
  }
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .star-box-wrap .single-ratting-star {
  margin-right: 20px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .star-box-wrap .single-ratting-star i {
  color: #989898;
  font-size: 14px;
  transition: all .3s ease 0s;
  cursor: pointer;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form {
    margin-right: 0px;
  }
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style label {
  color: #989898;
  text-transform: capitalize;
  margin: 0 0 8px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style label span {
  color: red;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style input {
  border: 1px solid #e8e8e8;
  background: transparent;
  height: 45px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style input:focus {
  border: 1px solid #343538;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  border: 1px solid #e7e7e7;
  background: transparent;
  height: 200px;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .rating-form-style textarea:focus {
  border: 1px solid #343538;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .form-submit input {
  padding: 10px 40px;
  text-transform: uppercase;
  width: auto;
  color: #fff;
  background-color: #dcb86c;
  border: none;
}

.description-review-wrapper .pro-dec-accordion .description-review .panel-body .ratting-form-wrapper .ratting-form form .form-submit input:hover {
  background-color: #343538;
}

.pro-details-group {
  margin: 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .pro-details-group {
    margin: 15px 0 0;
  }
}

.pro-details-group .single-group {
  display: flex;
  margin-bottom: 20px;
}

.pro-details-group .single-group .pro-dec-title-stock {
  margin: 5px 0 0 25px;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 {
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 5px;
  color: #262626;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 a {
  color: #262626;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 a:hover {
  color: #a38b5e;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span {
  color: #989898;
  font-size: 14px;
}

.cart-plus-minus {
  display: inline-block;
  height: 60px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 80px;
}

.cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #343538;
  float: left;
  font-size: 14px;
  height: 60px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 80px;
  border: 1px solid #e8e8e8;
}

.cart-plus-minus input.cart-plus-minus-box:focus {
  border: 1px solid #343538;
}

.cart-plus-minus .qtybutton {
  color: #343538;
  cursor: pointer;
  float: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.cart-plus-minus .qtybutton.dec {
  height: 60px;
  left: 0;
  padding-top: 20px;
  top: 0;
}

.cart-plus-minus .qtybutton.inc {
  height: 60px;
  padding-top: 20px;
  right: 0;
  top: 0;
}

.pro-details-size {
  margin-top: 17px;
}

.pro-details-size span {
  color: #262626;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 8px;
  display: block;
}

.pro-details-size ul {
  display: flex;
}

.pro-details-size ul li {
  margin-right: 7px;
}

.pro-details-size ul li:last-child {
  margin-right: 0;
}

.pro-details-size ul li a {
  font-size: 14px;
  color: #262626;
  display: inline-block;
  border: 1px solid #e8e8e8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.pro-details-size ul li a:hover {
  border: 1px solid #a38b5e;
  color: #a38b5e;
}

.pro-details-color {
  margin: 35px 0 0px;
}

.pro-details-color span {
  color: #262626;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 4px;
  display: block;
}

.pro-details-color ul {
  display: flex;
}

.pro-details-color ul li {
  margin-right: 10px;
  position: relative;
}

.pro-details-color ul li:before {
  position: absolute;
  left: -3px;
  top: -3px;
  border: 1px solid #e8e8e8;
  width: 46px;
  height: 46px;
  content: "";
  transition: all 0.3s ease 0s;
  pointer-events: none;
}

.pro-details-color ul li a {
  display: block;
}

.pro-details-color ul li:hover:before {
  border: 1px solid #dcb86c;
}

.product-dec-left {
  width: 160px;
  float: left;
  display: block;
  margin-top: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-left {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-left {
    width: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left {
    width: 100px;
  }
}

.product-dec-left.product-small-img-style .slick-list {
  width: auto;
}

.product-dec-left.product-small-img-style .slick-list .product-dec-small {
  margin-bottom: 8px;
}

.product-dec-right {
  width: calc(100% - 160px);
  padding-left: 8px;
  float: left;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-right {
    width: calc(100% - 130px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-right {
    width: calc(100% - 130px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 60px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

.pro-dec-icon-2 {
  color: #262626;
  cursor: pointer;
  display: inline-block;
  font-size: 25px;
  left: 50%;
  line-height: 1;
  padding: 0 10px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 99;
}

.pro-dec-icon-2 i {
  line-height: 1;
}

.pro-dec-icon-2.pro-dec-prev-2 {
  top: -31px;
  line-height: 1;
}

.pro-dec-icon-2.pro-dec-next-2 {
  bottom: -31px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-icon-2.pro-dec-next-2 {
    bottom: -22px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-icon-2.pro-dec-next-2 {
    bottom: -22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-dec-icon-2.pro-dec-next-2 {
    bottom: -22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-area.mt-175 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-area.mt-175 {
    margin-top: 0;
  }
  .related-product-area.pb-45 {
    padding-bottom: 15px;
  }
}

/*-------- 18. Compare style ---------*/
.compare-table .table tbody tr th.first-column {
  min-width: 205px;
  color: #4a4141;
  margin: 0;
  background-color: #f2f2f2;
  font-weight: 400;
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  padding-top: 122px;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 10px 15px;
  vertical-align: middle;
  border-color: #e7e7e7;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 9px 10px 17px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 13px;
  color: #989898;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 2px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #a38b5e;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 2px 0 6px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: auto;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #a38b5e;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #a38b5e;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 14px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #343538;
  padding: 5px 13px 6px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #a38b5e;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 13px;
  line-height: 1.88;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #343538;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-weight {
  font-size: 13px;
  font-weight: 400;
  color: #989898;
}

.compare-table .table tbody tr td.pro-stock p {
  font-size: 13px;
  font-weight: 400;
  background-color: #35d56a;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  padding: 4px 12px 6px;
  line-height: 1;
}

.compare-table .table tbody tr td.pro-dimensions {
  font-size: 13px;
  font-weight: 400;
  color: #989898;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 19. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table {
  width: 100%;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 11px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0 10px 0;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 10px 0px 20px;
  }
}

.cart-table-content table tbody > tr {
  border-bottom: 1px solid #E7E7E7;
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 30px 0px 30px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 30px 12px 30px 12px;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 30px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #989898;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-img {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 340px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 14px;
  display: block;
  margin: 0 0 5px;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #a38b5e;
}

.cart-table-content table tbody > tr td.product-name span {
  color: #989898;
  font-size: 12px;
  display: block;
  line-height: 1;
}

.cart-table-content table tbody > tr td.product-price {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #262626;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 110px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  color: #262626;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #a38b5e;
}

.cart-shiping-update-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 23px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 40%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 30px;
  font-size: 14px;
  color: #989898;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0 0 3px;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #989898;
}

.cart-shiping-update-wrapper .discount-code button:hover {
  color: #262626;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
  color: #989898;
}

.cart-shiping-update-wrapper .cart-clear a:hover {
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 30px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap > h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  margin: 24px 0 6px;
}

.grand-total-wrap .grand-total-content .single-grand-total {
  display: flex;
  margin: 0 0 20px;
}

.grand-total-wrap .grand-total-content .single-grand-total:last-child {
  margin: 0 0 0;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-left {
  margin-right: 34px;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > span {
  color: #262626;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li {
  display: flex;
  font-size: 12px;
  color: #262626;
  margin: 0 0 5px;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li:last-child {
  margin: 0 0 0;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li input {
  width: auto;
  height: auto;
  margin-right: 15px;
}

.grand-total-wrap > a {
  color: #262626;
}

.grand-total-wrap .cart-total-wrap {
  display: flex;
  border-top: 1px solid #DBDBDB;
  padding: 20px 0 0 0;
  margin: 20px 0 37px 0;
}

.grand-total-wrap .cart-total-wrap .single-cart-total-left {
  margin-right: 34px;
}

.grand-total-wrap .cart-total-wrap .single-cart-total-right span {
  color: #262626;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #a38b5e;
}

/*-------- 20. Wishlist style ---------*/
@media only screen and (max-width: 767px) {
  .wishlist-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

.wishlist-table-content table {
  width: 100%;
}

.wishlist-table-content table thead {
  background-color: #f7f7f7;
}

.wishlist-table-content table thead > tr th {
  border-top: medium none;
  font-size: 14px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 400;
  text-transform: capitalize;
  padding: 13px 0 12px 0;
}

.wishlist-table-content table thead > tr th.wishlist-cart-none {
  width: 100px;
}

.wishlist-table-content table thead > tr th.wishlist-cart-none span {
  display: none;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table thead > tr th {
    padding: 13px 10px 12px;
  }
}

.wishlist-table-content table tbody > tr {
  border-bottom: 1px solid #E7E7E7;
}

.wishlist-table-content table tbody > tr td {
  font-size: 14px;
  padding: 30px 0px 30px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td {
    padding: 30px 12px 30px 0;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-remove {
  width: 30px;
}

.wishlist-table-content table tbody > tr td.wishlist-remove a {
  color: #989898;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.wishlist-img {
  width: 90px;
}

.wishlist-table-content table tbody > tr td.wishlist-name {
  width: 410px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-name {
    width: 200px;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 0;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-name a {
  font-size: 14px;
  display: block;
  margin: 0 0 5px;
}

.wishlist-table-content table tbody > tr td.wishlist-name a:hover {
  color: #a38b5e;
}

.wishlist-table-content table tbody > tr td.wishlist-stock {
  width: 265px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-stock {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-stock {
    width: 100px;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-stock span {
  font-size: 13px;
  font-weight: 400;
  background-color: #35d56a;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  padding: 4px 12px 6px;
  line-height: 1;
}

.wishlist-table-content table tbody > tr td.wishlist-price {
  width: 90px;
}

.wishlist-table-content table tbody > tr td.wishlist-price span {
  color: #262626;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.wishlist-cart > a {
  display: inline-block;
  border: 1px solid #e7e7e7;
  line-height: 1;
  padding: 13px 20px;
  color: #989898;
  font-size: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    width: 100px;
    padding: 10px 12px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.wishlist-cart > a {
    width: 94px;
    padding: 10px 10px;
  }
}

.wishlist-table-content table tbody > tr td.wishlist-cart > a:hover {
  color: #a38b5e;
  border: 1px solid #a38b5e;
}

/*-------- 21. Order tracking style ---------*/
.order-tracking-content p {
  line-height: 1.714286;
  margin: 0;
  text-align: center;
  width: 98%;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 30px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  color: #262626;
  margin: 0 0 10px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #e7e7e7;
  height: 70px;
  padding: 10px 20px;
  color: #989898;
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 18px 50px;
  text-transform: uppercase;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #a38b5e;
}

/*-------- 22. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
  background: #f7f7f7;
  margin-right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .myaccount-tab-menu {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-tab-menu {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu {
    margin-right: 0px;
  }
}

.myaccount-tab-menu a {
  border-bottom: none;
  font-size: 14px;
  display: block;
  padding: 10px 30px;
  text-transform: capitalize;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #a38b5e;
  border-color: #a38b5e;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
  margin-left: -80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .myaccount-content {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
    margin-left: 0px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}

.myaccount-content .welcome a:hover {
  color: #a38b5e;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #a38b5e;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #a38b5e;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #a38b5e;
  font-size: 14px;
  padding: 20px 0;
}

/*-------- End My Account Page --------*/
/*-------- 23. Login register style ---------*/
.login-register-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.login-register-wrap h3 i {
  margin-right: 3px;
}

.login-register-wrap .login-register-form {
  margin: 47px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form {
    margin: 10px 0 0;
  }
}

.login-register-wrap .login-register-form .sin-login-register {
  margin: 0 0 16px;
}

.login-register-wrap .login-register-form .sin-login-register label {
  font-size: 12px;
  color: #262626;
}

.login-register-wrap .login-register-form .sin-login-register label span {
  color: red;
}

.login-register-wrap .login-register-form .sin-login-register input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 5px 10px;
}

.login-register-wrap .login-register-form .sin-login-register input:focus {
  border: 1px solid #262626;
}

.login-register-wrap .login-register-form .login-register-btn-remember {
  display: flex;
  align-items: center;
  margin: 0 0 17px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form .login-register-btn-remember {
    margin: 0 0 10px;
  }
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember {
  margin-left: 25px;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember input {
  width: 13px;
  height: auto;
}

.login-register-wrap .login-register-form .login-register-btn-remember .login-register-remember label {
  line-height: 1;
  margin: 0;
  font-size: 12px;
}

.login-register-wrap .login-register-form .lost-password > a {
  font-size: 12px;
  color: #a38b5e;
}

.login-register-wrap .login-register-form .lost-password > a:hover {
  text-decoration: underline;
}

.login-register-wrap .login-register-form p {
  color: #989898;
  font-size: 12px;
  margin: 0 0 16px;
}

.login-register-wrap .login-register-form p a {
  color: #a38b5e;
}

.login-register-wrap .login-register-form p a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrap.mr-70 {
    margin-right: 0;
  }
  .login-register-wrap.register-wrap {
    margin-top: 30px;
  }
}

.login-register-btn button {
  border: none;
  background-color: #262626;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 18px 40px 18px;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .login-register-btn button {
    padding: 14px 30px 17px;
  }
}

.login-register-btn button:hover {
  background-color: #a38b5e;
}

.vendor-customar-wrap {
  margin: 0 0 23px;
}

.vendor-customar-wrap label {
  display: flex;
  margin: 0 0 10px;
  color: #262626;
  font-size: 12px;
  line-height: 1;
}

.vendor-customar-wrap label input {
  width: auto;
  height: auto;
  margin-right: 5px;
}

.vendor-customar-active {
  display: none;
}

/*-------- 24. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  color: #262626;
}

.customer-zone > p a {
  color: #262626;
}

.customer-zone > p::before {
  content: "\f05a";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  margin: 30px 0 0;
  display: none;
}

.checkout-login-info p {
  color: #989898;
  font-size: 14px;
  margin: 0 0 15px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  color: #262626;
  font-size: 14px;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  color: #8a8a8a;
  border: 1px solid #aaa;
  padding: 8px 30px 10px;
  display: inline-block;
  float: left;
  margin-right: 24px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  color: #262626;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 35px;
}

.checkout-login-info form .lost-password a {
  color: #a38b5e;
}

.checkout-login-info form .lost-password a:hover {
  text-decoration: underline;
}

.checkout-login-info-2 {
  display: none;
  margin-top: 30px;
  margin-bottom: 55px;
}

.checkout-login-info-2 p {
  color: #989898;
  margin: 0 0 22px;
}

.checkout-login-info-2 form input[type="text"] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 48%;
  color: #989898;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info-2 form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-login-info-2 form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 45px;
  padding: 5px 30px 7px;
  text-transform: uppercase;
  font-weight: 700;
  width: inherit;
  font-size: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info-2 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info-2 form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-login-info-2 form input[type="submit"]:hover {
  background-color: #a38b5e;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 60px;
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -15px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
  color: #262626;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 16px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #8a8a8a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #4a4141;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e7e7e7;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #262626;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e7e7e7;
  padding: 16px 0 17px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle {
  display: flex;
  justify-content: space-between;
  margin: 0 0 22px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle:last-child {
  margin: 0 0 0px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content h5 {
  margin: 0;
  color: #262626;
  font-size: 14px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content h5 > span {
  color: #989898;
  font-size: 12px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content > span {
  color: #989898;
  font-size: 12px;
  display: block;
  line-height: 1;
  margin: 8px 0 0;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-price span {
  color: #232323;
}

.your-order-area .your-order-wrap .checkout-shipping-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 13px;
  padding-bottom: 7px;
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-left {
  margin-right: 34px;
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-right ul li {
  display: flex;
  color: #262626;
  margin: 0 0 5px;
  text-transform: uppercase;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-right ul li {
    font-size: 12px;
  }
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-right ul li input {
  width: auto;
  height: auto;
  margin-right: 5px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 17px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  color: #262626;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 12px;
  margin: 0;
  color: #989898;
  font-family: "Montserrat", sans-serif;
}

.your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 input {
  margin-top: 18px;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 30px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}

.your-order-area .your-order-wrap .condition-wrap p a:hover {
  color: #a38b5e;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.your-order-area .Place-order a:hover {
  background-color: #a38b5e;
}

.checkout-account input {
  border: 1px solid #e7e7e7;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.checkout-account span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 12px;
}

.checkout-account-toggle {
  display: none;
  margin-top: 25px;
}

.checkout-account-toggle label {
  color: #262626;
  display: block;
  margin: 0 0 5px;
}

.checkout-account-toggle label abbr {
  color: red;
}

.checkout-account-toggle input {
  border: 1px solid #e7e7e7;
  height: 50px;
  background-color: transparent;
  color: #989898;
  padding: 2px 20px;
}

.checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.different-address {
  display: none;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 25. About us style ---------*/
.about-us-img img, .skill-img img {
  width: 100%;
}

.about-us-content {
  margin: 0 0 0 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    margin: 0 0 0 0px;
  }
}

.about-us-content h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h2 {
    font-size: 22px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content h2 {
    font-size: 19px;
    margin: 20px 0 10px;
  }
}

.about-us-content p {
  color: #989898;
  font-size: 12px;
  margin: 0 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    margin: 0 0 15px;
  }
}

.about-us-content p:last-child {
  margin: 0 0 0px;
}

.about-us-content .about-us-list {
  margin: 0 0 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content .about-us-list {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content .about-us-list {
    margin: 0 0 12px;
  }
}

.about-us-content .about-us-list ul li {
  position: relative;
  color: #989898;
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 15px 15px;
}

.about-us-content .about-us-list ul li:last-child {
  margin: 0 0 0px 15px;
}

.about-us-content .about-us-list ul li::before {
  content: '\f111';
  font-family: FontAwesome;
  font-size: 5px;
  text-rendering: auto;
  vertical-align: middle;
  position: absolute;
  left: -15px;
  top: 1.6em;
}

.skill-content {
  margin-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-content {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content {
    margin-right: 0px;
  }
}

.skill-content h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content h2 {
    font-size: 22px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content h2 {
    font-size: 19px;
    margin: 0px 0 10px;
  }
}

.skill-content p {
  color: #989898;
  font-size: 12px;
  margin: 0;
}

.skill-bar {
  overflow: hidden;
  padding-top: 11px;
  margin-top: 20px;
}

.skill-bar .skill-bar-item {
  padding-bottom: 30px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar .skill-bar-item > span {
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  z-index: 2;
}

.skill-bar .skill-bar-item .progress {
  background-color: #EFEFEF;
  border-radius: 0;
  box-shadow: none;
  height: 5px;
  margin-top: 9px;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar {
  background-color: #a38b5e;
  box-shadow: none;
  position: relative;
}

.skill-bar .skill-bar-item .progress .progress-bar span.text-top {
  color: #4a4141;
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  top: -34px;
}

.skill-bar .skill-bar-item:nth-child(1) .progress-bar {
  width: 95%;
}

.skill-bar .skill-bar-item:nth-child(2) .progress-bar {
  width: 85%;
}

.skill-bar .skill-bar-item:nth-child(3) .progress-bar {
  width: 80%;
}

.skill-bar .skill-bar-item:nth-child(4) .progress-bar {
  width: 99%;
}

.skill-bar.skill-bar-width .skill-bar-item > span {
  color: #989898;
}

.skill-bar.skill-bar-width .skill-bar-item .progress .progress-bar span.text-top {
  color: #989898;
}

.skill-bar.skill-bar-width .skill-bar-item:nth-child(1) .progress-bar {
  width: 90%;
}

.skill-bar.skill-bar-width .skill-bar-item:nth-child(2) .progress-bar {
  width: 80%;
}

.skill-bar.skill-bar-width .skill-bar-item:nth-child(3) .progress-bar {
  width: 70%;
}

.skill-bar.skill-bar-gradient {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar.skill-bar-gradient .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar.skill-bar-gradient .skill-bar-item .progress .progress-bar {
  background-image: linear-gradient(to right, #e175a3 0%, #3a51e6 100%);
}

.skill-bar.skill-bar-black {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar.skill-bar-black .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar.skill-bar-black .skill-bar-item .progress .progress-bar {
  background-color: #4a4141;
}

.skill-bar.skill-bar-red {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar.skill-bar-red .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar.skill-bar-red .skill-bar-item .progress .progress-bar {
  background-color: #f4524d;
}

.skill-bar.skill-bar-red-light {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-bar.skill-bar-red-light .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar.skill-bar-red-light .skill-bar-item .progress .progress-bar {
  background-color: #b97ebb;
}

.talk-us-content a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  display: block;
  background-color: #262626;
  padding: 55px 0 55px;
}

@media only screen and (max-width: 767px) {
  .talk-us-content a {
    padding: 35px 0 35px;
    font-size: 25px;
  }
}

.talk-us-content a:hover {
  background-color: #a38b5e;
}

.team-details-area {
  position: relative;
}

.team-details-area .team-details-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-area .team-details-img {
    position: static;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-area .team-details-img {
    position: static;
    width: 100%;
  }
}

.team-details-area .team-details-img img {
  height: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.team-details-content {
  padding: 82px 276px 84px 75px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .team-details-content {
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .team-details-content {
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-details-content {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-content {
    padding: 42px 40px 45px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-content {
    padding: 32px 15px 35px 15px;
  }
}

.team-details-content h2 {
  font-size: 30px;
  margin: 0 0 5px;
  font-weight: bold;
}

.team-details-content > span {
  font-size: 18px;
  font-style: italic;
  font-family: "Playfair Display", serif;
  display: block;
  margin: 0 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .team-details-content > span {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-details-content > span {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-content > span {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-content > span {
    margin: 0 0 20px;
  }
}

.team-details-content p {
  font-size: 12px;
  color: #989898;
  margin: 0 0 20px;
}

.team-details-social {
  margin-top: 56px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .team-details-social {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .team-details-social {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-details-social {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-social {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-social {
    margin-top: 0px;
  }
}

.team-details-social a {
  font-size: 16px;
  margin-right: 15px;
}

.team-details-social a:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .about-us-skill.pb-85 {
    padding-bottom: 42px;
  }
}

/*-------- 26. Team style ---------*/
.team-wrap {
  position: relative;
}

.team-wrap:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 9;
  transition: all 0.4s 0.1s;
  opacity: 0;
  visibility: hidden;
  background-color: #212121;
  pointer-events: none;
}

.team-wrap > a {
  display: block;
}

.team-wrap > a img {
  width: 100%;
}

.team-wrap .team-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
}

.team-wrap .team-content h3 {
  font-weight: bold;
  font-size: 22px;
  color: #fff;
  margin: 0 0 0;
  line-height: 1;
}

.team-wrap .team-content h3 a {
  color: #fff;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s 0.1s;
  transform: translate(0, 20px);
}

.team-wrap .team-content span {
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-family: "Playfair Display", serif;
  margin: 12px 0 18px;
  display: block;
  transition-delay: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 20px);
}

.team-wrap .team-content .team-social a {
  display: inline-block;
  color: #fff;
  margin: 0 8px;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.5s;
  transform: translate(0, 20px);
}

.team-wrap .team-content .team-social a:hover {
  color: #a38b5e;
}

.team-wrap:hover:before {
  visibility: visible;
  opacity: .8;
}

.team-wrap:hover .team-content h3 a {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.team-wrap:hover .team-content span {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.team-wrap:hover .team-content .team-social a {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

/*-------- 27. Contact us style ---------*/
.contact-title {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .contact-title {
    margin-bottom: 30px;
  }
}

.contact-title h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  line-height: 17px;
}

.get-in-touch-wrap {
  margin-right: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .get-in-touch-wrap {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .get-in-touch-wrap {
    margin-right: 0px;
    margin-bottom: 50px;
  }
}

.get-in-touch-wrap .contact-from input, .get-in-touch-wrap .contact-from textarea {
  border: 1px solid #e7e7e7;
  background-color: transparent;
  height: 60px;
  margin-bottom: 20px;
  padding: 5px 25px;
  color: #989898;
}

.get-in-touch-wrap .contact-from input:focus, .get-in-touch-wrap .contact-from textarea:focus {
  border: 1px solid #262626;
}

.get-in-touch-wrap .contact-from textarea {
  height: 179px;
  padding: 20px 25px;
}

.get-in-touch-wrap .contact-from button.submit {
  border: none;
  background-color: #262626;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  padding: 20px 40px;
}

.get-in-touch-wrap .contact-from button.submit:hover {
  background-color: #a38b5e;
}

.contact-info-bottom .single-contact-info {
  margin: 0 0 41px;
}

@media only screen and (max-width: 767px) {
  .contact-info-bottom .single-contact-info {
    margin: 0 0 25px;
  }
}

.contact-info-bottom .single-contact-info h5 {
  font-weight: bold;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .contact-info-bottom .single-contact-info h5 {
    margin: 0 0 10px;
  }
}

.contact-info-bottom .single-contact-info p {
  color: #989898;
  line-height: 1.714286;
  margin: 0;
}

.contact-info-bottom .single-contact-info p a {
  color: #989898;
}

.contact-info-bottom .single-contact-info p a:hover {
  color: #a38b5e;
}

#contact-map {
  height: 270px;
}

/*-------- 28. Elements style ---------*/
.element-typography-wrap .element-typography-content h1, .element-typography-wrap .element-typography-content h2, .element-typography-wrap .element-typography-content h3, .element-typography-wrap .element-typography-content h4, .element-typography-wrap .element-typography-content h5, .element-typography-wrap .element-typography-content h6 {
  font-weight: bold;
  margin: 0 0 16px;
}

.element-typography-wrap .element-typography-content h1 {
  font-size: 48px;
}

.element-typography-wrap .element-typography-content h2 {
  font-size: 36px;
}

.element-typography-wrap .element-typography-content h3 {
  font-size: 24px;
}

.element-typography-wrap .element-typography-content h4 {
  font-size: 20px;
}

.element-typography-wrap .element-typography-content h5 {
  font-size: 18px;
}

.element-typography-wrap .element-typography-content h6 {
  font-size: 16px;
}

.element-typography-wrap p {
  color: #989898;
  line-height: 1.714286;
  margin: 0;
}

.element-section-title h3 {
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 10px;
}

.element-section-title p {
  color: #989898;
  margin: 0;
}

.element-list-icons ul li {
  color: #989898;
  font-size: 14px;
  margin: 0 0 21px;
}

@media only screen and (max-width: 767px) {
  .element-list-icons ul li {
    margin: 0 0 12px;
  }
}

.element-list-icons ul li:last-child {
  margin: 0;
}

.element-list-icons ul li span {
  color: #526df9;
  padding-right: 15px;
}

.element-highlights-wrap {
  padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .element-highlights-wrap {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .element-highlights-wrap {
    padding-right: 0px;
  }
}

.element-highlights-wrap .element-highlights-left {
  display: inline-block;
  float: left;
}

.element-highlights-wrap .element-highlights-left span {
  color: #1ece59;
  font-size: 60px;
  margin: -2px 0 0;
  line-height: 1;
  display: block;
  padding: 0 17px 12px 0;
}

.element-highlights-wrap .element-highlights-right p {
  color: #989898;
  line-height: 1.714286;
  margin: 0;
}

.element-highlights-wrap .element-highlights-right p .highlights-pinck {
  background-color: #ffb1c4;
  color: #fff;
}

.element-highlights-wrap .element-highlights-right p .highlights-purple {
  background-color: #4a0396;
  color: #fff;
}

.element-highlights-wrap .element-highlights-right p .highlights-purple-light {
  background-color: #c3c1df;
  color: #fff;
}

.element-highlights-wrap .element-highlights-right p .highlights-text-blod {
  font-weight: bold;
}

.element-single-highlights {
  padding-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .element-single-highlights {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .element-single-highlights {
    padding-right: 0px;
  }
}

.element-single-highlights p {
  color: #989898;
  line-height: 1.714286;
  margin: 0;
}

.element-single-highlights p .highlights-pinck {
  background-color: #ffb1c4;
  color: #fff;
}

.blockquote-content-one {
  border-left: 2px solid rgba(0, 0, 0, 0.12);
}

.blockquote-content-one p {
  line-height: 1.6;
  color: #333;
  margin: 0;
  font-size: 23px;
  font-family: "Noto Serif", serif;
  padding: 12px 24px;
}

@media only screen and (max-width: 767px) {
  .blockquote-content-one p {
    font-size: 20px;
  }
}

.blockquote-content-two {
  padding: 40px 30px;
  border: 1px solid #e7e7e7;
  position: relative;
}

.blockquote-content-two::before {
  content: '';
  background-image: url("../images/icon-img/quote2.png");
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center center;
  width: 40px;
  height: 33px;
  position: absolute;
  left: 25px;
  top: -15px;
}

.blockquote-content-two p {
  font-size: 20px;
  font-style: italic;
  line-height: 36px;
  font-family: "Playfair Display", serif;
  color: #262626;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .blockquote-content-two p {
    font-size: 17px;
    line-height: 30px;
  }
}

.blockquote-content-two a {
  font-size: 12px;
  color: #262626;
}

.blockquote-content-three {
  border-left: 2px solid #eeeeee;
  padding: 0 30px;
}

.blockquote-content-three p {
  color: #262626;
  font-family: "Playfair Display", serif;
  margin: 0 0 14px;
}

.blockquote-content-three a {
  font-weight: 600;
  color: #262626;
  font-size: 12px;
  font-style: italic;
}

.dropcaps-content {
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dropcaps-content {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropcaps-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .dropcaps-content {
    padding-left: 0px;
  }
}

.dropcaps-content p {
  color: #989898;
  margin: 0 0 16px;
  line-height: 1.714286;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left {
  display: inline-block;
  float: left;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span {
  line-height: 1;
  font-size: 60px;
  margin: -3px 14px 0 0;
  display: inline-block;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.black {
  color: #000000;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.blue {
  color: #362ab7;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.purple {
  color: #8412e2;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.red {
  color: #b20e3a;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.green-light {
  color: #1de50b;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.green {
  color: #008a93;
}

.dropcaps-content .dropcaps-wrap .dropcaps-left span.yellow {
  color: #dadd00;
}

.dropcaps-content .dropcaps-wrap .dropcaps-right p {
  color: #989898;
  margin: 0;
  line-height: 1.714286;
}

.timer-style-1 div {
  display: flex;
  flex-wrap: wrap;
}

.timer-style-1 div > span {
  display: flex;
  justify-content: center;
}

.timer-style-1 div > span > span {
  color: #a38b5e;
  font-size: 2.25em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1 div > span > span {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1 div > span > span {
    margin: 0 0 30px;
  }
}

.timer-style-1 div > span > p {
  margin: 6px 0 0 5px;
  color: #262626;
  font-size: 15px;
}

.timer-style-1.timer-width-1 div > span {
  flex: 0 0 14.28571%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1.timer-width-1 div > span {
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1.timer-width-1 div > span {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-1.timer-width-1 div > span {
    flex: 0 0 25%;
  }
}

.timer-style-1.timer-width-3 div > span {
  flex: 0 0 25%;
}

@media only screen and (max-width: 767px) {
  .timer-style-1.timer-width-3 div > span {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-1.timer-width-3 div > span {
    flex: 0 0 50%;
  }
}

.timer-style-2 div {
  display: flex;
  flex-wrap: wrap;
}

.timer-style-2 div > span {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2 div > span {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2 div > span {
    margin: 0 0 30px;
  }
}

.timer-style-2 div > span > span {
  font-size: 18px;
  font-weight: bold;
}

.timer-style-2 div > span > p {
  margin: -1px 0 0;
  color: #262626;
  font-size: 12px;
  line-height: 1;
}

.timer-style-2.timer-width-2 div > span {
  width: 14.28571%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2.timer-width-2 div > span {
    width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2.timer-width-2 div > span {
    width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-2.timer-width-2 div > span {
    width: 25%;
  }
}

.timer-style-2.timer-width-4 div > span {
  width: 25%;
}

@media only screen and (max-width: 767px) {
  .timer-style-2.timer-width-4 div > span {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-2.timer-width-4 div > span {
    flex: 0 0 50%;
  }
}

.single-count .count-icon-bg span {
  background-color: #a38b5e;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 24px;
  border-radius: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-count .count-icon-bg span {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .single-count .count-icon-bg span {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }
}

.single-count .count-icon-center span {
  color: #a38b5e;
  font-size: 30px;
}

.single-count .count-title h2 {
  color: #232324;
  font-size: 48px;
  margin: 5px 0 7px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-count .count-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .single-count .count-title h2 {
    font-size: 35px;
  }
}

.single-count .count-title span {
  color: #232324;
  font-size: 16px;
  line-height: 1;
}

.single-count-2 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .single-count-2 {
    justify-content: center;
  }
}

.single-count-2 .count-icon-left {
  margin-right: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-count-2 .count-icon-left {
    margin-right: 15px;
  }
}

.single-count-2 .count-icon-left span {
  color: #a38b5e;
  font-size: 30px;
}

.single-count-2 .count-title-2 h2 {
  color: #232324;
  font-size: 24px;
  margin: 0 0 7px;
}

.single-count-2 .count-title-2 span {
  color: #232324;
  font-size: 16px;
  line-height: 1;
}

.single-info-box .info-box-simple {
  margin-bottom: 18px;
}

.single-info-box .info-box-simple i {
  color: #a38b5e;
  font-size: 30px;
}

.single-info-box .info-box-round {
  margin: 0 0 23px;
}

.single-info-box .info-box-round i {
  font-size: 24px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  border-radius: 100%;
  background-color: #a38b5e;
  transition: all  .3s ease 0s;
}

.single-info-box .info-box-square {
  margin: 0 0 23px;
}

.single-info-box .info-box-square i {
  font-size: 24px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  background-color: #a38b5e;
  transition: all  .3s ease 0s;
}

.single-info-box .info-box-content h3 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 9px;
  line-height: 1;
}

.single-info-box .info-box-content span {
  color: #989898;
}

.single-info-box:hover .info-box-round i, .single-info-box:hover .info-box-square i {
  background-color: #de92c2;
}

.single-info-box-2 {
  display: flex;
}

.single-info-box-2 .info-box-simple-2 i {
  color: #a38b5e;
  font-size: 30px;
}

.single-info-box-2 .info-box-round-2 i {
  font-size: 18px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 100%;
  background-color: #a38b5e;
  transition: all  .3s ease 0s;
}

.single-info-box-2 .info-box-square-2 i {
  font-size: 18px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #a38b5e;
  transition: all  .3s ease 0s;
}

.single-info-box-2 .info-box-content-2 h3 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 9px;
  line-height: 1;
}

.single-info-box-2 .info-box-content-2 span {
  color: #989898;
}

.single-info-box-2:hover .info-box-round-2 i, .single-info-box-2:hover .info-box-square-2 i {
  background-color: #de92c2;
}

.single-price-box {
  border: 1px solid #e7e7e7;
  padding: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box {
    padding: 30px 15px;
  }
}

.single-price-box .price-box-top h3 {
  color: #232324;
  font-family: "Vidaloka", serif;
  font-size: 18px;
  line-height: 1;
  margin: 0;
}

.single-price-box .price-box-top h2 {
  color: #e9595e;
  font-size: 55px;
  font-weight: 700;
  line-height: 53px;
  margin: 11px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box .price-box-top h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .single-price-box .price-box-top h2 {
    font-size: 45px;
  }
}

.single-price-box .price-box-middle {
  margin: 36px 0 40px;
}

.single-price-box .price-box-middle ul li {
  color: #232324;
  margin: 0 0 14px;
}

.single-price-box .price-box-middle ul li:last-child {
  margin: 0 0 0px;
}

.single-price-box .price-box-bottom a {
  background-color: #262626;
  display: block;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  padding: 20px 10px 20px;
}

.single-price-box .price-box-bottom a:hover {
  background-color: #a38b5e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box .price-box-bottom a {
    padding: 20px 7px 20px;
  }
}

.single-price-box:hover .price-box-bottom a {
  background-color: #a38b5e;
}

.price-box-bg {
  background: -webkit-linear-gradient(left, #F5F7FF 0%, #FDF8FF 100%);
}

.single-price-box-2 {
  background-color: #fff;
  box-shadow: 0px 0px 49px 0px rgba(200, 111, 173, 0.24);
  border-radius: 20px;
  padding: 51px 30px 50px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box-2 {
    padding: 51px 15px 50px;
  }
}

.single-price-box-2 .price-badge {
  background-color: #ff4343;
  position: absolute;
  top: 0;
  right: 30px;
  padding: 20px 10px;
}

.single-price-box-2 .price-badge i {
  color: #fff;
}

.single-price-box-2 .price-box-top-2 h3 {
  color: #cca4b4;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}

.single-price-box-2 .price-box-top-2 h2 {
  color: #262626;
  font-size: 72px;
  line-height: 53px;
  margin: 15px 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box-2 .price-box-top-2 h2 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .single-price-box-2 .price-box-top-2 h2 {
    font-size: 42px;
  }
}

.single-price-box-2 .price-box-top-2 h2 span {
  font-size: 16px;
  display: inline-block;
  line-height: 1;
}

.single-price-box-2 .price-box-middle-2 {
  margin: 51px 0 46px;
}

.single-price-box-2 .price-box-middle-2 ul li {
  color: #989898;
  margin: 0 0 14px;
}

.single-price-box-2 .price-box-middle-2 ul li:last-child {
  margin: 0 0 0px;
}

.single-price-box-2 .price-box-bottom-2 a {
  width: 180px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.single-price-box-2 .price-box-bottom-2 a span {
  font-weight: bold;
  color: #232324;
  position: relative;
  z-index: 2;
  border: 2px solid #e3e3e3;
  background-color: transparent;
  padding: 24px 55px 23px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  transition: all .2s;
}

.single-price-box-2 .price-box-bottom-2 a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform .2s;
  opacity: 0;
  background-color: #e475a1;
  background-image: linear-gradient(to right, #e475a1 0%, #3851e7 100%);
}

.single-price-box-2:hover .price-box-bottom-2 a span {
  background-color: #232324;
  color: #fff;
  border: 2px solid #232324;
}

.single-price-box-2:hover .price-box-bottom-2 a::before {
  transform: translate(5px, 5px);
  opacity: 1;
}

.single-price-box-3 {
  padding: 36px 30px 40px;
  border-radius: 10px;
  transition: all .3s;
  box-shadow: 0px 0px 27px 0px rgba(41, 44, 58, 0.05);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box-3 {
    padding: 36px 15px 40px;
  }
}

.single-price-box-3 .price-box-top-3 i {
  color: #526df9;
  font-size: 46px;
}

.single-price-box-3 .price-box-top-3 h3 {
  color: #262626;
  font-size: 18px;
  font-weight: bold;
  margin: 9px 0 3px;
}

.single-price-box-3 .price-box-top-3 p {
  color: #989898;
  margin: 0;
}

.single-price-box-3 .price-box-top-3 .sub-price-box-top {
  background: url(../images/icon-img/line.png) no-repeat center bottom;
}

.single-price-box-3 .price-box-top-3 .sub-price-box-top h2 {
  color: #526df9;
  font-size: 48px;
  font-weight: bold;
  line-height: 40px;
  margin: 25px 0 0;
  padding: 0 0 28px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-price-box-3 .price-box-top-3 .sub-price-box-top h2 {
    font-size: 41px;
  }
}

@media only screen and (max-width: 767px) {
  .single-price-box-3 .price-box-top-3 .sub-price-box-top h2 {
    font-size: 41px;
  }
}

.single-price-box-3 .price-box-top-3 .sub-price-box-top h2 span {
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  color: #262626;
  font-weight: 400;
}

.single-price-box-3 .price-box-middle-3 {
  margin: 27px 0 29px;
}

.single-price-box-3 .price-box-middle-3 ul li {
  color: #232324;
  margin: 0 0 14px;
}

.single-price-box-3 .price-box-middle-3 ul li:last-child {
  margin: 0 0 0px;
}

.single-price-box-3 .price-box-bottom-3 a {
  color: #232324;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  line-height: 1;
  font-weight: bold;
  padding: 18px 55px 18px;
  border: 2px solid #e3e3e3;
  border-radius: 50px;
}

.single-price-box-3:hover {
  transform: translate(0, -20px);
  box-shadow: 0px 0px 30px 0px rgba(41, 44, 58, 0.1);
}

.single-price-box-3:hover .price-box-bottom-3 a {
  background-color: #232324;
  color: #fff;
  border: 2px solid #232324;
}

.progress-circular {
  overflow: hidden !important;
}

.progress-circular .knob {
  color: #989898 !important;
  font-size: 30px !important;
  font-weight: bold !important;
  margin-top: 70px !important;
  background-color: transparent !important;
}

.progress-circular h4.knob-content {
  color: #989898;
  font-weight: 300;
  font-size: 16px;
  margin: 11px 0 0;
}

.single-testimonial-2 .client-content-2 {
  border: 1px solid #e7e9ec;
  padding: 40px 30px 65px;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .client-content-2 {
    padding: 40px 22px 65px;
  }
}

.single-testimonial-2 .client-content-2 p {
  color: #262626;
  font-size: 18px;
  line-height: 1.56;
  margin: 0;
  font-family: "Playfair Display", serif;
}

.single-testimonial-2 .client-info-2 {
  align-items: center;
  background-color: #fff;
  display: flex;
  float: left;
  margin-left: 32px;
  margin-top: -44px;
  padding-right: 18px;
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .client-info-2 {
    margin-left: 10px;
  }
}

.single-testimonial-2 .client-info-2 h5 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 0 22px 0 10px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .client-info-2 h5 {
    font-size: 14px;
  }
}

.single-testimonial-2 .client-info-2 h5::before {
  background-color: #95999e;
  content: "";
  height: 1px;
  position: absolute;
  right: -15px;
  top: 8px;
  width: 7px;
}

.single-testimonial-2 .client-info-2 span {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-active-2.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-wrap.pb-70 {
    padding-bottom: 50px;
  }
  .block-quote-wrap.pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .element-heading-2.mt-90 {
    margin-top: 30px;
  }
  .element-heading-wrap.mt-40 {
    margin-top: 20px;
  }
  .element-typography-content.mb-40 {
    margin-bottom: 20px;
  }
  .element-heading-area.pb-80 {
    padding-bottom: 30px;
  }
  .element-list-icons-wrap.mb-65 {
    margin-bottom: 20px;
  }
  .element-list-icons-area.pb-65, .element-testimonials-area.pb-65 {
    padding-bottom: 20px;
  }
  .countdown-wrap.pb-70 {
    padding-bottom: 20px;
  }
  .countdown-wrap.pt-70 {
    padding-top: 50px;
  }
  .element-countdown-area.pb-65, .element-info-box-area.pb-65 {
    padding-bottom: 15px;
  }
  .info-box-wrap.pb-35 {
    padding-bottom: 16px;
  }
  .info-box-wrap.pt-70 {
    padding-top: 50px;
  }
  .element-pie-chart-area.pb-70 {
    padding-bottom: 20px;
  }
  .progress-bar-wrap.mb-70 {
    margin-bottom: 20px;
  }
  .block-quote-wrap.pl-35 {
    padding-left: 0px;
  }
}
