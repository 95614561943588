/*-------- 27. Contact us style ---------*/

.contact-title {
    margin-bottom: 50px;
    @media #{$xs-layout}{
        margin-bottom: 30px;
    }
    h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        line-height: 17px;
    }
}

.get-in-touch-wrap {
    margin-right: 100px;
    @media #{$md-layout}{
        margin-right: 0px;
    }
    @media #{$xs-layout}{
        margin-right: 0px;
        margin-bottom: 50px;
    }
    .contact-from {
        input , textarea {
            border: 1px solid #e7e7e7;
            background-color: transparent;
            height: 60px;
            margin-bottom: 20px;
            padding: 5px 25px;
            color: #989898;
            &:focus {
                border: 1px solid #262626;
            }
        }
        textarea {
            height: 179px;
            padding: 20px 25px;
        }
        button.submit {
            border: none;
            background-color: #262626;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            padding: 20px 40px;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
}

.contact-info-bottom {
    .single-contact-info {
        margin: 0 0 41px;
        @media #{$xs-layout}{
            margin: 0 0 25px;
        }
        h5 {
            font-weight: bold;
            margin: 0 0 15px;
            @media #{$xs-layout}{
                margin: 0 0 10px;
            }
        }
        p {
            color: #989898;
            line-height: 1.714286;
            margin: 0;
            a {
               color: #989898;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

#contact-map {
    height: 270px;
}





