/*-----------------------------------------------------------------------------------
    
    Template Name: Mantis - Minimal eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Section title style
    6. Subscribe style
    7. Instagram style
    8. Footer style
    9. Others style
    10. Service style
    11. Animation style
    12. Testimonial style
    13. Video style
    14. Blog style
    15. Breadcrumb style
    16. Shop style
    17. Product details style
    18. Compare style
    19. Cart style
    20. Wishlist style
    21. Order tracking style
    22. My account style
    23. Login register style
    24. Checkout style
    25. About us style
    26. Team style
    27. Contact us style
    28. Elements style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'slider';
@import 'banner';
@import 'product';
@import 'section-title';
@import 'subscribe';
@import 'instagram';
@import 'footer';
@import 'others';
@import 'service';
@import 'animation';
@import 'testimonial';
@import 'video';
@import 'blog';
@import 'breadcrumb';
@import 'shop';
@import 'product-details';
@import 'compare';
@import 'cart';
@import 'wishlist';
@import 'order-tracking';
@import 'my-account';
@import 'login-register';
@import 'checkout';
@import 'about-us';
@import 'team';
@import 'contact-us';
@import 'elements';










