/*-------- 4. Banner style ---------*/

.banner-hover {
    position: relative;
    overflow: hidden;
    &::before {
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 1;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
        transform: skewX(-25deg);
    }
}

.banner-wrap {
    position: relative;
    overflow: hidden;
    .banner-img {
        position: relative;
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
            }
        }
        &::after {
            background-color: transparent;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transition: all .35s;
            pointer-events: none;
        }
    }
    .banner-img-2 {
        a {
            display: block;
            img {
                width: 100%;
            }
        }
    }
    .banner-zoom {
        a {
            img {
                transform: scale(1.05);
                transition: all .35s ease 0s;
            }
        }
    }
    .banner-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        z-index: 9;
        @media #{$xs-layout} {
            padding: 0 10px;
        }
        h3 {
            font-size: 36px;
            margin: 0;
            color: #fff;
            @media #{$xl-layout} {
                font-size: 27px;
            }
            @media #{$lg-layout} {
                font-size: 22px;
            }
            @media #{$md-layout} {
                font-size: 26px;
            }
            @media #{$xs-layout} {
                font-size: 21px;
            }
            @media #{$sm-layout} {
                font-size: 30px;
            }
            a {
                color: #fff;
            }
        }
    }
    .banner-content-2 {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: -30px;
        z-index: 99;
        opacity: 0;
        transition: all .45s ease-out;
        a {
            position: relative;
            display: inline-block;
            color: #4A4141;
            background-color: #fff;
            font-weight: 600;
            padding: 20px 35px;
            @media #{$md-layout} {
                padding: 11px 25px;
            }
            @media #{$xs-layout} {
                padding: 10px 25px;
            }
        }
    }
    .banner-content-3 {
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        @media #{$lg-layout} {
            left: 20px;
        }
        @media #{$md-layout} {
            left: 20px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
        span {
            font-size: 20px;
            color: #fff;
            display: block;
            line-height: 1;
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$md-layout} {
                font-size: 18px;
            }
            @media #{$xs-layout} {
                font-size: 15px;
            }
        }
        h2 {
            font-size: 72px;
            font-weight: 600;
            color: #fff;
            margin: 20px 0 35px;
            line-height: 60px;
            @media #{$lg-layout} {
                font-size: 50px;
                margin: 5px 0 15px;
                line-height: 50px;
            }
            @media #{$md-layout} {
                font-size: 50px;
                margin: 5px 0 15px;
                line-height: 50px;
            }
            @media #{$xs-layout} {
                font-size: 50px;
                margin: 10px 0 15px;
                line-height: 50px;
            }
            a {
                color: #fff;
            }
        }
        .banner-btn {
            a {
                display: inline-block;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                border: 1px solid #fff;
                color: #fff;
                line-height: 1;
                padding: 18px 50px;
                @media #{$lg-layout} {
                    padding: 15px 35px;
                }
                @media #{$md-layout} {
                    padding: 15px 35px;
                }
                @media #{$xs-layout} {
                    padding: 12px 30px;
                }
            }
        }
    }
    .banner-content-5 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        span {
            font-size: 18px;
            color: #fff;
        }
        h2 {
            font-size: 75px;
            font-weight: 700;
            color: #fff;
            margin: 10px 0 19px;
            @media #{$xl-layout} {
                font-size: 50px;
            }
            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 33px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
            a {
                color: #fff;
            }
        }
        .banner-btn-3 {
            a {
                display: inline-block;
                font-size: 12px;
                padding: 15px 45px;
                color: #262626;
                font-weight: 700;
                background-color: #fff;
                letter-spacing: 2px;
                @media #{$xs-layout} {
                    padding: 10px 30px;
                }
            }
        }
    }
    .banner-content-6 {
        position: absolute;
        bottom: 32px;
        left: 45px;
        z-index: 9;
        @media #{$md-layout} {
            left: 30px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
        span {
            font-size: 18px;
            color: #fff;
        }
        h2 {
            font-size: 75px;
            font-weight: 700;
            color: #fff;
            margin: 10px 0 0px;
            @media #{$xl-layout} {
                font-size: 50px;
            }
            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 33px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
            a {
                color: #fff;
            }
        }
    }
    .banner-content-7 {
        position: absolute;
        bottom: 173px;
        left: 125px;
        z-index: 9;
        @media #{$xl-layout} {
            left: 50px;
        }
        @media #{$lg-layout} {
            left: 50px;
        }
        @media #{$md-layout} {
            left: 50px;
            bottom: 50px;
        }
        @media #{$xs-layout} {
            left: 20px;
            bottom: 20px;
        }
        span {
            font-size: 18px;
            color: #fff;
        }
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: #fff;
            margin: 0px 0 0px;
            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 33px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
            a {
                color: #fff;
            }
        }
    }
    .banner-content-8 {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        z-index: 9;
        @media #{$xs-layout} {
            padding: 0 10px;
        }
        h3 {
            color: #fff;
            font-size: 16px;
            margin: 0;
            line-height: 1;
            a {
               color: #fff; 
            }
        }
    }
    .banner-content-9 {
        position: absolute;
        left: 22px;
        bottom: 35px;
        width: 100%;
        z-index: 9;
        @media #{$lg-layout} {
            left: 15px;
            bottom: 15px;
        }
        @media #{$xs-layout} {
            padding: 0 10px;
        }
        span {
            font-size: 18px;
            font-weight: 600;
            display: block;
            line-height: 1;
            color: #fff;
        }
        h3 {
            color: #fff;
            font-size: 64px;
            font-weight: 600;
            margin: 8px 0 0;
            line-height: 1;
            @media #{$xl-layout} {
                font-size: 38px;
            }
            @media #{$lg-layout} {
                font-size: 30px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
            a {
               color: #fff; 
            }
        }
    }
    .banner-position-1 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 84px;
        @media #{$xl-layout} {
            left: 30px;
        }
        @media #{$lg-layout} {
            left: 30px;
        }
        @media #{$md-layout} {
            left: 40px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
    }
    &:hover .banner-img::after {
        background-color: rgba(0,0,0,.2);
    }
    &:hover .banner-hover::before {
        animation: effect_shine 1s;
    }
    &:hover .banner-zoom a img {
        transform: scale(1.01);
    }
    &:hover {
        .banner-content-2 {
            bottom: 30px;
            opacity: 1;
        }
        .banner-btn {
            a {
                background-color: $theme-color;
                border-color: $theme-color;
            }
        }
    }
}

@-webkit-keyframes effect_shine {
  100% {
    left: 150%;
  }
}
@keyframes effect_shine {
  100% {
    left: 150%;
  }
}

.banner-slider-active {
    .owl-nav {
        div {
            color: #858a9f;
            font-size: 30px;
            left: -43px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease 0s;
            @media #{$xl-layout} {
                left: 0px;
            }
            @media #{$lg-layout} {
                left: -18px;
            }
            @media #{$md-layout} {
                left: -18px;
            }
            @media #{$xs-layout} {
                left: 0px;
            }
            @media #{$sm-layout} {
                left: -15px;
            }
            &.owl-next {
                left: auto;
                right: -43px;
                @media #{$xl-layout} {
                    right: 0px;
                }
                @media #{$lg-layout} {
                    right: -18px;
                }
                @media #{$md-layout} {
                    right: -18px;
                }
                @media #{$xs-layout} {
                    right: 0px;
                }
                @media #{$sm-layout} {
                    right: -15px;
                }
            }
        }
    }
}

.product-parallax-img {
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
    padding: 250px 0 230px;
    @media #{$xl-layout} {
        padding: 150px 0 130px;
    }
    @media #{$lg-layout} {
        padding: 100px 0 100px;
    }
    @media #{$md-layout} {
        padding: 100px 0 100px;
    }
    @media #{$xs-layout} {
        padding: 60px 0 60px;
    }
}

.banner-content-4 {
    h3 {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 25px;
        }
    }
    span {
        color: rgb(74, 65, 65);
        font-size: 20px;
        margin: 14px 0 50px;
        @media #{$xs-layout} {
            margin: 14px 0 30px;
        }
        display: block;
    }
    .banner-btn-2 {
        a {
            position: relative;
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            line-height: 1.2;
            color: #262626;
            margin: 0 0 0 23px;
            &::before {
                content: '';
                border-top: 2px solid #262626;
                width: 96px;
                position: absolute;
                left: -23px;
                bottom: 1em;
                transition: all 250ms ease-out;
            }
            &:hover {
                color: $theme-color;
            }
            &:hover::before {
                border-top: 2px solid $theme-color;
                bottom: 0;
            }
        }
    }
    &.banner-negative-mrg {
        margin-left: -15px;
        @media #{$xs-layout} {
            margin-left: 15px;
        }
    }
}

@media #{$md-layout} {
    .banner-area {
        &.mt-110 {
            margin-top: 0;
        }
    }
}

@media #{$xs-layout} {
    .banner-area {
        &.pb-65 {
            padding-bottom: 15px;
        }
        &.mt-110 {
            margin-top: 0;
        }
    }
}











