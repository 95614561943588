/*-------- 7. Instagram style ---------*/

.instagram-wrap {
    h3 {
        font-size: 24px;
        font-weight: 700;
        margin: 0 0 45px;
        text-decoration: underline;
        @media #{$xs-layout} {
            font-size: 20px;
            margin: 0 0 27px;
        }
    }
}
.instagram-item {
    position: relative;
    > a {
        display: block;
        position: relative;
        &::before {
            background-color: rgba(0, 0, 0, 0.4);
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transition: all 0.3s ease 0s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            height: 100%;
            width: 100%;
            z-index: 9;
        }
        img {
            width: 100%;
        }
    }
    > .instagram-hvr-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease 0s;
        z-index: 99;
        span {
            i {
                color: $theme-color;
                margin-right: 3px;
            }
            font-size: 20px;
            margin: 0 5px;
            color: #fff;
            @media #{$xx-layout} {
                font-size: 19px;
            }
            @media #{$xl-layout} {
                font-size: 17px;
            }
            @media #{$lg-layout} {
                font-size: 17px;
            }
        }
    }
    &:hover > a::before {
        opacity: 1;
        visibility: visible;
    }
    &:hover > .instagram-hvr-content {
        opacity: 1;
        visibility: visible;
    }
}






