/*-------- 26. Team style ---------*/

.team-wrap {
    position: relative;
    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 9;
        transition: all 0.4s 0.1s;
        opacity: 0;
        visibility: hidden;
        background-color: #212121;
        pointer-events: none;
    }
    > a {
        display: block;
        img {
            width: 100%;
        }
    }
    .team-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 99;
        h3 {
            font-weight: bold;
            font-size: 22px;
            color: #fff;
            margin: 0 0 0;
            line-height: 1;
            a {
                color: #fff;
                display: block;
                opacity: 0;
                visibility: hidden;
                transition: all 0.4s 0.1s;
                transform: translate(0, 20px);
            }
        }
        span {
            color: #fff;
            font-size: 16px;
            font-style: italic;
            font-family: $playfairdisplay;
            margin: 12px 0 18px;
            display: block;
            transition-delay: 0.3s;
            opacity: 0;
            visibility: hidden;
            transform: translate(0, 20px);
        }
        .team-social {
            a {
                display: inline-block;
                color: #fff;
                margin: 0 8px;
                opacity: 0;
                visibility: hidden;
                transition-delay: 0.5s;
                transform: translate(0, 20px);
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &:hover {
        &:before {
            visibility: visible;
            opacity: .8;
        }
        .team-content {
            h3 a {
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
            }
            span {
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
            }
            .team-social a {
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
            }
        }
    }
}




