/*-------- 20. Wishlist style ---------*/

.wishlist-main-area {
    @media #{$xs-layout}{
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}

.wishlist-table-content {
    table {
        width: 100%;
        thead {
            background-color: #f7f7f7;
            > tr {
                th {
                    border-top: medium none;
                    font-size: 14px;
                    text-transform: capitalize;
                    vertical-align: middle; 
                    white-space: nowrap;
                    font-weight: 400;
                    text-transform: capitalize;
                    padding: 13px 0 12px 0;
                    &.wishlist-cart-none {
                        width: 100px;
                        span {
                            display: none;
                        }
                    }
                    @media #{$xs-layout}{
                        padding: 13px 10px 12px;
                    }
                }
            }
        }
        tbody > tr {
            border-bottom: 1px solid #E7E7E7;
            td {
                font-size: 14px;
                padding: 30px 0px 30px;
                @media #{$xs-layout}{
                    padding: 30px 12px 30px 0;
                }
            }
            td.wishlist-remove {
                width: 30px;
                a {
                    color: #989898;
                    font-size: 16px;
                }
            }
            td.wishlist-img {
                width: 90px;
            }
            td.wishlist-name {
                width: 410px;
                @media #{$md-layout}{
                    width: 250px;
                }
                @media #{$xs-layout}{
                    width: 200px;
                    padding-left: 10px;
                    padding-right: 20px;
                    padding-top: 0;
                }
                a {
                    font-size: 14px;
                    display: block;
                    margin: 0 0 5px;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
            td.wishlist-stock {
                width: 265px;
                @media #{$md-layout}{
                    width: 120px;
                }
                @media #{$xs-layout}{
                    width: 100px;
                }
                span {
                    font-size: 13px;
                    font-weight: 400;
                    background-color: #35d56a;
                    color: #fff;
                    border-radius: 50px;
                    display: inline-block;
                    padding: 4px 12px 6px;
                    line-height: 1;
                }
            }
            td.wishlist-price {
                width: 90px;
                span {
                    color: #262626;
                    font-size: 16px;
                }
            }
            td.wishlist-cart {
                > a {
                    display: inline-block;
                    border: 1px solid #e7e7e7;
                    line-height: 1;
                    padding: 13px 20px;
                    color: #989898;
                    font-size: 12px;
                    @media #{$lg-layout}{
                        padding: 10px 10px;
                    }
                    @media #{$md-layout}{
                        padding: 10px 12px;
                    }
                    @media #{$xs-layout}{
                        width: 100px;
                        padding: 10px 12px;
                        text-align: center;
                    }
                    @media #{$sm-layout}{
                        width: 94px;
                        padding: 10px 10px;
                    }
                    &:hover {
                        color: $theme-color;
                        border: 1px solid $theme-color;
                    }
                }
            }
        }
    }
}



