/*-------- 10. Service style ---------*/

.service-wrap {
    display: flex;
    .service-icon {
        margin: 0 20px 0px 0;
        i {
            font-size: 30px;
        }
    }
    .service-content {
        h6 {
            font-size: 12px;
            font-weight: 700;
            margin: 0 0 11px;
            line-height: 1;
        }
        p {
            color: #989898;
            margin: 0;
            font-size: 12px;
            line-height: 1;
        }
    }
}

@media #{$xs-layout} {
    .service-area {
        &.pt-70 {
            padding-top: 20px;
        }
    }
}




































