/*-------- 5. Section title style ---------*/

.section-title {
    h2 {
        font-size: 36px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 0 12px;
        line-height: 29px;
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    p {
        color: #989898;
        margin: 0;
    }
    @media #{$xs-layout} {
        &.mb-45 {
            margin-bottom: 30px;
        }
    }
}

.section-title-2 {
    h2 {
        font-size: 48px;
        font-weight: bold;
        margin: 0;
        font-family: $playfairdisplay;
        line-height: 38px;
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-40 {
            margin-bottom: 30px;
        }
    }
}

.section-title-3 {
    h2 {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 27px;
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 20px;
        }
    }
}
.section-title-4 {
    h2 {
        font-size: 48px;
        font-weight: 700;
        margin: 0;
        line-height: 34px;
        @media #{$md-layout} {
            font-size: 36px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 25px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
        &.mb-45 {
            margin-bottom: 20px;
        }
    }
}

.sec-title-pro-btn-wrap {
	display: flex;
	justify-content: space-between;
    align-items: center;
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

.section-title-5 {
    h2 {
        font-size: 72px;
        font-weight: 700;
        margin: 0 0 45px;
        line-height: 54px;
        @media #{$lg-layout} {
            font-size: 60px;
        }
        @media #{$md-layout} {
            font-size: 36px;
            margin: 0 0 15px;
            line-height: 28px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 25px;
            margin: 0 0 15px;
        }
    }
    p {
        color: #989898;
        font-family: $playfairdisplay;
        margin: 0 auto;
        line-height: 1.714286;
        width: 66%;
        @media #{$lg-layout} {
            width: 85%;
        }
        @media #{$md-layout} {
            width: 85%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

.section-title-6 {
    h2 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        line-height: 22px;
    }
    p {
        color: #989898;
        margin: 12px 0 0;
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
        &.mb-70 {
            margin-bottom: 30px;
        }
        &.mb-65 {
            margin-bottom: 30px;
        }
        &.mb-35 {
            margin-bottom: 20px;
        }
    }
}
.section-title-7 {
    h2 {
        font-size: 30px;
        font-weight: bold;
        margin: 0;
        line-height: 32px;
        @media #{$xs-layout} {
            font-size: 25px;
        }
    }
    @media #{$xs-layout} {
        &.mb-40 {
            margin-bottom: 20px;
        }
    }
}
.section-title-8 {
    h2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        margin: 0 0 15px;
        @media #{$xs-layout} {
            font-size: 22px;
        }
    }
}
.section-title-9 {
    h2 {
        font-size: 36px;
        font-weight: bold;
        line-height: 29px;
        margin: 0px;
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-45 {
            margin-bottom: 20px;
        }
    }
}
.section-title-paragraph {
    p {
        font-family: $playfairdisplay;
        margin: 0;
        font-size: 18px;
        line-height: 1.714286;
        width: 41%;
        margin: 0 auto;
        @media #{$xl-layout} {
            width: 65%;
        }
        @media #{$lg-layout} {
            width: 78%;
        }
        @media #{$md-layout} {
            width: 78%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
    @media #{$xs-layout} {
        &.mb-45 {
            margin-bottom: 20px;
        }
    }
}






